<a class="resource-card" (click)="onOpenCard()">
  <div class="resource-card__media">
    <img [src]="previewUrl" class="resource-card__media__preview" [alt]="previewAlt" />
    <img
      *ngIf="isVideo"
      src="/assets/images/video-play-icon.svg"
      class="resource-card__media__icon"
      alt="play icon"
    />
  </div>
  <div class="resource-card__description">
    <h5 class="resource-card__description__title">
      {{ data.title }}
    </h5>
    <div class="resource-card__description__text" [innerHTML]="descriptionHtml"></div>
    <div *ngIf="isVideo" class="resource-card__description__duration">
      {{ duration }}
    </div>
  </div>
</a>
