<div class="sidebar-toggle-notifications">
  <img
    src="./assets/images/{{
      toggleNotifications ? 'notifications-on.svg' : 'notifications-off.svg'
    }}"
  />

  <mat-slide-toggle
    class="custom-toggle-switch"
    matTooltipClass="custom-tooltip"
    [matTooltip]="
      toggleNotifications ? 'Turn off email notifications' : 'Turn on email notifications'
    "
    (change)="onToggleChange()"
    [(ngModel)]="toggleNotifications"
  ></mat-slide-toggle>
</div>
