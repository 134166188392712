import {
  ProfileSearchFormItem,
  ProfileSearchFormRequestBody,
  ProfileSearchFormRequestBodyItem,
  ProfileSearchFormStateModel,
} from "../profile-search-form.model";

export function submitProfileSearchDataRequestBodyUtils(
  m: ProfileSearchFormStateModel,
): ProfileSearchFormRequestBody {
  const filterFormItems = filterByDisabledCriteria(m.disabled_criteria);

  return {
    availability_percentage: m.availability_percentage,
    start_date: m.start_date,
    locations: m.locations?.filter(filterFormItems),
    areas_of_expertise: m.areas_of_expertise?.filter(filterFormItems).map(mapFormItem),
    specialisations: m.specialisations?.filter(filterFormItems).map(mapFormItem),
    skills: m.skills?.filter(filterFormItems).map(mapFormItem),
    primary_domains: m.primary_domains?.filter(filterFormItems).map(pd => pd.item.id),
    secondary_domains: m.secondary_domains?.filter(filterFormItems).map(sd => sd.item.id),
    certifications: m.certifications?.filter(filterFormItems).map(cert => cert.item.id),
  };
}

function filterByDisabledCriteria<T extends string | ProfileSearchFormItem>(
  disabled_criteria: string[],
) {
  return function (formItem: T): boolean {
    const itemId = typeof formItem === "string" ? formItem : formItem.item.id;

    return disabled_criteria.findIndex(id => id === itemId) < 0;
  };
}

function mapFormItem(formItem: ProfileSearchFormItem): ProfileSearchFormRequestBodyItem {
  return {
    entity_id: formItem.item.id,
    max_level: formItem.max_level,
  };
}
