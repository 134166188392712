import { cmsPagesIds } from "./cms-pages-ids.utils";

const getSysIdClause = (id: string): string => `{ sys: { id: "${id}" } }`;

export const PAGE_COLLECTION_QUERY = (pageNames: PageId | PageId[]) => {
  const whereClause = Array.isArray(pageNames)
    ? `{ OR: [${pageNames
        .map(pageName => cmsPagesIds[pageName])
        .map(getSysIdClause)
        .join(",")}] }`
    : getSysIdClause(pageNames);

  return `
    pageCollection(where: ${whereClause}) {
      items {
        sys { id }
        title
        subtitle
        headingSection1
        headingSection2
        headingSection3
        fallbackText { json }
        fallback2 { json }
        fallbackIllustration { url }
        descriptionSection1 { json }
        descriptionSection2 { json }
        descriptionSection3 { json }
        button1Text
        button1Link
        textButton1Link
        textButton1Text
        emptySearchResultIllustration { url }
        emptySearchResultText { json }
      }
    }
  `;
};

type PageId = keyof typeof cmsPagesIds;
