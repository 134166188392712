import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { EventMessage, EventType, LogLevel, Logger } from "@azure/msal-browser";
import { Observable, Subject, Subscription, concat } from "rxjs";
import { tap } from "rxjs/operators";
import { AppService } from "@shared/services/app.service";
import { AuthorizationService } from "@shared/services/authorization.service";
import { MsalProfileService } from "@shared/services/msal-profile.service";
import { AdminService, environment } from "src/environments/environment";
import { ProfileStoreService } from "./store/profile/services/profile-store.service";
import { CmsTextDataService } from "./store/cms-text-data/services/cms-text-data.service";

@Component({
  selector: "sm-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  @ViewChild("hostRef") set hostRef(element: ElementRef) {
    if (element) this.adminService.setupAdmin();
  }

  adminEnabled = false;
  subscriptions: Array<Subscription> = [];
  msalSubscription: string | null = null;
  noSidebar = false;

  constructor(
    private authService: AuthorizationService,
    private msalService: MsalService,
    private msalProfile: MsalProfileService,
    private profileStore: ProfileStoreService,
    private cmsTextDataService: CmsTextDataService,
    public appService: AppService,
    public adminService: AdminService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    const sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url.endsWith("env")) {
        this.noSidebar = true;
        this.appService.loading.next(false);
      }
    });

    this.subscriptions.push(sub);

    if (environment.MA_LOGGING_ENABLED) {
      this.enableMsalLogging();
    }

    this.msalSubscription = this.msalService.instance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        this.extractToken(event);
        this.fetchOnAppInit().subscribe(() => this.appService.loading.next(false));
      }
      if (
        event.eventType === EventType.INITIALIZE_END &&
        this.msalService.instance.getAllAccounts().length > 0
      ) {
        concat(this.checkForValidToken(), this.fetchOnAppInit()).subscribe(() =>
          this.appService.loading.next(false),
        );
      }
    });
  }

  private extractToken(event: EventMessage) {
    const payload = event.payload as any;
    if (payload && payload.idToken) {
      this.authService.setCurrentIdToken(payload.idToken);
    }
  }

  private fetchContentfulKey(): Observable<any> {
    return this.authService.getContentfulKey().pipe(
      tap(res => {
        const account = this.msalService.instance.getAllAccounts()[0];
        this.authService.setCurrentKey(res.api_key);

        if (account) this.msalProfile.setProfile(account);
      }),
    );
  }

  private checkForValidToken() {
    const accounts = this.msalService.instance.getAllAccounts()[0];

    const authRequest = {
      account: accounts,
      scopes: ["user.read"],
    };

    return this.msalService.acquireTokenSilent(authRequest).pipe(
      tap(res => {
        if (res.account) this.msalProfile.setProfile(res.account);
        if (res.idToken) this.authService.setCurrentIdToken(res.idToken);
      }),
    );
  }

  private enableMsalLogging() {
    this.msalService.setLogger(
      new Logger({
        logLevel: LogLevel.Trace,
        loggerCallback: (logLevel, msg) => {
          console.log(`#### MSAL LOGGING ${logLevel}: `, msg);
        },
      }),
    );
  }

  private fetchOnAppInit() {
    return concat(
      this.fetchContentfulKey(),
      this.profileStore.fetchProfile(),
      this.cmsTextDataService.fetchCmsTextData(),
    );
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    if (this.msalSubscription) this.msalService.instance.removeEventCallback(this.msalSubscription);
  }
}
