import { Component, Input } from "@angular/core";

@Component({
  selector: "sm-loading-spinner",
  templateUrl: "./loading-spinner.component.html",
  styleUrls: ["./loading-spinner.component.scss"],
})
export class LoadingSpinnerComponent {
  @Input() showTitle = false;
  @Input() title = "not set";
  @Input() subTitle = "not set";
}
