import { StateContext } from "@ngxs/store";
import { LearningPathStoreItem, LearningPathsStateModel } from "../models/learning-paths.model";
import { areStoreItemArraysEqual } from "../../utils/is-store-items-array-equal.utils";

export function setLearningPathsState({
  getState,
  patchState,
}: StateContext<LearningPathsStateModel>) {
  return function (paths: LearningPathStoreItem[]): void {
    const prevPaths = getState().paths;
    const shouldNotUpdateState = areStoreItemArraysEqual(paths, prevPaths);
    if (shouldNotUpdateState) return;

    patchState({ paths: [...prevPaths, ...paths] });
  };
}
