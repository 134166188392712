import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { GetPeers } from "./peers.actions";
import { MY_PEERS_KEY, PeerModel, PeersStateModel } from "./peers.model";

@State<PeersStateModel>({
  name: "peers",
  defaults: {},
})
@Injectable()
export class PeersState {
  constructor(private http: HttpClient) {}

  /* ACTIONS */
  @Action(GetPeers)
  fetchPeers(
    { getState, patchState }: StateContext<PeersStateModel>,
    { profileId, refreshRequest }: GetPeers,
  ): Observable<PeerModel[]> {
    const peersKey = profileId || MY_PEERS_KEY;
    const peersState = getState()[peersKey];
    const isPeersStateSet = Array.isArray(peersState) && peersState.length > 0;

    if (!isPeersStateSet || refreshRequest) {
      const suffix = profileId ? `/${profileId}` : "";

      return this.http
        .get<PeerModel[]>(`${environment.AZ_URL}/peers${suffix}`)
        .pipe(tap(peers => patchState({ [peersKey]: peers })));
    }

    return of(peersState);
  }

  /* SELECTORS */
  @Selector()
  static selectPeers(peersState: PeersStateModel) {
    return function (profileId?: string): PeerModel[] {
      return peersState?.[profileId || MY_PEERS_KEY] || [];
    };
  }

  @Selector([PeersState.selectPeers])
  static selectPeer(_: PeersStateModel, selectPeers: ReturnType<typeof PeersState.selectPeers>) {
    return function (peerId: string, profileId?: string): PeerModel | undefined {
      const peers = selectPeers(profileId);
      if (!peers) return;

      return peers.find(peer => peer.id === peerId);
    };
  }

  @Selector()
  static selectAllAvailablePeers(peerState: PeersStateModel): PeerModel[] {
    return Object.keys(peerState).reduce(
      (peers, peerId) => [...peers, ...peerState[peerId]],
      [] as PeerModel[],
    );
  }
}
