import { TeamMemberPendingChangeStatus } from "../team-members/models/team-members.model";

export type entityType =
  | "overall"
  | "areas_of_expertise"
  | "specialisation"
  | "competence"
  | "primary_domain"
  | "secondary_domain"
  | "primary_power_skill"
  | "secondary_power_skill"
  | "certification";

export type HistoryStateModel = Readonly<Record<string, HistoryModel[]>>;

export const MY_HISTORY_KEY = "myHistory" as const;

export type HistoryModel = Readonly<{
  entity_id: string;
  entity_type: entityType;
  parent_id?: string;
  changes: HistoryChanges[];
}>;

type HistoryChanges = Readonly<{
  timestamp: string;
  status: TeamMemberPendingChangeStatus;
  action: "new" | "change";
  old_value?: number;
  new_value: number;
  comment?: string;
  approved_declined_by_display_name?: string;
  approval_rejection_date?: string;
}>;
