import { Component, Input } from "@angular/core";

@Component({
  selector: "sm-status-badge",
  templateUrl: "status-badge.component.html",
  styleUrls: ["./status-badge.component.scss"],
})
export class StatusBadgeComponent {
  @Input() status?: "approved" | "pending" | "declined" | "unavailable" | "new" | "change" | null;
  @Input() size?: "normal" | "small" = "normal";

  get className(): string {
    const extraClasses: string[] = [];
    if (this.size) extraClasses.push(this.size);
    if (this.status) extraClasses.push(this.status);

    return extraClasses.join(" ");
  }
}
