import { Certificate } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { CertificateStoreItem } from "../models/certificates.model";
import { LearningPathSectionStoreItem } from "../../learning-paths/models/learning-paths.model";
import { getCertificationRelatedLearningPathIds } from "../../catalogue/utils/get-reference-related-learning-path-ids.utils";

export function parseCMSCertificates(
  cmsCertificates: Certificate[],
  learningPathSections: LearningPathSectionStoreItem[],
): CertificateStoreItem[] {
  return cmsCertificates.map(cmsCertificate => {
    const {
      sys: { id, publishedVersion },
      name,
      linkedFrom,
    } = cmsCertificate;

    // It is assumed that each certificate can have ONLY ONE issuer
    const issuer = linkedFrom?.certificateIssuerCollection?.items.filter(filterNull)[0];

    return {
      id,
      type: "Certificate",
      entityVersion: publishedVersion || 0,
      name: name || "",
      learningPathIds: getCertificationRelatedLearningPathIds(id, learningPathSections),
      issuer: {
        id: issuer?.sys.id || "",
        type: "CertificateIssuer",
        name: issuer?.nameOfIssuer || "",
        iconUrl: issuer?.logoOfIssuer?.url || "",
      },
    };
  });
}
