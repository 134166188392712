import { StateContext } from "@ngxs/store";
import {
  LearningPathSectionStoreItem,
  LearningPathsStateModel,
} from "../models/learning-paths.model";
import { areStoreItemArraysEqual } from "../../utils/is-store-items-array-equal.utils";

export function setLearningPathSectionsState({
  getState,
  patchState,
}: StateContext<LearningPathsStateModel>) {
  return function (sections: LearningPathSectionStoreItem[]): void {
    const prevSections = getState().sections;
    const shouldNotUpdateState = areStoreItemArraysEqual(sections, prevSections);
    if (shouldNotUpdateState) return;

    patchState({ sections: [...prevSections, ...sections] });
  };
}
