import { StateContext } from "@ngxs/store";
import {
  ChangeSecondaryPowerSkillResponse,
  MY_PROFILE_KEY,
  ProfileModel,
  ProfileStateModel,
} from "../profile.model";

export function updateProfileStateSecondaryPowerSkills({
  getState,
  patchState,
}: StateContext<ProfileStateModel>) {
  return function (response: ChangeSecondaryPowerSkillResponse): void {
    const profile = getState()[MY_PROFILE_KEY];
    const successfulSecondaryPowerSkills: ProfileModel["secondary_power_skills"] = response
      .filter(res => `${res.http_status_code}`.startsWith("2"))
      .map(res => ({
        entry_id: res.entity_id,
        parent_id: res.parent_id,
        pending: res.status === "pending",
      }));

    patchState({
      [MY_PROFILE_KEY]: {
        ...profile,
        secondary_power_skills: [
          ...profile.secondary_power_skills,
          ...successfulSecondaryPowerSkills,
        ],
      },
    });
  };
}
