import { CatalogueHeadlinesCollection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { HeadlineItem } from "../cms-text-data.model";

export function parseHeadlineItems(
  catalogueHeadlineCollection?: CatalogueHeadlinesCollection | null,
): HeadlineItem[] {
  if (!catalogueHeadlineCollection) return [];
  const items = catalogueHeadlineCollection.items.filter(filterNull) || [];

  return items.map(item => ({
    catalogue: item.name || "",
    mainHeadline: item.mainHeadline || "",
    subheadline: item.subheadline || "",
    unavailableHeadline: item.unavailableHeadline || "",
  }));
}
