export class GetAoEItems {
  static readonly type = "[Catalogue] Get AoE items";
}

export class GetSkillItems {
  static readonly type = "[Catalogue] Get skill items";
}

export class GetSpecItems {
  static readonly type = "[Catalogue] Get specialisation items";
}
