import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { multipleWordSearchTermMatches } from "@shared/utils/multiple-word-search-term-matches.utils";

@Pipe({ name: "highlightSearchTermMatches" })
export class HighlightSearchTermMatchesPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  private startDelimiter = "@";
  private endDelimiter = "%";

  private styleWrapper(term: string): string {
    const startRegex = new RegExp(this.startDelimiter, "g");
    const endRegex = new RegExp(this.endDelimiter, "g");
    const tagOpening = `<strong style="color:#5a5f66">`;
    const tagClosing = `</strong>`;

    return term.replace(startRegex, tagOpening).replace(endRegex, tagClosing);
  }

  transform(name: string, searchTerm: string): SafeHtml | string {
    const matches = multipleWordSearchTermMatches(name, searchTerm);
    if (matches.length === 0) return "";

    const delimitedName = matches.reduce(
      (delimitedName, match) =>
        delimitedName.replace(match, `${this.startDelimiter}${match}${this.endDelimiter}`),
      name,
    );
    const formattedName = this.styleWrapper(delimitedName);

    return this.sanitizer.bypassSecurityTrustHtml(formattedName);
  }
}
