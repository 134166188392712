import { PendingRequestTree } from "../models/pending-request-tree.model";
import { TeamMember } from "../models/team-members.model";
import { buildTeamMemberPendingRequestTrees } from "./build-team-member-request-tree.utils";

export function buildAllTeamMembersPendingRequestTree(
  teamMembers: TeamMember[],
): PendingRequestTree[] {
  return teamMembers.reduce((trees, teamMember) => {
    return [...trees, ...buildTeamMemberPendingRequestTrees(teamMember)];
  }, [] as PendingRequestTree[]);
}
