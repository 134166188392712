<div class="header" *ngIf="items.length > 0 && items[0].link">
  <a
    *ngFor="let item of items"
    class="nav-item"
    (click)="onNavigate(item)"
    [class.nav-item--active]="item === activeItem"
    [routerLink]="[item.link]"
    >{{ item.name }}</a
  >
</div>

<div class="header" *ngIf="items.length > 0 && !items[0].link">
  <a
    *ngFor="let item of items"
    class="nav-item"
    [class.nav-item--active]="item === activeItem"
    (click)="onClick(item)"
    >{{ item.name }}</a
  >
</div>
