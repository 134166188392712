import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { AvatarsState } from "../avatars.state";
import { AvatarModel } from "../avatar.model";
import { GetAvatar } from "../avatars.actions";

@Injectable({ providedIn: "root" })
export class AvatarsStoreService {
  @Select(AvatarsState.selectAvatar)
  private selectAvatar$!: Observable<ReturnType<typeof AvatarsState.selectAvatar>>;

  constructor(private store: Store) {}

  /**
   * @param profileId if not provided, the id of the current user will be set
   */
  fetchAvatar(profileId?: string) {
    return this.store.dispatch(new GetAvatar(profileId));
  }

  /**
   * @param profileId if not provided, the id of the current user will be set
   * @returns  profile model, if already in store
   */
  selectAvatar(profileId?: string): Observable<AvatarModel | undefined> {
    return this.selectAvatar$.pipe(map(selectAvatar => selectAvatar(profileId)));
  }
}
