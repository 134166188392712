import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { NavigationItem } from "../../models/navigationItemData";

@Component({
  selector: "sm-navigation",
  templateUrl: "./sm-navigation.component.html",
  styleUrls: ["./sm-navigation.component.scss"],
})
export class SmNavigationComponent implements OnInit, OnDestroy {
  @Input() items: NavigationItem[] = [];
  @Output() navigated = new EventEmitter<NavigationItem | any>();

  subs: Subscription[] = [];
  activeItem = this.items[0];

  constructor(private router: Router) {}

  ngOnInit() {
    if (this.items.length <= 0) return;

    const items = this.items as any[];
    if (items.length < 0 || !items[0].link) {
      return;
    }

    this.setActiveItem(this.router.url, true);

    const subRouter = this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) return;

      this.setActiveItem(event.urlAfterRedirects);
    });

    this.subs.push(subRouter);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setActiveItem(path: string, emit = false) {
    this.items.forEach(item => {
      if (item.link === path.split("?")[0]) {
        if (emit) {
          if (item.click) item.click();
          else this.onNavigate(item);
        }

        this.activeItem = item;
      }
    });
  }

  onClick(item: NavigationItem) {
    if (item.click) {
      item.click();
    } else {
      console.error(`The items array passed to sm-navigation has malformed data.
      Please make sure to either pass a link OR a click-function on every item.`);
    }

    this.activeItem = item;
  }

  onNavigate(item: NavigationItem) {
    this.navigated.emit(item);
  }
}
