import { Injectable } from "@angular/core";
import { State, Action, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { filterNull } from "@shared/utils/filter-null.utils";
import { CMSRequestService } from "../services/cms-request.service";
import { HelpContentStateModel } from "./help-content.model";
import { GetHelpContent } from "./help-content.actions";
import { HELP_CONTENT_QUERY } from "./help-content.query";
import { cmsPagesIds } from "../utils/cms-pages-ids.utils";

@State<HelpContentStateModel>({
  name: "helpContent",
  defaults: {
    videoCards: [],
    externalCards: [],
  },
})
@Injectable()
export class HelpContentState {
  private isRequestFired = false;

  constructor(private cmsService: CMSRequestService) {}

  @Action(GetHelpContent)
  getHelpContent({ setState }: StateContext<HelpContentStateModel>) {
    if (this.isRequestFired) return;

    return this.cmsService
      .cmsQuery(HELP_CONTENT_QUERY(["help", "emptyProfile", "skillCatalogue"]))
      .pipe(
        tap(({ data }) => {
          this.isRequestFired = true;
          const pages = data.pageCollection?.items.filter(filterNull) || [];

          return setState({
            videoCards: data.videoResourceCardCollection?.items.filter(filterNull) || [],
            externalCards: data.externalResourceCardCollection?.items.filter(filterNull) || [],
            emptyProfilePage: pages.find(page => page.sys.id === cmsPagesIds.emptyProfile),
            helpPage: pages.find(page => page.sys.id === cmsPagesIds.help),
            skillCataloguePage: pages.find(page => page.sys.id === cmsPagesIds.skillCatalogue),
          });
        }),
      );
  }
}
