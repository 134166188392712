import { CertificateStoreItem, CertificateIssuerStoreItem } from "../models/certificates.model";

export function reduceCertificateIssuers(
  certificateItems: CertificateStoreItem[],
): CertificateIssuerStoreItem[] {
  return certificateItems.reduce((issuers, certificate) => {
    const alreadyAdded = issuers.findIndex(({ id }) => certificate.issuer.id === id) >= 0;
    if (alreadyAdded) return issuers;

    return [...issuers, certificate.issuer];
  }, [] as CertificateIssuerStoreItem[]);
}
