import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AreaOfExpertise, Specialisation, Technology } from "@shared/models/contentful.types";
import { filterNullItemsPipe } from "@shared/utils/filter-null.utils";
import { CMSRequestService, LargeCollectionRequestArgs } from "../services/cms-request.service";
import { GetAoEItems, GetSkillItems, GetSpecItems } from "./catalogue.actions";
import {
  AoEStoreItem,
  SpecStoreItem,
  SkillStoreItem,
  CatalogueStateModel,
} from "./catalogue.model";
import { AOE_ITEMS_QUERY, SKILL_ITEMS_QUERY, SPEC_ITEMS_QUERY } from "./catalogue.query";
import { formatCMSSkillItem } from "./utils/format-cms-skill-item.utils";
import { formatCMSAoEItem } from "./utils/format-cms-aoe-items.utils";
import { formatCMSSpecItem } from "./utils/format-cms-spec-item.utils";
import { ProfileState } from "../profile/profile.state";
import { LearningPathsState } from "../learning-paths/learning-paths.state";
import { ProfileAoETree } from "./models/profile-aoe-tree.model";
import { buildProfileAoETrees } from "./utils/build-profile-aoe-trees.utils";
import { setCatalogueAoEState } from "./utils/set-catalogue-aoe-state.utils";
import { setCatalogueSpecState } from "./utils/set-catalogue-spec-state.utils";
import { setCatalogueSkillState } from "./utils/set-catalogue-skill-state.utils";

@State<CatalogueStateModel>({
  name: "cmsCatalogue",
  defaults: {
    cmsAreasOfExpertise: [],
    cmsSkills: [],
    cmsSpecialisations: [],
  },
})
@Injectable()
export class CatalogueState {
  constructor(private cmsService: CMSRequestService) {}

  /** ACTIONS */
  @Action(GetAoEItems)
  getAoEItems(context: StateContext<CatalogueStateModel>): Observable<AreaOfExpertise[]> {
    const fetchArgs: LargeCollectionRequestArgs<"areaOfExpertiseCollection"> = {
      collection: "areaOfExpertiseCollection",
      limit: 10,
      itemQuery: AOE_ITEMS_QUERY,
    };

    return this.cmsService.fetchLargeCollectionItems(fetchArgs).pipe(
      map(({ data }) => data[fetchArgs.collection]?.items || []),
      filterNullItemsPipe,
      tap(setCatalogueAoEState(context)),
    );
  }

  @Action(GetSpecItems)
  getSpecItems(context: StateContext<CatalogueStateModel>): Observable<Specialisation[]> {
    const fetchArgs: LargeCollectionRequestArgs<"specialisationCollection"> = {
      collection: "specialisationCollection",
      limit: 30,
      itemQuery: SPEC_ITEMS_QUERY,
    };

    return this.cmsService.fetchLargeCollectionItems(fetchArgs).pipe(
      map(({ data }) => data[fetchArgs.collection]?.items || []),
      filterNullItemsPipe,
      tap(setCatalogueSpecState(context)),
    );
  }

  @Action(GetSkillItems)
  getSkillItems(context: StateContext<CatalogueStateModel>): Observable<Technology[]> {
    const fetchArgs: LargeCollectionRequestArgs<"technologyCollection"> = {
      collection: "technologyCollection",
      limit: 500,
      itemQuery: SKILL_ITEMS_QUERY,
    };

    return this.cmsService.fetchLargeCollectionItems(fetchArgs).pipe(
      map(({ data }) => data[fetchArgs.collection]?.items || []),
      filterNullItemsPipe,
      tap(setCatalogueSkillState(context)),
    );
  }

  /** SELECTORS */
  @Selector()
  static selectAreasOfExpertise(catalogueState: CatalogueStateModel): AoEStoreItem[] {
    return catalogueState.cmsAreasOfExpertise.map(formatCMSAoEItem);
  }

  @Selector([CatalogueState.selectAreasOfExpertise])
  static selectSpecialisations(
    catalogueState: CatalogueStateModel,
    areasOfExpertise: ReturnType<typeof CatalogueState.selectAreasOfExpertise>,
  ): SpecStoreItem[] {
    return catalogueState.cmsSpecialisations.map(formatCMSSpecItem(areasOfExpertise));
  }

  @Selector([
    CatalogueState.selectSpecialisations,
    LearningPathsState.selectAllLearningPathSections,
  ])
  static selectSkills(
    catalogueState: CatalogueStateModel,
    specialisations: ReturnType<typeof CatalogueState.selectSpecialisations>,
    learningPathSections: ReturnType<typeof LearningPathsState.selectAllLearningPathSections>,
  ): SkillStoreItem[] {
    return catalogueState.cmsSkills.map(formatCMSSkillItem(specialisations, learningPathSections));
  }

  @Selector([CatalogueState.selectAreasOfExpertise])
  static selectSpecParent(
    _: CatalogueStateModel,
    areasOfExpertise: ReturnType<typeof CatalogueState.selectAreasOfExpertise>,
  ) {
    return function (specId: string): AoEStoreItem | undefined {
      return areasOfExpertise.find(aoe => aoe.specChildrenIds.includes(specId));
    };
  }

  @Selector([CatalogueState.selectSpecialisations])
  static selectSkillParents(
    _: CatalogueStateModel,
    specialisations: ReturnType<typeof CatalogueState.selectSpecialisations>,
  ) {
    return function (skillId: string): SpecStoreItem[] {
      return specialisations.filter(spec => spec.skillChildrenIds.includes(skillId));
    };
  }

  @Selector([CatalogueState.selectAreasOfExpertise])
  static selectAoEItem(
    _: CatalogueStateModel,
    areasOfExpertise: ReturnType<typeof CatalogueState.selectAreasOfExpertise>,
  ) {
    return function (aoeId: string): AoEStoreItem | undefined {
      return areasOfExpertise.find(({ id }) => aoeId === id);
    };
  }

  @Selector([CatalogueState.selectSpecialisations])
  static selectSpecItem(
    _: CatalogueStateModel,
    specialisations: ReturnType<typeof CatalogueState.selectSpecialisations>,
  ) {
    return function (specId: string): SpecStoreItem | undefined {
      return specialisations.find(({ id }) => specId === id);
    };
  }

  @Selector([CatalogueState.selectSkills])
  static selectSkillItem(
    _: CatalogueStateModel,
    skills: ReturnType<typeof CatalogueState.selectSkills>,
  ) {
    return function (skillId: string): SkillStoreItem | undefined {
      return skills.find(({ id }) => skillId === id);
    };
  }

  @Selector([
    CatalogueState.selectAreasOfExpertise,
    CatalogueState.selectSpecialisations,
    CatalogueState.selectSkills,
    ProfileState.selectProfile,
  ])
  static selectProfileCatalogueItems(
    _: CatalogueStateModel,
    aoeItems: ReturnType<typeof CatalogueState.selectAreasOfExpertise>,
    specItems: ReturnType<typeof CatalogueState.selectSpecialisations>,
    skillItems: ReturnType<typeof CatalogueState.selectSkills>,
    selectProfile: ReturnType<typeof ProfileState.selectProfile>,
  ) {
    return function (profileId?: string): ProfileAoETree[] | null {
      const profile = selectProfile(profileId);
      if (!profile) return null;

      return buildProfileAoETrees(profile, aoeItems, specItems, skillItems);
    };
  }
}
