import { StateContext } from "@ngxs/store";
import { Specialisation } from "@shared/models/contentful.types";
import { CatalogueStateModel } from "../catalogue.model";
import { areCMSItemArraysEqual } from "../../utils/is-store-items-array-equal.utils";

export function setCatalogueSpecState({ getState, patchState }: StateContext<CatalogueStateModel>) {
  return function (cmsSpecs: Specialisation[]): void {
    const prevSpecs = getState().cmsSpecialisations;
    const shouldNotUpdateState = areCMSItemArraysEqual(cmsSpecs, prevSpecs);
    if (shouldNotUpdateState) return;

    patchState({ cmsSpecialisations: [...prevSpecs, ...cmsSpecs] });
  };
}
