import { CatalogueStoreItemStatus } from "../../catalogue/catalogue.model";
import { ProfileEntry, ProfileModel } from "../../profile/profile.model";
import {
  ProfilePrimaryPowerSkillTree,
  ProfileSecondaryPowerSkillTree,
} from "../models/power-skill-tree.model";
import { PrimaryPowerSkillItem, SecondaryPowerSkillItem } from "../models/power-skills.model";

export function buildProfilePowerSkillTrees(
  profile: ProfileModel,
  primaryPowerSkillsItems: PrimaryPowerSkillItem[],
): ProfilePrimaryPowerSkillTree[] {
  const profilePrimaryIds = profile.primary_power_skills.map(p => p.entry_id);

  return primaryPowerSkillsItems
    .filter(({ id }) => profilePrimaryIds.includes(id))
    .map(primaryItem => ({
      primaryItem,
      primaryStatus: getPrimaryItemStatus(primaryItem, profile.primary_power_skills),
      secondaryTrees: buildProfileSecondarySkillTrees(
        profile,
        primaryItem.id,
        primaryItem.secondaryPowerSkillItems,
      ),
    }));
}

function buildProfileSecondarySkillTrees(
  { secondary_power_skills }: ProfileModel,
  primaryItemId: string,
  secondaryPowerSkillsItems: SecondaryPowerSkillItem[],
): ProfileSecondaryPowerSkillTree[] {
  const profileSecondaryIds = secondary_power_skills
    .filter(s => s.parent_id === primaryItemId)
    .map(s => s.entry_id);

  return secondaryPowerSkillsItems
    .filter(({ id }) => profileSecondaryIds.includes(id))
    .map(secondaryItem => ({
      secondaryItem,
      secondaryStatus: getSecondaryItemStatus(secondaryItem, secondary_power_skills),
    }));
}

function getPrimaryItemStatus(
  primaryItem: PrimaryPowerSkillItem,
  primaryDomains: ProfileModel["primary_power_skills"],
): Extract<CatalogueStoreItemStatus, "pending" | "approved"> {
  const profileItem = primaryDomains.find(p => p.entry_id === primaryItem.id);

  return getStatus(profileItem);
}

function getSecondaryItemStatus(
  secondaryItem: SecondaryPowerSkillItem,
  secondaryDomains: ProfileModel["secondary_power_skills"],
): Extract<CatalogueStoreItemStatus, "pending" | "approved"> {
  const profileItem = secondaryDomains.find(
    s => s.entry_id === secondaryItem.id && s.parent_id === secondaryItem.parentId,
  );

  return getStatus(profileItem);
}

function getStatus(
  entry?: ProfileEntry,
): Extract<CatalogueStoreItemStatus, "pending" | "approved"> {
  return entry?.pending ? "pending" : "approved";
}
