import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { NewsState } from "../news.state";
import { GetNews } from "../news.actions";
import { NewsItem } from "../news.model";

@Injectable({ providedIn: "root" })
export class NewsStoreService {
  @Select(NewsState)
  private news$!: Observable<NewsItem[]>;

  constructor(private store: Store) {}

  fetchNews() {
    return this.store.dispatch(new GetNews());
  }

  selectNews(): Observable<NewsItem[]> {
    return this.news$;
  }
}
