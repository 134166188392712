import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { NewsService } from "src/app/pages/news/news.service";
import { SidebarItem } from "../../models/sidebar-item.model";

@Component({
  selector: "sm-news-sidebar-item",
  templateUrl: "news-sidebar-item.component.html",
  styleUrls: ["../sidebar/sidebar.component.scss"],
})
export class NewsSidebarItemComponent implements OnInit {
  @Input() item!: SidebarItem;
  @Input() currentRoute!: string | null;
  showDot$!: Observable<boolean>;

  constructor(private newsService: NewsService) {}

  ngOnInit() {
    this.newsService.getLastVisit();
    this.showDot$ = this.newsService.showSidebarDot();
  }
}
