import { SidebarCollection } from "@shared/models/contentful.types";
import { SidebarText } from "../cms-text-data.model";

export function parseSidebarText(sidebarCollection?: SidebarCollection | null): SidebarText {
  const sidebar = sidebarCollection?.items?.[0];
  if (!sidebar) return {} as SidebarText;

  return {
    title: sidebar.title || "",
    version: sidebar.version || "",
    buttonTitle: sidebar.sidebarButton?.title || "",
    buttonText: sidebar.sidebarButton?.text || "",
    buttonIconUrl: sidebar.sidebarButton?.icon?.url || "",
  };
}
