import { Injectable } from "@angular/core";
import { Apollo, TypedDocumentNode } from "apollo-angular";
import { EMPTY } from "rxjs";
import { expand } from "rxjs/operators";
import { Query } from "@shared/models/contentful.types";

@Injectable({ providedIn: "root" })
export class CMSRequestService {
  constructor(private apollo: Apollo) {}

  cmsQuery(query: TypedDocumentNode<unknown, unknown>) {
    return this.apollo.query<Query>({
      query,
      errorPolicy: "ignore",
    });
  }

  fetchLargeCollectionItems<T extends LargeCMSCollection>(args: LargeCollectionRequestArgs<T>) {
    const { collection, limit, itemQuery } = args;
    const query = (limit: number, skip: number) => this.cmsQuery(itemQuery(limit, skip));
    let count = 0;

    return query(limit, 0).pipe(
      expand(({ data }) => {
        count++;
        const total = data[collection]?.total || 0;
        const skip = limit * count;
        const remain = total - skip;

        if (remain <= 0) {
          return EMPTY;
        } else if (remain > limit) {
          return query(limit, skip);
        } else {
          return query(remain, skip);
        }
      }),
    );
  }
}

type LargeCMSCollection = keyof Pick<
  Query,
  | "areaOfExpertiseCollection"
  | "specialisationCollection"
  | "technologyCollection"
  | "primaryDomainCollection"
  | "secondaryDomainCollection"
  | "primaryPowerSkillCollection"
  | "secondaryPowerSkillCollection"
  | "certificateCollection"
  | "learningPathCollection"
  | "learningPathSectionCollection"
  | "editorCollection"
  | "learningPathItemCollection"
>;

export interface LargeCollectionRequestArgs<T extends LargeCMSCollection> {
  collection: T;
  limit: number;
  itemQuery(limit: number, skip: number): TypedDocumentNode<unknown, unknown>;
}
