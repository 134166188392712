export type PeersStateModel = Record<string, PeerModel[]>;

export const MY_PEERS_KEY = "myPeers" as const;

export interface PeerModel {
  id: string;
  subordinates_counter: number;
  display_name: string;
  job_title: string;
  account_enabled: boolean;
  office_location: string;
  mail: string;
}
