export type ProfileStateModel = Readonly<Record<string, ProfileModel>>;

export const MY_PROFILE_KEY = "myProfile" as const;

export type ProfileModel = Readonly<{
  user_id: string;
  areas_of_expertise: ProfileEntry[];
  specialisations: ProfileEntry[];
  skills: ProfileEntry[];
  primary_domains: ProfileEntry[];
  secondary_domains: ProfileEntry[];
  primary_power_skills: ProfileEntry[];
  secondary_power_skills: ProfileEntry[];
  certifications: ProfileEntry[];
  timestamp: string;
  email_notification_opt_in: boolean;
  last_updated_at: string;
  user_mail_sent_at: string;
  teamlead_mail_sent_at: string;
  last_workday_skill_sync: string;
  overall_level: {
    level: number;
    pending: boolean;
  };
  employee: ProfileUserData;
  manager?: ProfileUserData;
  location: string;
}>;

export type ProfileUserData = Readonly<{
  adId: string;
  employeeId: string;
  jobTitle: string;
  name: string;
  subordinatesCounter?: number;
}>;

export type ProfileEntry = Readonly<{
  entry_id: string;
  pending: boolean;
  parent_id?: string;
  level?: number;
  previous_level?: number;
}>;

interface ChangeRequestBody {
  entity_id: string;
  entity_version: number; // publishedVersion
}

export type ChangePrimaryDomainRequestBody = ChangeRequestBody;

export interface ChangeSecondaryDomainRequestBody {
  parent_id: string;
  secondary_domains: ChangeRequestBody[];
}

export interface ChangePrimaryDomainResponse {
  change_id: string;
  user_id: string;
  parent_id: string;
  timestamp: string;
  status: ChangeDomainResponseStatus;
  comment?: string;
  entity_id: string;
  approved_declined_by: string;
  approval_decline_date: string;
}

export type ChangePrimaryPowerSkillRequestBody = ChangeRequestBody;

export type ChangePrimaryPowerSkillResponse = ChangePrimaryDomainResponse;

export interface ChangeSecondaryPowerSkillRequestBody {
  parent_id: string;
  secondary_power_skills: ChangeRequestBody[];
}

export type ChangeSecondaryDomainResponse = {
  parent_id: string;
  entity_id: string;
  http_status_code: number;
  status: ChangeDomainResponseStatus;
  change_id: string;
  description: string;
}[];

export type ChangeSecondaryPowerSkillResponse = ChangeSecondaryDomainResponse;

export type ChangeDomainResponseStatus = "pending" | "approved" | "declined" | "deleted";
