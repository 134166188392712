import { StateContext } from "@ngxs/store";
import { Query } from "@shared/models/contentful.types";
import { CmsDataStateModel } from "../cms-text-data.model";
import { parseApprovalsPageText } from "./parse-approvals-page-text";
import { parseProfilePageText } from "./parse-profile-page-text.utils";
import { parseSidebarTextItems } from "./parse-sidebar-text-items.utils";
import { parseSidebarText } from "./parse-sidebar-text.utils";
import { parseNotificationTextItems } from "./parse-notification-text-items.utils";
import { parsePopupTextItems } from "./parse-popup-text-items.utils";
import { parseTwoLevelPanelItems } from "./parse-two-level-panel-text.utils";
import { parseInfoBoxTextItems } from "./parse-info-box-text-items.utils";
import { parseHeadlineItems } from "./parse-headline-items";
import { parseProfileSearchPageText } from "./parse-profile-search-page-text.utils";
import { parseSearchResultTablePageTextUtils } from "./parse-search-result-table-page-text.utils";
import { parsePageNotFoundPageTextUtils } from "./parse-page-not-found-page-text.utils";
import { parseLearningPathPageText } from "./parse-learning-path-page-text";

export function setCMSTextDataState({ setState }: StateContext<CmsDataStateModel>) {
  return function (queryResult: Query): CmsDataStateModel {
    const {
      pageCollection,
      notificationCollection,
      sidebarCollection,
      popUpCollection,
      twoLevelPanelCollection,
      infoBoxCollection,
      catalogueHeadlinesCollection,
    } = queryResult;

    return setState({
      popups: parsePopupTextItems(popUpCollection),
      notifications: parseNotificationTextItems(notificationCollection),
      sidebar: parseSidebarText(sidebarCollection),
      sidebarItems: parseSidebarTextItems(sidebarCollection),
      approvalsPage: parseApprovalsPageText(pageCollection),
      profilePage: parseProfilePageText(pageCollection),
      profileSearchPage: parseProfileSearchPageText(pageCollection),
      twoLevelPanels: parseTwoLevelPanelItems(twoLevelPanelCollection),
      infoBoxes: parseInfoBoxTextItems(infoBoxCollection),
      headlines: parseHeadlineItems(catalogueHeadlinesCollection),
      searchResultTable: parseSearchResultTablePageTextUtils(pageCollection),
      pageNotFoundPage: parsePageNotFoundPageTextUtils(pageCollection),
      learningPath: parseLearningPathPageText(pageCollection),
    });
  };
}
