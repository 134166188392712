import { TeamMember } from "../models/team-members.model";

/** Finds the element in the state array to update */
export function findTeamMember<T extends { change_id: string }>(response: T) {
  return function (teamMember: TeamMember): boolean {
    return (
      teamMember.pending_changes.findIndex(({ change_id }) => change_id === response.change_id) >= 0
    );
  };
}
