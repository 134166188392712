import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class EmailNotificationService {
  constructor(private httpClient: HttpClient) {}

  toggleNotifications(toggleNotifications?: boolean | null) {
    return this.httpClient.patch<{ email_notification: boolean }>(`${environment.AZ_URL}/profile`, {
      email_notification: !!toggleNotifications,
    });
  }
}
