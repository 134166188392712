import { ProfileEntry, ProfileModel } from "../../profile/profile.model";
import {
  AoEStoreItem,
  CatalogueStoreItemStatus,
  SkillStoreItem,
  SpecStoreItem,
} from "../catalogue.model";
import {
  ProfileAoETree,
  ProfileSkillTree,
  ProfileSpecTree,
} from "../models/profile-aoe-tree.model";

export function buildProfileAoETrees(
  profile: ProfileModel,
  aoeItems: AoEStoreItem[],
  specItems: SpecStoreItem[],
  skillItems: SkillStoreItem[],
): ProfileAoETree[] {
  const profileAoEIds = profile.areas_of_expertise.map(i => i.entry_id);
  const profileAoEItems = aoeItems.filter(aoeItem => profileAoEIds.includes(aoeItem.id));

  return profileAoEItems.map(aoeItem => {
    return {
      aoeItem,
      ...getAoEItemExtraInfo(aoeItem, profile.areas_of_expertise),
      specTrees: specItems
        .filter(filterProfileItemFromParent(aoeItem, profile.specialisations))
        .map(buildProfileSpecTree(skillItems, profile)),
    };
  });
}

function buildProfileSpecTree(skillItems: SkillStoreItem[], profile: ProfileModel) {
  return function (specItem: SpecStoreItem): ProfileSpecTree {
    return {
      specItem,
      ...getSpecItemExtraInfo(specItem, profile.specialisations),
      skillTrees: skillItems
        .filter(filterProfileItemFromParent(specItem, profile.skills))
        .map(buildProfileSkillTree(profile, specItem.id)),
    };
  };
}

function buildProfileSkillTree(profile: ProfileModel, parentId: string) {
  return function (skillItem: SkillStoreItem): ProfileSkillTree {
    return {
      skillItem,
      ...getSkillItemExtraInfo(skillItem, profile.skills, parentId),
    };
  };
}

function filterProfileItemFromParent(
  parentItem: AoEStoreItem | SpecStoreItem,
  entries: ProfileEntry[],
) {
  return function (item: SpecStoreItem | SkillStoreItem): boolean {
    return entries
      .filter(e => e.parent_id === parentItem.id)
      .map(e => e.entry_id)
      .includes(item.id);
  };
}

function getAoEItemExtraInfo(
  aoeItem: AoEStoreItem,
  profileAoEs: ProfileModel["areas_of_expertise"],
): Pick<ProfileAoETree, "aoeStatus" | "aoeLevel"> {
  const profileItem = profileAoEs.find(a => a.entry_id === aoeItem.id);

  return {
    aoeStatus: getStatus(profileItem),
    aoeLevel: getLevel(profileItem),
  };
}

function getSpecItemExtraInfo(
  specItem: SpecStoreItem,
  profileSpecs: ProfileModel["specialisations"],
): Pick<ProfileSpecTree, "specStatus" | "specLevel"> {
  const profileItem = profileSpecs.find(
    s => s.entry_id === specItem.id && s.parent_id === specItem.parentId,
  );

  return {
    specStatus: getStatus(profileItem),
    specLevel: getLevel(profileItem),
  };
}

function getSkillItemExtraInfo(
  skillItem: SkillStoreItem,
  profileSkills: ProfileModel["skills"],
  parentId: string,
): Pick<ProfileSkillTree, "skillStatus" | "skillLevel"> {
  const profileItem = profileSkills.find(
    s => s.entry_id === skillItem.id && s.parent_id === parentId,
  );

  return {
    skillStatus: getStatus(profileItem),
    skillLevel: getLevel(profileItem),
  };
}

function getStatus(
  entry?: ProfileEntry,
): Extract<CatalogueStoreItemStatus, "pending" | "approved"> {
  return entry?.pending ? "pending" : "approved";
}

function getLevel(entry?: ProfileEntry): number {
  return entry?.level || 0;
}
