import { StateContext } from "@ngxs/store";
import { AreaOfExpertise } from "@shared/models/contentful.types";
import { CatalogueStateModel } from "../catalogue.model";
import { areCMSItemArraysEqual } from "../../utils/is-store-items-array-equal.utils";

export function setCatalogueAoEState({ getState, patchState }: StateContext<CatalogueStateModel>) {
  return function (cmsAoEs: AreaOfExpertise[]): void {
    const prevAoEs = getState().cmsAreasOfExpertise;
    const shouldNotUpdateState = areCMSItemArraysEqual(cmsAoEs, prevAoEs);
    if (shouldNotUpdateState) return;

    patchState({ cmsAreasOfExpertise: [...prevAoEs, ...cmsAoEs] });
  };
}
