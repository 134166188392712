import { gql } from "apollo-angular";

export const OVERALL_LEVEL_DESCRIPTION_QUERY = () => gql`
  query {
    overallLevelDescriptionCollection(limit: 1) {
      items {
        sys {
          id
        }
        junior {
          json
        }
        regular {
          json
        }
        senior {
          json
        }
        principal {
          json
        }
        seniorPrincipal {
          json
        }
      }
    }
  }
`;
