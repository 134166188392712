import { Component, Input } from "@angular/core";
import { EmailNotificationService } from "@shared/services/email-notification.service";
import { NotificationService } from "@shared/services/notification.service";

@Component({
  selector: "sm-notifications-toggle",
  templateUrl: "./notifications-toggle.component.html",
  styleUrls: ["./notifications-toggle.component.scss"],
})
export class NotificationsToggleComponent {
  @Input() toggleNotifications?: boolean | null;

  constructor(
    private emailNotificationService: EmailNotificationService,
    private notification: NotificationService,
  ) {}

  onToggleChange() {
    this.emailNotificationService
      .toggleNotifications(this.toggleNotifications)
      .subscribe(result => {
        // backend will return back the sent value
        result.email_notification
          ? this.notification.showMessage("Successfully subscribed to email notifications")
          : this.notification.showMessage("Successfully unsubscribed from email notifications");
      });
  }
}
