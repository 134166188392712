import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { filterNullItemsPipe } from "@shared/utils/filter-null.utils";
import { GetCertificates } from "./certificates.actions";
import {
  CertificateStoreItem,
  CertificateIssuerStoreItem,
  CertificatesStateModel,
} from "./models/certificates.model";
import { CERTIFICATES_QUERY } from "./certificates.query";
import { parseCMSCertificates } from "./utils/parse-cms-certificates.utils";
import { reduceCertificateIssuers } from "./utils/reduce-certificate-issuers.utils";
import { CMSRequestService, LargeCollectionRequestArgs } from "../services/cms-request.service";
import { ProfileState } from "../profile/profile.state";
import { LearningPathsState } from "../learning-paths/learning-paths.state";
import { buildProfileCertificateTrees } from "./utils/build-profile-certificates-trees.utils";
import { ProfilePrimaryCertificateTree } from "./models/profile-certificate-tree.model";
import { setCertificationsState } from "./utils/set-certifications-state.utils";

@State<CertificatesStateModel>({
  name: "certificates",
  defaults: [],
})
@Injectable()
export class CertificatesState {
  constructor(private cmsService: CMSRequestService) {}

  /* ACTIONS */
  @Action(GetCertificates)
  getCertificates(
    context: StateContext<CertificatesStateModel>,
  ): Observable<CertificatesStateModel> {
    const fetchArgs: LargeCollectionRequestArgs<"certificateCollection"> = {
      collection: "certificateCollection",
      limit: 500,
      itemQuery: CERTIFICATES_QUERY,
    };

    return this.cmsService.fetchLargeCollectionItems(fetchArgs).pipe(
      map(({ data }) => data[fetchArgs.collection]?.items || []),
      filterNullItemsPipe,
      tap(setCertificationsState(context)),
    );
  }

  /* SELECTORS */
  @Selector([LearningPathsState.selectAllLearningPathSections])
  static selectCertificateItems(
    cmsCertifications: CertificatesStateModel,
    learningPathSections: ReturnType<typeof LearningPathsState.selectAllLearningPathSections>,
  ): CertificateStoreItem[] {
    return parseCMSCertificates(cmsCertifications, learningPathSections);
  }

  @Selector([CertificatesState.selectCertificateItems])
  static selectCertificateItem(
    _: CertificatesStateModel,
    certificationItems: ReturnType<typeof CertificatesState.selectCertificateItems>,
  ) {
    return function (certificateId: string): CertificateStoreItem | undefined {
      return certificationItems.find(({ id }) => certificateId === id);
    };
  }

  @Selector([CertificatesState.selectCertificateItems])
  static selectCertificateIssuerItems(
    _: CertificatesStateModel,
    certificationItems: ReturnType<typeof CertificatesState.selectCertificateItems>,
  ): CertificateIssuerStoreItem[] {
    return reduceCertificateIssuers(certificationItems);
  }

  @Selector([CertificatesState.selectCertificateItems, ProfileState.selectProfile])
  static selectProfileCertificates(
    _: CertificatesStateModel,
    certificationItems: ReturnType<typeof CertificatesState.selectCertificateItems>,
    selectProfile: ReturnType<typeof ProfileState.selectProfile>,
  ) {
    return function (profileId?: string): ProfilePrimaryCertificateTree[] | null {
      const profile = selectProfile(profileId);
      if (!profile) return null;

      return buildProfileCertificateTrees(profile, certificationItems);
    };
  }
}
