import { gql } from "apollo-angular";

const EDITORS_LIMIT = 10;
export function LEARNING_PATHS_QUERY(limit: number, skip: number) {
  return gql`
    query {
      learningPathCollection(limit: ${limit}, skip: ${skip}) {
        total
        items {
          sys { id, publishedVersion }
          title
          url
          platformId
          creationDate
          lastModified
          description
          categories

          editorsCollection(limit: ${EDITORS_LIMIT}) {
            items {
              displayName
              email
            }
          }

          # Children section IDs
          sectionsCollection {
            items {
              sys { id }
            }
          }
        }
      }
    }
  `;
}

export function LEARNING_PATH_SECTIONS_QUERY(limit: number, skip: number) {
  return gql`
      query {
        learningPathSectionCollection( limit: ${limit}, skip: ${skip}) {
          total
          items {
            sys { id, publishedVersion }
            title
            platformId
            order
            description

            # Skill list items
            skillReferenceCollection {
              total
              items {
                ...on Certificate {
                  sys { id }
                  name
                }
                ...on Technology {
                  sys { id }
                  name
                }
              }
            }

            # Parent path IDs
            linkedFrom {
              learningPathCollection {
                items {
                  sys { id } 
                }
              }
            }
            
            # Children step IDs
            itemsCollection {
              items {
                  sys { id }
              }
            }
          }
        }
      }
    `;
}

export function LEARNING_PATH_SECTION_STEPS_QUERY(pathSectionItemIds: string[]) {
  return function (limit: number, skip: number) {
    const whereClause = getWhereClause(pathSectionItemIds);

    return gql`
      query {
        learningPathItemCollection(
          limit: ${limit},
          skip: ${skip},
          where: ${whereClause}
        ) {
          total
          items {
            sys { id, publishedVersion }
            title
            platformId
            order
            duration
            url
            type
            numberOfItems
            thumbnail { 
              url
              size100X100
              size480X270
              size125H
              size200H
              size240X135
              size304X171
              size48X27
              size50X50
              size750X422
              size75X75
              size96X54
            }

            # Parent section IDs
            linkedFrom {
              learningPathSectionCollection {
                items {
                  sys { id }
                }
              }
            }
          }
        }
      }
    `;
  };
}

function getWhereClause(ids: string[]): string {
  return `{
    OR: [
      ${ids.map(id => `{ sys: { id: "${id}" } }`).join(", ")}
    ]
  }`;
}
