import { Pipe, PipeTransform } from "@angular/core";
import {
  ProfileTree,
  isProfileAoETree,
  isProfileSkillTree,
  isProfileSpecTree,
} from "src/app/store/catalogue/models/profile-aoe-tree.model";
import {
  isProfilePrimaryCertificateTree,
  isProfileSecondaryCertificateTree,
} from "src/app/store/certificates/models/profile-certificate-tree.model";
import {
  isProfilePrimaryDomainTree,
  isProfileSecondaryDomainTree,
} from "src/app/store/domain-knowledge/models/profile-domain-knowledge-tree.model";
import {
  isProfilePrimaryPowerSkillTree,
  isProfileSecondaryPowerSkillTree,
} from "src/app/store/power-skills/models/power-skill-tree.model";

@Pipe({ name: "sortProfileTrees" })
export class SortProfileTreesPipe implements PipeTransform {
  transform<T extends ProfileTree>(trees?: T[] | null): T[] {
    if (!trees) return [];

    /**
     * The pipe might receive immutable data,
     * a copy is created to avoid errors
     */
    const sortedTrees = [...trees];

    // Sort alphabetically by name
    return sortedTrees.sort((treeA, treeB) => {
      const nameA = isProfileAoETree(treeA)
        ? treeA.aoeItem.name
        : isProfileSpecTree(treeA)
        ? treeA.specItem.name
        : isProfileSkillTree(treeA)
        ? treeA.skillItem.name
        : isProfilePrimaryDomainTree(treeA) ||
          isProfilePrimaryPowerSkillTree(treeA) ||
          isProfilePrimaryCertificateTree(treeA)
        ? treeA.primaryItem?.name || ""
        : isProfileSecondaryDomainTree(treeA) ||
          isProfileSecondaryPowerSkillTree(treeA) ||
          isProfileSecondaryCertificateTree(treeA)
        ? treeA.secondaryItem?.name || ""
        : "";

      const nameB = isProfileAoETree(treeB)
        ? treeB.aoeItem.name
        : isProfileSpecTree(treeB)
        ? treeB.specItem.name
        : isProfileSkillTree(treeB)
        ? treeB.skillItem.name
        : isProfilePrimaryDomainTree(treeB) ||
          isProfilePrimaryPowerSkillTree(treeB) ||
          isProfilePrimaryCertificateTree(treeB)
        ? treeB.primaryItem?.name || ""
        : isProfileSecondaryDomainTree(treeB) ||
          isProfileSecondaryPowerSkillTree(treeB) ||
          isProfileSecondaryCertificateTree(treeB)
        ? treeB.secondaryItem?.name || ""
        : "";

      return nameA.localeCompare(nameB);
    });
  }
}
