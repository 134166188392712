import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { concatMap, tap } from "rxjs/operators";
import { TeamMembersStoreService } from "src/app/store/team-members/services/team-members-store.service";

@Injectable({ providedIn: "root" })
export class TeamGuard implements CanActivate {
  constructor(private router: Router, private teamMemberStore: TeamMembersStoreService) {}

  canActivate(): Observable<boolean> {
    const fetchTeamMembers$ = this.teamMemberStore.fetchTeamMembers();
    const isTeamLead$ = this.teamMemberStore.selectIsTeamLead();

    return fetchTeamMembers$.pipe(
      concatMap(() => isTeamLead$),
      tap(isAllowed => {
        if (!isAllowed) this.router.navigate(["/"]);
      }),
    );
  }
}
