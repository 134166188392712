import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { GetAvatar } from "./avatars.actions";
import { AvatarModel, AvatarsStateModel } from "./avatar.model";
import { MY_PROFILE_KEY } from "../profile/profile.model";

@State<AvatarsStateModel>({
  name: "avatars",
  defaults: {},
})
@Injectable()
export class AvatarsState {
  private firedRequests: Record<string, true | undefined> = {};

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  /* ACTIONS */
  @Action(GetAvatar)
  getAvatar(
    { getState, patchState }: StateContext<AvatarsStateModel>,
    { profileId, refreshRequest }: GetAvatar,
  ): Observable<AvatarModel> {
    const profileKey = profileId || MY_PROFILE_KEY;

    if (!this.firedRequests[profileKey] || refreshRequest) {
      const suffix = profileId ? `users/${profileId}` : "me";
      const url = `https://graph.microsoft.com/v1.0/${suffix}/photo/$value`;
      this.firedRequests[profileKey] = true;

      return this.http.get(url, { responseType: "blob" }).pipe(
        map(res => new Blob([res])),
        map(URL.createObjectURL),
        map(url => this.sanitizer.bypassSecurityTrustUrl(url)),
        tap(avatar => patchState({ [profileKey]: avatar })),
      );
    }

    return of(getState()[profileKey]);
  }

  /* SELECTORS */
  @Selector()
  static selectAvatar(avatars: AvatarsStateModel) {
    return function (profileId?: string): AvatarModel | undefined {
      return avatars?.[profileId || MY_PROFILE_KEY];
    };
  }
}
