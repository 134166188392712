<a
  class="sidebar-link__item"
  [class.sidebar-link--active]="active$ | async"
  [routerLink]="item.path"
>
  <img *ngIf="item.iconUrl" class="sidebar-link__icon" [src]="item.iconUrl" />
  <div class="item-name">
    {{ item.linkText }}
    <span *ngIf="showDot" class="unread"></span>
  </div>
</a>
