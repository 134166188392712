import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { PageCollection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { ProfilePageText } from "../cms-text-data.model";
import { cmsPagesIds } from "../../utils/cms-pages-ids.utils";

export function parseProfilePageText(pageCollection?: PageCollection | null): ProfilePageText {
  const profilePage = pageCollection?.items
    .filter(filterNull)
    .find(page => page.sys.id === cmsPagesIds.profile);

  return {
    title: profilePage?.title || "",
    domainKnowledgeTitle: profilePage?.headingSection1 || "",
    aoeTitle: profilePage?.headingSection2 || "",
    specAndSkillsTitle: profilePage?.headingSection3 || "",
    noSpecialisationsText: documentToHtmlString(profilePage?.descriptionSection2?.json) || "",
    noSkillsText: documentToHtmlString(profilePage?.descriptionSection3?.json) || "",
  };
}
