import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { TeamGuard } from "@shared/guards/team.guard";
import { ProfileSearchGuard } from "@shared/guards/profile-search.guard";
import { AdminService } from "src/environments/environment";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "env",
        pathMatch: "full",
        component: AdminService.getAdminRouteComponent(),
      },
      {
        path: "",
        canActivate: [MsalGuard],
        loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule),
      },
      {
        path: "auth/redirect",
        pathMatch: "full",
        loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule),
      },
      {
        path: "catalogue",
        canActivate: [MsalGuard],
        loadChildren: () =>
          import("./pages/catalogue/catalogue.module").then(m => m.CatalogueModule),
      },
      {
        path: "addFlow",
        canActivate: [MsalGuard],
        loadChildren: () =>
          import("./pages/catalogue-add-flow/catalogue-add-flow.module").then(
            m => m.CatalogueAddFlowModule,
          ),
      },
      {
        path: "team",
        canActivate: [MsalGuard, TeamGuard],
        loadChildren: () => import("./pages/team/team.module").then(m => m.TeamModule),
      },
      {
        path: "team-profiles",
        canActivate: [MsalGuard, TeamGuard],
        loadChildren: () => import("./pages/peers/peers.module").then(m => m.PeersModule),
      },
      {
        path: "help",
        canActivate: [MsalGuard],
        loadChildren: () => import("./pages/help/help.module").then(m => m.HelpModule),
      },
      {
        path: "news",
        canActivate: [MsalGuard],
        loadChildren: () => import("./pages/news/news.module").then(m => m.NewsModule),
      },
      {
        path: "profile-search",
        canActivate: [MsalGuard, ProfileSearchGuard],
        loadChildren: () =>
          import("./pages/profile-search/profile-search.module").then(m => m.ProfileSearchModule),
      },
      {
        path: "user-profile",
        canActivate: [MsalGuard, ProfileSearchGuard],
        loadChildren: () =>
          import("./pages/user-profile/user-profile.module").then(m => m.UserProfileModule),
      },
      {
        path: "learning-paths",
        canActivate: [MsalGuard],
        loadChildren: () =>
          import("./pages/learning-paths/learning-paths.module").then(m => m.LearningPathsModule),
      },
      {
        path: "**",
        canActivate: [MsalGuard],
        loadChildren: () =>
          import("./pages/page-not-found/page-not-found.module").then(m => m.PageNotFoundModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
