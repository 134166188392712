import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Observable } from "rxjs";
import { LocationStateModel } from "./location.model";
import { GetLocations } from "./location.actions";
import { environment } from "src/environments/environment";
import { tap } from "rxjs/operators";

@State<LocationStateModel>({
  name: "location",
  defaults: [],
})
@Injectable()
export class LocationState {
  constructor(private http: HttpClient) {}

  /** ACTIONS */
  @Action(GetLocations)
  fetchLocation({ setState }: StateContext<LocationStateModel>): Observable<string[]> {
    return this.http
      .get<string[]>(`${environment.AZ_URL}/search/locations`)
      .pipe(tap(data => setState(data.map(name => ({ name })))));
  }

  /* SELECTORS */

  @Selector()
  static selectLocation(locations: LocationStateModel) {
    return locations;
  }
}
