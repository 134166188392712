import { isCertificationPendingChange, TeamMember } from "../models/team-members.model";
import { CertificationPendingRequestTree } from "../models/pending-request-tree.model";

export function buildCertificationPendingRequestTrees({
  pending_changes,
}: TeamMember): CertificationPendingRequestTree[] {
  const trees: CertificationPendingRequestTree[] = [];
  const certificationPendingRequests = pending_changes.filter(isCertificationPendingChange);

  /**
   * Since the parentId is not available for certifications,
   * a single tree is generated with all certification pending requests
   */
  if (certificationPendingRequests.length > 0) {
    trees.push({ certificationPendingRequests });
  }

  return trees;
}
