import { CataloguePendingRequestTree } from "../models/pending-request-tree.model";
import {
  AoEPendingChange,
  SkillPendingChange,
  SpecPendingChange,
  TeamMember,
  isAoEPendingChange,
  isSkillPendingChange,
  isSpecPendingChange,
} from "../models/team-members.model";

export function buildAoEPendingRequestTrees({
  pending_changes,
}: TeamMember): CataloguePendingRequestTree[] {
  const trees: CataloguePendingRequestTree[] = [];

  pending_changes.forEach(pendingReq => {
    if (isAoEPendingChange(pendingReq)) {
      const aoeReq = pendingReq;
      const aoeTree = getAoETree(trees, aoeReq);
      if (!aoeTree) newAoETree(trees, aoeReq);
    } else if (isSpecPendingChange(pendingReq)) {
      const aoeReq = pendingReq.parent_change_request;
      const specReq = pendingReq;
      const aoeTree = getAoETree(trees, aoeReq);

      if (!aoeTree) {
        newAoETree(trees, aoeReq, specReq);
      } else if (isPendingChangeNotInTree(aoeTree.specPendingRequests, specReq)) {
        aoeTree.specPendingRequests.push(specReq);
      }
    } else if (isSkillPendingChange(pendingReq)) {
      const aoeReq = pendingReq.parent_change_request.parent_change_request;
      const specReq = pendingReq.parent_change_request;
      const skillReq = pendingReq;
      const aoeTree = getAoETree(trees, aoeReq);

      if (!aoeTree) {
        newAoETree(trees, aoeReq, specReq, skillReq);
      } else if (isPendingChangeNotInTree(aoeTree.skillPendingRequests, skillReq)) {
        if (isPendingChangeNotInTree(aoeTree.specPendingRequests, specReq)) {
          aoeTree.specPendingRequests.push(specReq);
        }
        aoeTree.skillPendingRequests.push(skillReq);
      }
    }
  });

  return trees;
}

function getAoETree(
  trees: CataloguePendingRequestTree[],
  aoeReq: AoEPendingChange,
): CataloguePendingRequestTree | undefined {
  return trees.find(({ aoePendingRequest }) => aoePendingRequest.entity_id === aoeReq.entity_id);
}

function newAoETree(
  trees: CataloguePendingRequestTree[],
  aoeReq: AoEPendingChange,
  specReq?: SpecPendingChange,
  skillReq?: SkillPendingChange,
): void {
  trees.push({
    aoePendingRequest: aoeReq,
    specPendingRequests: specReq ? [specReq] : [],
    skillPendingRequests: skillReq ? [skillReq] : [],
  });
}

function isPendingChangeNotInTree(
  reqs: SpecPendingChange[] | SkillPendingChange[],
  req: SpecPendingChange | SkillPendingChange,
): boolean {
  return reqs.findIndex(({ change_id }) => change_id === req.change_id) < 0;
}
