import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { GetProfileSearchData, ResetProfileSearchData } from "./profile-search.actions";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { ProfileSearchModel, ProfileSearchStateModel } from "./profile-search.model";

@State<ProfileSearchStateModel>({
  name: "profileSearch",
  defaults: [],
})
@Injectable()
export class ProfileSearchState {
  constructor(private http: HttpClient) {}

  /** ACTIONS */
  @Action(GetProfileSearchData)
  fetchProfileSearchData(
    { setState }: StateContext<ProfileSearchStateModel>,
    { name }: GetProfileSearchData,
  ) {
    return this.http
      .get<ProfileSearchModel[]>(`${environment.AZ_URL}/search/profiles-by-name?name=${name}`)
      .pipe(tap(data => setState(data)));
  }

  @Action(ResetProfileSearchData)
  resetProfileSearchData({ setState }: StateContext<ProfileSearchStateModel>) {
    setState([]);
  }

  /** SELECTORS */
  @Selector()
  static selectGetProfileSearchData(
    profileSearchData: ProfileSearchStateModel,
  ): ProfileSearchStateModel {
    return profileSearchData;
  }
}
