import { SidebarCollection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { SidebarTextItem } from "./../cms-text-data.model";

export function parseSidebarTextItems(
  sidebarCollection?: SidebarCollection | null,
): SidebarTextItem[] {
  const sidebar = sidebarCollection?.items?.[0];
  if (!sidebar) return [];

  const items = sidebar.sidebarItemCollection?.items.filter(filterNull) || [];

  return items.map(item => ({
    title: item.title || "",
    isForAll: !!item.isForAll,
    isForTeamLead: !!item.isForTeamLead,
    isForCmPm: !!item.isForCmPm,
    name: item.name || "",
    path: item.path || "",
    linkText: item.linkText || "",
    iconUrl: item.icon?.url || "",
  }));
}
