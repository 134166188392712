import {
  TeamMemberPendingChange,
  UpdateMultiplePendingRequestResponse,
  UpdateTeamMembersPendingRequestResponse,
} from "../models/team-members.model";

export function updatePendingChanges(
  pendingChanges: TeamMemberPendingChange[],
  response: Response,
): TeamMemberPendingChange[] {
  const { change_id, status } = response;

  return pendingChanges.map(pendingChange => {
    if (change_id !== pendingChange.change_id) return pendingChange;

    return { ...pendingChange, status };
  });
}

type Response = UpdateTeamMembersPendingRequestResponse | UpdateMultiplePendingRequestResponse[0];
