import { PendingRequestTree } from "../models/pending-request-tree.model";
import { TeamMember } from "../models/team-members.model";
import { buildAoEPendingRequestTrees } from "./build-catalogue-pending-request-trees.utils";
import { buildCertificationPendingRequestTrees } from "./build-certification-pending-request-trees.utils";
import { buildDomainPendingRequestTrees } from "./build-domain-pending-request-trees.utils";
import { buildPowerSkillPendingRequestTrees } from "./build-power-skill-pending-request-trees.utils";

export function buildTeamMemberPendingRequestTrees(teamMember: TeamMember): PendingRequestTree[] {
  return [
    ...buildAoEPendingRequestTrees(teamMember),
    ...buildDomainPendingRequestTrees(teamMember),
    ...buildPowerSkillPendingRequestTrees(teamMember),
    ...buildCertificationPendingRequestTrees(teamMember),
  ];
}
