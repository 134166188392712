import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CurrentRouteService } from "@shared/services/current-route.service";
import { SidebarItem } from "../../models/sidebar-item.model";

@Component({
  selector: "sm-sidebar-item",
  templateUrl: "./sidebar-item.component.html",
  styleUrls: ["../sidebar/sidebar.component.scss"],
})
export class SidebarItemComponent implements OnInit {
  @Input() item!: SidebarItem;
  @Input() showDot: boolean | null = false;
  active$!: Observable<boolean>;

  constructor(private currentRouteService: CurrentRouteService) {}

  ngOnInit(): void {
    this.active$ = this.currentRouteService.getCurrentRoute().pipe(
      map(fullUrl => fullUrl.split("?")[0]),
      map(urlWithoutQueryParams => urlWithoutQueryParams.split("/")[1]),
      map(urlFirstSegment => urlFirstSegment === this.item.path.split("/")[1]),
    );
  }
}
