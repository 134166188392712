import { StateContext } from "@ngxs/store";
import { ChangePrimaryDomainResponse, MY_PROFILE_KEY, ProfileStateModel } from "../profile.model";

export function updateProfileStatePrimaryDomains({
  getState,
  patchState,
}: StateContext<ProfileStateModel>) {
  return function (response: ChangePrimaryDomainResponse): void {
    const profile = getState()[MY_PROFILE_KEY];

    patchState({
      [MY_PROFILE_KEY]: {
        ...profile,
        primary_domains: [
          ...profile.primary_domains,
          {
            entry_id: response.entity_id,
            pending: response.status === "pending",
          },
        ],
      },
    });
  };
}
