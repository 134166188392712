import { ProfileModel } from "../profile.model";

export function getProfilePendingRequestNumber(profile?: ProfileModel): number {
  if (!profile) return 0;

  return profileKeys.reduce(
    (total, key) => total + profile[key].filter(entry => entry.pending).length,
    0,
  );
}

const profileKeys: ProfileKey[] = [
  "areas_of_expertise",
  "specialisations",
  "skills",
  "primary_domains",
  "secondary_domains",
];

type ProfileKey = Extract<
  keyof ProfileModel,
  "areas_of_expertise" | "specialisations" | "skills" | "primary_domains" | "secondary_domains"
>;
