import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TeamMembersState } from "../team-members.state";
import { TeamMember, TeamMembersStateModel } from "../models/team-members.model";
import { GetTeamMembers } from "../team-members.actions";
import { PendingRequestTree } from "../models/pending-request-tree.model";

@Injectable({ providedIn: "root" })
export class TeamMembersStoreService {
  @Select(TeamMembersState.selectTeamMembers)
  private teamMembers$!: Observable<TeamMembersStateModel>;

  @Select(TeamMembersState.selectTeamMember)
  private selectTeamMember$!: Observable<ReturnType<typeof TeamMembersState.selectTeamMember>>;

  @Select(TeamMembersState.selectPendingRequestTrees)
  private selectPendingRequestTrees$!: Observable<
    ReturnType<typeof TeamMembersState.selectPendingRequestTrees>
  >;

  constructor(private store: Store) {}

  fetchTeamMembers() {
    return this.store.dispatch(new GetTeamMembers());
  }

  /**
   * @returns array with all fetched team members
   */
  selectTeamMembers(): Observable<TeamMember[]> {
    return this.teamMembers$;
  }

  /**
   * @returns is team lead or not
   */
  selectIsTeamLead(): Observable<boolean> {
    return this.selectTeamMembers().pipe(map(teamMembers => teamMembers.length > 0));
  }

  /**
   * @param teamMemberId provided for specific team member, if not, for all
   * @returns array with all fetched team members for the specified ID or all
   */
  selectTeamMember(teamMemberId?: string): Observable<TeamMember | undefined> {
    return this.selectTeamMember$.pipe(map(selectTeamMember => selectTeamMember(teamMemberId)));
  }

  /**
   * @param teamMemberId provided for specific team member
   * @returns is team member or not
   */
  selectIsTeamMember(teamMemberId?: string): Observable<boolean> {
    return this.selectTeamMember(teamMemberId).pipe(map(teamMember => !!teamMember));
  }

  /**
   * @param teamMemberId provided for specific team member, if not, for all
   * @returns array with all built request trees for the specified ID or all
   */
  selectPendingRequestTrees(teamMemberId?: string): Observable<PendingRequestTree[]> {
    return this.selectPendingRequestTrees$.pipe(
      map(selectPendingRequestTrees => selectPendingRequestTrees(teamMemberId)),
    );
  }
}
