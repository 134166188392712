import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AvatarModel } from "src/app/store/avatars/avatar.model";
import { AvatarsStoreService } from "src/app/store/avatars/services/avatars-store.service";
import { SidebarText } from "src/app/store/cms-text-data/cms-text-data.model";
import { SidebarItemsService } from "../../services/sidebar.service";
import { SidebarItem } from "../../models/sidebar-item.model";

@Component({
  selector: "sm-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  constructor(
    private avatarStore: AvatarsStoreService,
    private sidebarService: SidebarItemsService,
  ) {}

  ngOnInit(): void {
    this.sidebarService.fetchSidebarInfo();
  }

  get sidebarText$(): Observable<SidebarText> {
    return this.sidebarService.selectSidebarText();
  }

  get sidebarItems$(): Observable<SidebarItem[]> {
    return this.sidebarService.selectSidebarItems();
  }

  get msalProfileName$(): Observable<string> {
    return this.sidebarService.getMSalProfileName();
  }

  get emailNotificationStatus$(): Observable<boolean> {
    return this.sidebarService.getEmailNotificationStatus();
  }

  get myAvatar$(): Observable<AvatarModel | undefined> {
    return this.avatarStore.selectAvatar();
  }

  get myLocation$(): Observable<string> {
    return this.sidebarService.getLocation();
  }
}
