import { gql } from "apollo-angular";

const BASIC_ITEM_QUERY = `
  sys { id, publishedVersion }
  name
`;

const LEVELS_QUERY = `
  junior { json }
  regular { json }
  senior { json }
  principal { json }
  seniorPrincipal { json }
`;

export const AOE_ITEMS_QUERY = (limit: number, skip: number) => gql`
  query {
    areaOfExpertiseCollection(limit: ${limit}, skip: ${skip}) {
      total
      items {
        ${BASIC_ITEM_QUERY}
        ${LEVELS_QUERY}
        icon { url }
        illustration { url }
        levelDescriptionTitle
        description
        specialisationsCollection {
          items {
            sys { id }
          }
        }
      }
    }
  }
`;

export const SPEC_ITEMS_QUERY = (limit: number, skip: number) => gql`
  query {
    specialisationCollection(limit: ${limit}, skip: ${skip}) {
      total
      items {
        ${BASIC_ITEM_QUERY}
        ${LEVELS_QUERY}
        description 
        technologiesCollection {
          items {
            sys { id }
          }
        }
      }
    }
  }
`;

export const SKILL_ITEMS_QUERY = (limit: number, skip: number) => gql`
  query {
    technologyCollection(limit: ${limit}, skip: ${skip}) {
      total
      items {
        ${BASIC_ITEM_QUERY}
        description { json }
      }
    }
  }
`;
