import { Injectable } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class MsalProfileService {
  private msalProfile$ = new BehaviorSubject<AccountInfo | null>(null);

  getProfile(): Observable<AccountInfo | null> {
    return this.msalProfile$;
  }

  setProfile(profile: AccountInfo): void {
    this.msalProfile$.next(profile);
  }
}
