import { CatalogueStoreItemStatus } from "../../catalogue/catalogue.model";
import { ProfileTree } from "../../catalogue/models/profile-aoe-tree.model";
import { PrimaryPowerSkillItem, SecondaryPowerSkillItem } from "./power-skills.model";

export interface ProfilePrimaryPowerSkillTree {
  primaryItem: PrimaryPowerSkillItem;
  primaryStatus: CatalogueStoreItemStatus;
  secondaryTrees: ProfileSecondaryPowerSkillTree[];
}

export function isProfilePrimaryPowerSkillTree(
  tree: ProfileTree,
): tree is ProfilePrimaryPowerSkillTree {
  return "primaryItem" in tree && tree.primaryItem.type === "PrimaryPowerSkill";
}

export interface ProfileSecondaryPowerSkillTree {
  secondaryItem: SecondaryPowerSkillItem;
  secondaryStatus: CatalogueStoreItemStatus;
}

export function isProfileSecondaryPowerSkillTree(
  tree: ProfileTree,
): tree is ProfileSecondaryPowerSkillTree {
  return "secondaryItem" in tree && tree.secondaryItem.type === "SecondaryPowerSkill";
}
