import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotifyComponent } from "@shared/components/notify/notify.component";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  isOpen = false;
  constructor(private snackbar: MatSnackBar) {}

  showMessage(message: string) {
    if (this.isOpen) return;

    this.isOpen = true;
    const ref = this.snackbar.openFromComponent(NotifyComponent, {
      data: {
        message,
        icon: "check",
      },
      duration: 5 * 1000,
      panelClass: ["sb-panel", "sb-panel--message"],
    });

    ref
      .afterDismissed()
      .toPromise()
      .then(() => {
        this.isOpen = false;
      });
  }

  showError(args: { message?: string; error?: Error }): void {
    if (this.isOpen) return;
    const { message, error } = args;
    if (error) console.error(error);

    const defaultMessage = "We're sorry! An error occurred, please try again later!";

    this.isOpen = true;
    const ref = this.snackbar.openFromComponent(NotifyComponent, {
      data: { message: message || defaultMessage },
      duration: 10 * 1000,
      panelClass: ["sb-panel", "sb-panel--error"],
    });

    ref
      .afterDismissed()
      .toPromise()
      .then(() => {
        this.isOpen = false;
      });
  }
}
