import { environment } from "src/environments/environment";

let emptyProfile = "ZuG68bMrOdmwFpLtNJBzR";
let profile = "478mJFkFpyVp0Sx0YMmvHo";
let skillCatalogue = "36Hh9Q0DprrU7eAOUTI6Td";
let help = "pxGGgEPmrEvjQpIjOGaRx";
let approvals = "2guIujJlDvQO9gK9Zy59dW";
let profileSearch = "Mdu4OUNePmJza4bIeuNP2";
let searchResultTable = "5PNuI2FUoFH6jL8BM5lCIG";
let pageNotFound = "4HIQDR3ctoizE7f9SlfIsP";
let learningPath = "5IvrWxgVOESr9XfiUZci4u";

const isStaging = !environment.IS_PROD && environment.MA_REDIRECT_URI.includes("staging");
if (isStaging) {
  emptyProfile = "ZuG68bMrOdmwFpLtNJBzR";
  profile = "478mJFkFpyVp0Sx0YMmvHo";
  skillCatalogue = "36Hh9Q0DprrU7eAOUTI6Td";
  help = "pxGGgEPmrEvjQpIjOGaRx";
  approvals = "2guIujJlDvQO9gK9Zy59dW";
  profileSearch = "Mdu4OUNePmJza4bIeuNP2";
  searchResultTable = "719OHOJgMbBNnaplVlzjE8";
  pageNotFound = "4mUrNffxpvBNp1ir1ObXSV";
  learningPath = "2cZGpE3C2pxuQDhB3MrW8K";
}

const isProduction = environment.IS_PROD;
if (isProduction) {
  emptyProfile = "6EQNiFGZVybFUlOFR3yQhP";
  profile = "ZuG68bMrOdmwFpLtNJBzR";
  skillCatalogue = "36Hh9Q0DprrU7eAOUTI6Td";
  help = "pxGGgEPmrEvjQpIjOGaRx";
  approvals = "4m6MPxYlRi0xUEDrZbxiZ8";
  profileSearch = "3cm4yJ3WGAtiay4cBIGbae";
  searchResultTable = "5g7EWFoLxUlUDd7icLY8Mp";
  pageNotFound = "4DllN3mhPpIWURi8S007WK";
  learningPath = "7p7TYXEFwtjGYGoE55kcJ";
}

export const cmsPagesIds = {
  emptyProfile,
  profile,
  skillCatalogue,
  help,
  approvals,
  profileSearch,
  searchResultTable,
  pageNotFound,
  learningPath,
} as const;
