import { Component, OnInit } from "@angular/core";
import { AdminService } from "src/environments/environment";

@Component({
  selector: "sm-envc",
  templateUrl: "./env.component.html",
  styleUrls: ["./env.component.scss"],
})
export class EnvComponent implements OnInit {
  constructor(private adminService: AdminService) {}

  ngOnInit() {
    this.adminService.createAdmin();
  }
}
