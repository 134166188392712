import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Technology } from "@shared/models/contentful.types";
import { SkillStoreItem, SpecStoreItem } from "../catalogue.model";
import { LearningPathSectionStoreItem } from "../../learning-paths/models/learning-paths.model";
import { getSkillRelatedLearningPathIds } from "./get-reference-related-learning-path-ids.utils";

export function formatCMSSkillItem(
  specs: SpecStoreItem[],
  learningPathSections: LearningPathSectionStoreItem[],
) {
  return function (skill: Technology): SkillStoreItem {
    const id = skill.sys.id;
    const parentSpecs = specs.filter(spec => spec.skillChildrenIds.includes(id));

    return {
      id,
      type: skill.__typename,
      entityVersion: skill.sys.publishedVersion || 1,
      name: skill.name || "",
      parentIds: parentSpecs.map(spec => spec.id),
      parentNames: parentSpecs.map(spec => spec.name),
      description: documentToHtmlString(skill.description?.json),
      learningPathIds: getSkillRelatedLearningPathIds(id, learningPathSections),
    };
  };
}
