export const POPUPS_QUERY = (limit: number): string => `
    popUpCollection(limit: ${limit}) {
        total
        items {
            sys { id }
            name
            title
            description { json }
            descriptionTitle
            headline
            headline2
        }
    }
`;
