// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.production.ts`.
// The list of file replacements can be found in `angular.json`.

export { AdminService } from "../app/shared/admin/admin.service";

export const environment = {
  IS_PROD: false,
  //Contentful config
  CF_URL: "https://graphql.contentful.com/content/v1/spaces/$space$/environments/$env$",
  CF_SPACE: "g1lk475ncm86",
  CF_ENVIRONMENT: "development",
  // Azure config
  AZ_URL: "https://skillmatrixdev.azure-api.net",
  SUB_KEY: "f55bbb2b00ff4102bc7601776752fcd6",
  // MSAL Auth config
  MA_CLIENT_ID: "b98c710e-5ce4-4be2-b258-0ca352d29ce5",
  MA_AUTHORITY: "https://login.microsoftonline.com/00a83132-4221-4698-a787-6d679d557a90",
  // This has to be changed in the Azure config to redirect to / or another URL of our choosing
  // because right now /auth/redirect is the only one authorized
  MA_REDIRECT_URI: "https://dev.skills.intive.com/auth/redirect",
  // Logging
  MA_LOGGING_ENABLED: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
