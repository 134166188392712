import { PrimaryPowerSkill, SecondaryPowerSkill } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { PrimaryPowerSkillItem, SecondaryPowerSkillItem } from "../models/power-skills.model";

export function formatCMSPrimaryPowerSkill(
  primaryPowerSkill: PrimaryPowerSkill,
): PrimaryPowerSkillItem {
  const { __typename: type, sys, name, icon } = primaryPowerSkill;

  return {
    type,
    id: sys.id,
    entityVersion: sys.publishedVersion || 1,
    name: name || "",
    iconUrl: icon?.url || "",
    secondaryPowerSkillItems: formatSecondaryPowerSkillItems(primaryPowerSkill),
  };
}

function formatSecondaryPowerSkillItems(
  primaryPowerSkill: PrimaryPowerSkill,
): SecondaryPowerSkillItem[] {
  return (
    primaryPowerSkill.secondaryPowerSkillsCollection?.items
      .filter(filterNull)
      .map(formatCMSSecondaryPowerSkill(primaryPowerSkill)) || []
  );
}

function formatCMSSecondaryPowerSkill(primaryPowerSkill: PrimaryPowerSkill) {
  return function (secondaryPowerSkill: SecondaryPowerSkill): SecondaryPowerSkillItem {
    const { __typename: type, sys, name } = secondaryPowerSkill;

    return {
      type,
      id: sys.id,
      entityVersion: sys.publishedAt || 1,
      name: name || "",
      iconUrl: primaryPowerSkill.icon?.url || "",
      parentId: primaryPowerSkill.sys.id,
      parentName: primaryPowerSkill.name || "",
    };
  };
}
