import {
  TeamMemberPendingChange,
  TeamMemberPendingChangeStatus,
} from "./models/team-members.model";

export class GetTeamMembers {
  static readonly type = "[TeamMembers] Get team members";
  constructor(public refreshRequest?: boolean) {}
}

export class UpdateSinglePendingRequest {
  static readonly type = "[TeamMembers] Approve or reject single pending request";
  constructor(
    public changeId: string,
    public entityVersion: number,
    public status: TeamMemberPendingChangeStatus,
    public comment?: string,
  ) {}
}

export class UpdateMultiplePendingRequest {
  static readonly type = "[TeamMembers] Approve or reject multiple pending requests";
  constructor(
    public changeIds: string[],
    public entityVersions: number[],
    public status: TeamMemberPendingChangeStatus,
    public comment?: string,
  ) {}
}

export class RemovePendingRequests {
  static readonly type = "[TeamMembers] Remove pending requests";
  constructor(public pendingRequests: TeamMemberPendingChange[]) {}
}
