import { ProfileSearchFormItem } from "./profile-search-form.model";

export class UpdateAvailability {
  static readonly type = "[Profile Search Form] Update availability";
  constructor(public availability: number) {}
}

export class UpdateDate {
  static readonly type = "[Profile Search Form] Update date";
  constructor(public date: string) {}
}

export class AddLocation {
  static readonly type = "[Profile Search Form] Add location";
  constructor(public location: string) {}
}

export class DeleteLocation {
  static readonly type = "[Profile Search Form] Delete location";
  constructor(public location: string) {}
}

export class AddAreasOfExpertise {
  static readonly type = "[Profile Search Form] Add areas of expertise";
  constructor(public aoe: ProfileSearchFormItem) {}
}

export class DeleteAreasOfExpertise {
  static readonly type = "[Profile Search Form] Delete areas of expertise";
  constructor(public aoe: ProfileSearchFormItem["item"]) {}
}

export class AddSpecialisation {
  static readonly type = "[Profile Search Form] Add specialisation";
  constructor(public spec: ProfileSearchFormItem) {}
}

export class DeleteSpecialisation {
  static readonly type = "[Profile Search Form] Delete specialisation";
  constructor(public spec: ProfileSearchFormItem["item"]) {}
}

export class AddSkill {
  static readonly type = "[Profile Search Form] Add skill";
  constructor(public skill: ProfileSearchFormItem) {}
}

export class DeleteSkill {
  static readonly type = "[Profile Search Form] Delete skill";
  constructor(public skill: ProfileSearchFormItem["item"]) {}
}

export class AddPrimaryDomain {
  static readonly type = "[Profile Search Form] Add primary domain";
  constructor(public primaryDomain: ProfileSearchFormItem) {}
}

export class DeletePrimaryDomain {
  static readonly type = "[Profile Search Form] Delete primary domain";
  constructor(public primaryDomain: ProfileSearchFormItem["item"]) {}
}

export class AddSecondaryDomain {
  static readonly type = "[Profile Search Form] Add secondary domain";
  constructor(public secondaryDomain: ProfileSearchFormItem) {}
}

export class DeleteSecondaryDomain {
  static readonly type = "[Profile Search Form] Delete secondary domain";
  constructor(public secondaryDomain: ProfileSearchFormItem["item"]) {}
}

export class AddCertification {
  static readonly type = "[Profile Search Form] Add certification";
  constructor(public certification: ProfileSearchFormItem) {}
}

export class DeleteCertification {
  static readonly type = "[Profile Search Form] Delete certification";
  constructor(public certification: ProfileSearchFormItem["item"]) {}
}

export class SubmitProfileSearchData {
  static readonly type = "[Profile Search Form] Submit data";
}

export class ResetProfileSearchData {
  static readonly type = "[Profile Search Form] Reset data";
}

export class ToggleCriteria {
  static readonly type = "[Profile Search Form] Toggle criteria";
  constructor(public id: string) {}
}

export class ResetDisabledSearchCriteria {
  static readonly type = "[Profile Search Form] Reset disabled search criteria";
}
