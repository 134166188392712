import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

interface IVideoModalData {
  url: string;
}

@Component({
  selector: "sm-video-modal",
  templateUrl: "./video-modal.component.html",
  styleUrls: ["./video-modal.component.scss"],
})
export class VideoModalComponent implements OnInit {
  videoUrl: SafeHtml = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IVideoModalData,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.data.url);
  }
}
