import { StateContext } from "@ngxs/store";
import { MY_PROFILE_KEY, ProfileEntry, ProfileModel, ProfileStateModel } from "../profile.model";
import { DeleteProfileEntryBody } from "../models/delete-profile-entry.model";

export function deleteProfileEntry(
  context: StateContext<ProfileStateModel>,
  requestBody: DeleteProfileEntryBody,
): void {
  const myProfile = context.getState()[MY_PROFILE_KEY];
  const filteredProfile = removeProfileEntries(myProfile, requestBody);
  if (!filteredProfile) return;

  context.patchState({
    [MY_PROFILE_KEY]: {
      ...myProfile,
      ...filteredProfile,
    },
  });
}

function removeProfileEntries(
  p: ProfileModel,
  requestBody: DeleteProfileEntryBody,
): Partial<ProfileModel> | undefined {
  const { entity_type, entity_id, parent_id } = requestBody;

  if (entity_type === "areas_of_expertise") {
    const specIdsToRemove = p.specialisations
      .filter(spec => spec.entry_id === parent_id)
      .map(spec => spec.entry_id);

    return {
      areas_of_expertise: removeEntries(p.areas_of_expertise, entity_id),
      specialisations: removeEntries(p.specialisations, parent_id),
      skills: removeEntries(p.skills, specIdsToRemove),
    };
  } else if (entity_type === "specialisation") {
    return {
      specialisations: removeEntries(p.specialisations, entity_id),
      skills: removeEntries(p.skills, parent_id),
    };
  } else if (entity_type === "competence") {
    return {
      skills: removeEntries(p.skills, entity_id),
    };
  } else if (entity_type === "primary_domain") {
    return {
      primary_domains: removeEntries(p.primary_domains, entity_id),
      secondary_domains: removeEntries(p.secondary_domains, parent_id),
    };
  } else if (entity_type === "secondary_domain") {
    return {
      secondary_domains: removeEntries(p.secondary_domains, entity_id),
    };
  } else if (entity_type === "primary_power_skill") {
    return {
      primary_power_skills: removeEntries(p.primary_power_skills, entity_id),
      secondary_power_skills: removeEntries(p.secondary_power_skills, parent_id),
    };
  } else if (entity_type === "secondary_power_skill") {
    return {
      secondary_power_skills: removeEntries(p.secondary_power_skills, entity_id),
    };
  } else if (entity_type === "certification") {
    return {
      certifications: removeEntries(p.certifications, entity_id),
    };
  } else {
    return;
  }
}

function removeEntries(entries: ProfileEntry[], toRemove: string | string[]): ProfileEntry[] {
  return entries.filter(e => {
    if (Array.isArray(toRemove)) {
      return !toRemove.includes(e.entry_id);
    } else {
      return e.entry_id !== toRemove;
    }
  });
}
