import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class CurrentRouteService {
  constructor(private router: Router) {}

  getCurrentRoute(): Observable<string> {
    return this.router.events.pipe(
      filter((event): event is RouterEvent => event instanceof NavigationEnd),
      startWith(this.router),
      map(event => event.url),
    );
  }
}
