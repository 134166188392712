export const SIDEBAR_QUERY = (limit: number): string => `
    sidebarCollection(limit: 1) {
        total
        items {
            sys { id }
            version
            title
            sidebarButton {
                icon { url }
                text
                title
            }
            sidebarItemCollection(limit: ${limit}) {
                total
                items {
                    sys { id }
                    icon { url }
                    isForAll
                    isForTeamLead
                    isForCmPm
                    linkText
                    path
                    name
                }
            }
        }
    }
`;
