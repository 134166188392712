import { StateContext } from "@ngxs/store";
import { compose, updateItem } from "@ngxs/store/operators";
import { TeamMemberPendingChange, TeamMembersStateModel } from "../models/team-members.model";
import { findTeamMember } from "./find-team-member.utils";

export function removeStatePendingRequests(
  { setState }: StateContext<TeamMembersStateModel>,
  pendingRequests: TeamMemberPendingChange[],
): void {
  const updatedItems = pendingRequests.map(pendingRequest =>
    updateItem(findTeamMember(pendingRequest), teamMember => ({
      ...teamMember,
      pending_changes: removePendingChange(teamMember.pending_changes, pendingRequest),
    })),
  );

  setState(compose(...updatedItems));
}

function removePendingChange(
  statePendingRequests: TeamMemberPendingChange[],
  pendingRequest: TeamMemberPendingChange,
): TeamMemberPendingChange[] {
  return statePendingRequests.filter(({ change_id }) => pendingRequest.change_id !== change_id);
}
