import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DeleteProfileEntryBody } from "./models/delete-profile-entry.model";
import {
  UpdateProfileEntryBody,
  UpdateProfileEntryResponse,
} from "./models/update-profile-entry.model";
import {
  ChangeProfilePrimaryDomain,
  ChangeProfilePrimaryPowerSkill,
  ChangeProfileSecondaryDomains,
  ChangeProfileSecondaryPowerSkills,
  DeleteProfileEntry,
  GetProfile,
  UpdateProfileEntry,
} from "./profile.actions";
import {
  ChangePrimaryDomainRequestBody,
  ChangePrimaryDomainResponse,
  ChangePrimaryPowerSkillRequestBody,
  ChangePrimaryPowerSkillResponse,
  ChangeSecondaryDomainRequestBody,
  ChangeSecondaryDomainResponse,
  ChangeSecondaryPowerSkillRequestBody,
  ChangeSecondaryPowerSkillResponse,
  MY_PROFILE_KEY,
  ProfileModel,
  ProfileStateModel,
} from "./profile.model";
import { deleteProfileEntry } from "./utils/delete-profile-entry.utils";
import { getProfilePendingRequestNumber } from "./utils/get-profile-pending-request-number.utils";
import { setProfileState } from "./utils/set-profile-state.utils";
import { updateProfileEntry } from "./utils/update-profile-entry.utils";
import { updateProfileStatePrimaryDomains } from "./utils/update-profile-state-primary-domains.utils";
import { updateProfileStatePrimaryPowerSkills } from "./utils/update-profile-state-primary-power-skills.utils";
import { updateProfileStateSecondaryDomains } from "./utils/update-profile-state-secondary-domains.utils";
import { updateProfileStateSecondaryPowerSkills } from "./utils/update-profile-state-secondary-power-skills.utils";

@State<ProfileStateModel>({
  name: "profile",
  defaults: {},
})
@Injectable()
export class ProfileState {
  private firedRequests: Record<string, true | undefined> = {};

  constructor(private http: HttpClient) {}

  /** ACTIONS */
  @Action(GetProfile)
  fetchProfile(
    context: StateContext<ProfileStateModel>,
    { profileId, refreshRequest }: GetProfile,
  ): Observable<ProfileModel> {
    const profileKey = profileId || MY_PROFILE_KEY;

    if (!this.firedRequests[profileKey] || refreshRequest) {
      const urlSuffix = profileId ? `/${profileId}` : "";
      this.firedRequests[profileKey] = true;

      return this.http
        .get<ProfileModel>(`${environment.AZ_URL}/profile${urlSuffix}`)
        .pipe(tap(setProfileState(profileKey, context)));
    }

    return of(context.getState()[profileKey]);
  }

  @Action(UpdateProfileEntry)
  updateProfileEntry(
    stateContext: StateContext<ProfileStateModel>,
    { entityId, parentId, entityType, entityVersion, action, level }: UpdateProfileEntry,
  ): Observable<UpdateProfileEntryResponse> {
    const body: UpdateProfileEntryBody = {
      entity_id: entityId,
      entity_type: entityType,
      entity_version: entityVersion,
      parent_id: parentId || "",
      new_value: level,
      action: action || "new",
    };

    return this.http
      .post<UpdateProfileEntryResponse>(`${environment.AZ_URL}/change`, body)
      .pipe(tap(updateProfileEntry(stateContext)));
  }

  @Action(ChangeProfilePrimaryDomain)
  updatePrimaryDomainItems(
    stateContext: StateContext<ProfileStateModel>,
    { primaryDomain }: ChangeProfilePrimaryDomain,
  ): Observable<ChangePrimaryDomainResponse> {
    const body: ChangePrimaryDomainRequestBody = {
      entity_id: primaryDomain.id,
      entity_version: primaryDomain.entityVersion,
    };

    return this.http
      .post<ChangePrimaryDomainResponse>(`${environment.AZ_URL}/change/domain/primary`, body)
      .pipe(tap(updateProfileStatePrimaryDomains(stateContext)));
  }

  @Action(ChangeProfileSecondaryDomains)
  updateSecondaryDomains(
    stateContext: StateContext<ProfileStateModel>,
    { parentId, secondaryDomains }: ChangeProfileSecondaryDomains,
  ): Observable<ChangeSecondaryDomainResponse> {
    const body: ChangeSecondaryDomainRequestBody = {
      parent_id: parentId,
      secondary_domains: secondaryDomains.map(item => ({
        entity_id: item.id,
        entity_version: item.entityVersion,
      })),
    };

    return this.http
      .post<ChangeSecondaryDomainResponse>(`${environment.AZ_URL}/change/domain/secondary`, body)
      .pipe(tap(updateProfileStateSecondaryDomains(stateContext)));
  }

  @Action(ChangeProfilePrimaryPowerSkill)
  updatePrimaryPowerSkillItems(
    stateContext: StateContext<ProfileStateModel>,
    { primaryPowerSkill }: ChangeProfilePrimaryPowerSkill,
  ): Observable<ChangePrimaryDomainResponse> {
    const body: ChangePrimaryPowerSkillRequestBody = {
      entity_id: primaryPowerSkill.id,
      entity_version: primaryPowerSkill.entityVersion,
    };

    return this.http
      .post<ChangePrimaryPowerSkillResponse>(
        `${environment.AZ_URL}/change/powerSkill/primary`,
        body,
      )
      .pipe(tap(updateProfileStatePrimaryPowerSkills(stateContext)));
  }

  @Action(ChangeProfileSecondaryPowerSkills)
  updateSecondaryPowerSkillItems(
    stateContext: StateContext<ProfileStateModel>,
    { parentId, secondaryPowerSkills }: ChangeProfileSecondaryPowerSkills,
  ): Observable<ChangeSecondaryPowerSkillResponse> {
    const body: ChangeSecondaryPowerSkillRequestBody = {
      parent_id: parentId,
      secondary_power_skills: secondaryPowerSkills.map(item => ({
        entity_id: item.id,
        entity_version: item.entityVersion,
      })),
    };

    return this.http
      .post<ChangeSecondaryPowerSkillResponse>(
        `${environment.AZ_URL}/change/powerSkill/secondary`,
        body,
      )
      .pipe(tap(updateProfileStateSecondaryPowerSkills(stateContext)));
  }

  @Action(DeleteProfileEntry)
  deleteProfileItem(
    stateContext: StateContext<ProfileStateModel>,
    { entityId, parentId, entityType }: DeleteProfileEntry,
  ): Observable<void> {
    const body: DeleteProfileEntryBody = {
      entity_id: entityId,
      entity_type: entityType,
      parent_id: parentId || "",
      dry_run: false,
    };

    return this.http
      .delete<void>(`${environment.AZ_URL}/profile/entry`, { body })
      .pipe(tap(() => deleteProfileEntry(stateContext, body)));
  }

  /* SELECTORS */
  @Selector()
  static selectProfile(profiles: ProfileStateModel) {
    return function (profileId?: string): ProfileModel | undefined {
      return profiles?.[profileId || MY_PROFILE_KEY];
    };
  }

  @Selector()
  static selectEmailNotificationStatus(profileState: ProfileStateModel): boolean {
    const myProfile = profileState?.[MY_PROFILE_KEY];
    return myProfile?.email_notification_opt_in;
  }

  @Selector()
  static selectPendingRequestNumber(profileState: ProfileStateModel) {
    return function (profileId?: string): number {
      const profile = profileState?.[profileId || MY_PROFILE_KEY];
      return getProfilePendingRequestNumber(profile);
    };
  }
}
