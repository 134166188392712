import { Pipe, PipeTransform } from "@angular/core";
import {
  CatalogueItemLevel,
  CatalogueItemLevelDescriptions,
} from "src/app/store/catalogue/catalogue.model";

@Pipe({ name: "catalogueItemLevelDescription" })
export class CatalogueStoreItemLevelDescriptionPipe implements PipeTransform {
  private get levelMap(): Record<number, CatalogueItemLevel> {
    return {
      0: "junior",
      1: "regular",
      2: "senior",
      3: "principal",
      4: "seniorPrincipal",
    };
  }

  transform(level: number, descriptions?: CatalogueItemLevelDescriptions | null): string {
    if (!descriptions) return "";

    const key = this.levelMap[level];
    return descriptions[key];
  }
}
