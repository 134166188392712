import { PrimaryDomain, SecondaryDomain } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import {
  PrimaryDomainItem,
  SecondaryDomainItem,
} from "src/app/store/domain-knowledge/domain-knowledge.models";
import { getNameAfterLastSlash } from "src/app/store/domain-knowledge/utils/get-domain-item-name.utils";

export function formatCMSPrimaryDomainItem(primaryDomain: PrimaryDomain): PrimaryDomainItem {
  const { icon, sys, name, __typename: type } = primaryDomain;

  return {
    type,
    id: sys.id,
    iconUrl: icon?.url || "",
    entityVersion: sys.publishedVersion || 1,
    name: getNameAfterLastSlash(name),
    secondaryDomainItems: formatSecondaryDomainItems(primaryDomain),
  };
}

function formatSecondaryDomainItems(primaryDomain: PrimaryDomain): SecondaryDomainItem[] {
  return (
    primaryDomain.secondaryDomainsCollection?.items
      .filter(filterNull)
      .map(formatSecondaryDomainItem(primaryDomain)) || []
  );
}

function formatSecondaryDomainItem(primaryDomain: PrimaryDomain) {
  return function (secondaryDomain: SecondaryDomain): SecondaryDomainItem {
    const { sys, name, __typename: type } = secondaryDomain;

    return {
      type,
      id: sys.id,
      entityVersion: sys.publishedVersion || 1,
      name: getNameAfterLastSlash(name),
      iconUrl: primaryDomain.icon?.url || "",
      parentId: primaryDomain.sys.id,
      parentName: getNameAfterLastSlash(primaryDomain.name),
    };
  };
}
