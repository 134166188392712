import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { NewsItem } from "src/app/store/news/news.model";
import { NewsStoreService } from "src/app/store/news/services/news-store.service";

const NEWS_LAST_VISIT = "NEWS_LAST_VISIT";

@Injectable({ providedIn: "root" })
export class NewsService {
  private lastVisit$ = new BehaviorSubject<string | null>(null);

  constructor(private newStore: NewsStoreService) {}

  private setLastVisit(visit: string): void {
    this.lastVisit$.next(visit);
  }

  saveLastVisit(): void {
    const visit = new Date().toUTCString();

    localStorage.setItem(NEWS_LAST_VISIT, visit);
    this.setLastVisit(visit);
  }

  getLastVisit(): void {
    const lastStoredVisit = localStorage.getItem(NEWS_LAST_VISIT);
    if (!lastStoredVisit) return;

    this.setLastVisit(lastStoredVisit);
  }

  getNews(): Observable<NewsItem[]> {
    return this.newStore.selectNews();
  }

  showSidebarDot(): Observable<boolean> {
    return this.newStore.selectNews().pipe(
      switchMap(news => {
        if (news.length === 0) return of(false);
        const mostRecentNewsDate: number = +news[0].publishDate;

        return this.lastVisit$.pipe(
          map(lastVisit => (lastVisit ? +lastVisit : 0)),
          map(lastVisitDate => lastVisitDate < mostRecentNewsDate),
        );
      }),
    );
  }
}
