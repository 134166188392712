import { Injectable } from "@angular/core";
import { Observable, combineLatest, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { MsalProfileService } from "@shared/services/msal-profile.service";
import { CmsTextDataService } from "src/app/store/cms-text-data/services/cms-text-data.service";
import { TeamMembersStoreService } from "src/app/store/team-members/services/team-members-store.service";
import { ProfileStoreService } from "src/app/store/profile/services/profile-store.service";
import { AvatarsStoreService } from "src/app/store/avatars/services/avatars-store.service";
import { SidebarText } from "src/app/store/cms-text-data/cms-text-data.model";
import { NewsStoreService } from "src/app/store/news/services/news-store.service";
import { SidebarItem } from "../models/sidebar-item.model";

@Injectable({ providedIn: "root" })
export class SidebarItemsService {
  constructor(
    private msalProfile: MsalProfileService,
    private teamMembersStore: TeamMembersStoreService,
    private cmsTextDataService: CmsTextDataService,
    private profileStore: ProfileStoreService,
    private avatarsStore: AvatarsStoreService,
    private newsService: NewsStoreService,
  ) {}

  fetchSidebarInfo() {
    return forkJoin([
      this.teamMembersStore.fetchTeamMembers(),
      this.avatarsStore.fetchAvatar(),
      this.newsService.fetchNews(),
    ]);
  }

  /**
   * @returns fetched sidebar items from CMS, filtered for team leads or regular employees
   */
  selectSidebarItems(): Observable<SidebarItem[]> {
    const isCmOrPm$ = this.profileStore.selectProfileIsCmOrPm();
    const isTeamLead$ = this.teamMembersStore.selectIsTeamLead();
    const sidebarItems$ = this.cmsTextDataService.selectSidebarTextItems();

    return combineLatest([isTeamLead$, isCmOrPm$, sidebarItems$]).pipe(
      map(([isTeamLead, isCmOrPm, sidebarItems]) =>
        sidebarItems.filter(
          item =>
            item.isForAll || (isCmOrPm && item.isForCmPm) || (isTeamLead && item.isForTeamLead),
        ),
      ),
    );
  }

  selectSidebarText(): Observable<SidebarText> {
    return this.cmsTextDataService.selectSidebarText();
  }

  getMSalProfileName(): Observable<string> {
    return this.msalProfile.getProfile().pipe(map(msalProfile => msalProfile?.name || ""));
  }

  getEmailNotificationStatus(): Observable<boolean> {
    return this.profileStore.selectEmailNotificationStatus();
  }

  getLocation(): Observable<string> {
    return this.profileStore.selectProfile().pipe(map(profile => profile?.location || ""));
  }
}
