<div class="catalogue-item-dialog-header">
  <div class="catalogue-item-dialog-header__text">
    <img *ngIf="iconUrl" class="catalogue-item-dialog-header__icon" [src]="iconUrl" />
    <div>
      <h3 *ngIf="upperTitle">{{ upperTitle }}</h3>
      <h1 class="catalogue-item-dialog-header__title">{{ title }}</h1>
    </div>
  </div>

  <h3 *ngIf="subTitle" class="catalogue-item-dialog-header__subtitle">
    {{ subTitle }}
  </h3>
</div>
