import { StateContext } from "@ngxs/store";
import { compose, updateItem } from "@ngxs/store/operators";
import {
  TeamMembersStateModel,
  UpdateMultiplePendingRequestResponse,
} from "../models/team-members.model";
import { findTeamMember } from "./find-team-member.utils";
import { updatePendingChanges } from "./update-pending-changes.utils";

export function updateMultiplePendingRequestsState({
  setState,
}: StateContext<TeamMembersStateModel>) {
  return function (response: UpdateMultiplePendingRequestResponse): void {
    const updateItems = response
      .filter(singleResponse => singleResponse.http_status_code === 200)
      .map(singleResponse =>
        updateItem(findTeamMember(singleResponse), teamMember => ({
          ...teamMember,
          pending_changes: updatePendingChanges(teamMember.pending_changes, singleResponse),
        })),
      );

    setState(compose(...updateItems));
  };
}
