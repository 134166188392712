import { PowerSkillPendingRequestTree } from "../models/pending-request-tree.model";
import {
  PrimaryPowerSkillPendingChange,
  SecondaryPowerSkillPendingChange,
  TeamMember,
  isPrimaryPowerSkillPendingChange,
  isSecondaryPowerSkillPendingChange,
} from "../models/team-members.model";

export function buildPowerSkillPendingRequestTrees({
  pending_changes,
}: TeamMember): PowerSkillPendingRequestTree[] {
  const trees: PowerSkillPendingRequestTree[] = [];

  pending_changes.forEach(pendingReq => {
    if (isPrimaryPowerSkillPendingChange(pendingReq)) {
      const primaryReq = pendingReq;
      const primaryTree = getPowerSkillTree(trees, primaryReq.entity_id);

      if (!primaryTree) newPowerSkillTree(trees, primaryReq);
    } else if (isSecondaryPowerSkillPendingChange(pendingReq)) {
      const primaryReq = pendingReq.parent_change_request;
      const secondaryReq = pendingReq;
      const primaryTree = getPowerSkillTree(trees, primaryReq.entity_id);

      if (!primaryTree) {
        newPowerSkillTree(trees, primaryReq, secondaryReq);
      } else if (
        isPendingChangeNotInTree(primaryTree.secondaryPowerSkillPendingRequests, secondaryReq)
      ) {
        primaryTree.secondaryPowerSkillPendingRequests.push(secondaryReq);
      }
    }
  });

  return trees;
}

function getPowerSkillTree(
  trees: PowerSkillPendingRequestTree[],
  primaryEntityId: string,
): PowerSkillPendingRequestTree | undefined {
  return trees.find(
    ({ primaryPowerSkillPendingRequest }) =>
      primaryPowerSkillPendingRequest.entity_id === primaryEntityId,
  );
}

function newPowerSkillTree(
  trees: PowerSkillPendingRequestTree[],
  primaryReq: PrimaryPowerSkillPendingChange,
  secondaryReq?: SecondaryPowerSkillPendingChange,
): void {
  trees.push({
    primaryPowerSkillPendingRequest: primaryReq,
    secondaryPowerSkillPendingRequests: secondaryReq ? [secondaryReq] : [],
  });
}

function isPendingChangeNotInTree(
  reqs: SecondaryPowerSkillPendingChange[],
  req: SecondaryPowerSkillPendingChange,
): boolean {
  return reqs.findIndex(({ change_id }) => change_id === req.change_id) < 0;
}
