import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { map, tap } from "rxjs/operators";
import { GetCMSTextData } from "./cms-text-data.actions";
import { CMS_TEXT_DATA_QUERY } from "./cms-text-data.query";
import {
  CmsDataStateModel,
  HeadlineItem,
  PopupTextItem,
  TwoLevelPanelText,
} from "./cms-text-data.model";
import { cmsTextDataDefaults as defaults } from "./cms-text-data.defaults";
import { CMSRequestService } from "../services/cms-request.service";
import { setCMSTextDataState } from "./utils/set-cms-text-data-state.utils";
import { CmsTextPopupName } from "./models/cms-text-popup-name.model";
import { CmsTextTwoLevelPanelName } from "./models/cms-text-two-level-panel-name.model";
import { CmsCatalogueHeadline } from "./models/cms-catalogue-headline.model";

@State<CmsDataStateModel>({
  name: "cmsData",
  defaults,
})
@Injectable()
export class CmsTextDataState {
  constructor(private cmsService: CMSRequestService) {}

  /** ACTIONS */
  @Action(GetCMSTextData)
  fetchCmsTextData(context: StateContext<CmsDataStateModel>) {
    return this.cmsService.cmsQuery(CMS_TEXT_DATA_QUERY()).pipe(
      map(response => response.data),
      tap(setCMSTextDataState(context)),
    );
  }

  /** SELECTORS */
  @Selector()
  static selectPopupTextItem(cmsTextDataState: CmsDataStateModel) {
    return function (popupName: CmsTextPopupName): PopupTextItem | undefined {
      return cmsTextDataState.popups.find(popup => popup.name === popupName);
    };
  }

  @Selector()
  static selectTwoLevelPanelTextItem(cmsTextDataState: CmsDataStateModel) {
    return function (twoLevelPanelName: CmsTextTwoLevelPanelName): TwoLevelPanelText | undefined {
      return cmsTextDataState.twoLevelPanels.find(
        twoLevelPanel => twoLevelPanel.name === twoLevelPanelName,
      );
    };
  }

  @Selector()
  static selectHeadlineItem(cmsTextDataState: CmsDataStateModel) {
    return function (catalogueName: CmsCatalogueHeadline): HeadlineItem | undefined {
      return cmsTextDataState.headlines.find(headline => headline.catalogue === catalogueName);
    };
  }
}
