import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

/**
 * ADD NEW ICONS HERE.
 * "check.svg" will be added as "check"
 * "ico-up.svg" will be added as "ico-up"
 */
const icons = [
  "avatar",
  "back",
  "check",
  "check_filled",
  "info_circle",
  "info_circle_blue",
  "info_circle_small",
  "teams",
  "team-profiles",
  "approvals",
  "profile",
  "catalogue",
  "help",
  "feedback",
  "up",
  "down",
  "edit",
  "delete",
  "news",
  "arrow-right",
  "all-team-icon",
  "peer-card-icon-large",
  "close-dialog",
  "graduation-cap",
  "warning",
];

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class IconModule {
  constructor(public matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/${icon}.svg`),
      );
    });
  }
}
