import { PageCollection } from "@shared/models/contentful.types";
import { SearchResultTableText } from "../cms-text-data.model";
import { filterNull } from "@shared/utils/filter-null.utils";
import { cmsPagesIds } from "../../utils/cms-pages-ids.utils";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export function parseSearchResultTablePageTextUtils(
  pageCollection?: PageCollection | null,
): SearchResultTableText {
  const searchResultTablePage = pageCollection?.items
    .filter(filterNull)
    .find(page => page.sys.id === cmsPagesIds.searchResultTable);

  return {
    iconUrl: searchResultTablePage?.fallbackIllustration?.url || "",
    domainKnowledgePopupText: searchResultTablePage?.headingSection1 || "",
    matchPopupText: searchResultTablePage?.headingSection2 || "",
    noSearchResultText:
      documentToHtmlString(searchResultTablePage?.emptySearchResultText?.json) || "",
  };
}
