import { Injectable } from "@angular/core";
import { State, Action, StateContext } from "@ngxs/store";
import { map, tap } from "rxjs/operators";
import { filterNullItemsPipe } from "@shared/utils/filter-null.utils";
import { CMSRequestService } from "../services/cms-request.service";
import { GetNews } from "./news.actions";
import { NewsStateModel } from "./news.model";
import { NEWS_QUERY } from "./news.query";
import { setNewsState } from "./utils/set-news-state.utils";

@State<NewsStateModel>({
  name: "news",
  defaults: [],
})
@Injectable()
export class NewsState {
  constructor(private cmsService: CMSRequestService) {}

  @Action(GetNews)
  getNews(context: StateContext<NewsStateModel>) {
    return this.cmsService.cmsQuery(NEWS_QUERY()).pipe(
      map(({ data }) => data?.newsCardCollection?.items || []),
      filterNullItemsPipe,
      tap(setNewsState(context)),
    );
  }
}
