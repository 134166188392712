import { LearningPathItem, LearningPathSection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { formatPathDescription } from "./format-path-description.utils";
import {
  LearningPathSectionReference,
  LearningPathSectionStoreItem,
} from "../models/learning-paths.model";

export function parseLearningPathSections(
  cmsSections: LearningPathSection[],
): LearningPathSectionStoreItem[] {
  return cmsSections.map(s => ({
    id: s.sys.id,
    entityVersion: s.sys.publishedVersion || 0,
    type: "LearningPathSection",
    title: s.title || "",
    description: formatPathDescription(s.description),
    platformId: String(s.platformId || ""),
    order: s.order || 0,
    skillReferences: getSkillRefs(s.skillReferenceCollection),
    stepIds: getItemIds(s.itemsCollection?.items),
    parentLearningPathId: getParentId(s),
  }));
}

function getSkillRefs(
  collection: LearningPathSection["skillReferenceCollection"],
): LearningPathSectionReference[] {
  return (
    collection?.items.filter(filterNull).map(ref => ({
      id: ref.sys.id,
      type: ref.__typename,
      name: ref.name || "",
    })) || []
  );
}

function getItemIds(items?: (LearningPathItem | null)[]): string[] {
  return items?.filter(filterNull).map(item => item.sys.id) || [];
}

function getParentId(s: LearningPathSection): string {
  return s.linkedFrom?.learningPathCollection?.items[0]?.sys.id || "";
}
