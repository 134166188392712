import { StateContext } from "@ngxs/store";
import {
  LearningPathSectionStepStoreItem,
  LearningPathsStateModel,
} from "../models/learning-paths.model";
import { areStoreItemArraysEqual } from "../../utils/is-store-items-array-equal.utils";

export function setLearningPathSectionStepsState({
  getState,
  patchState,
}: StateContext<LearningPathsStateModel>) {
  return function (step: LearningPathSectionStepStoreItem[]): void {
    const prevSteps = getState().steps;
    const shouldNotUpdateState = areStoreItemArraysEqual(step, prevSteps);
    if (shouldNotUpdateState) return;

    patchState({ steps: [...prevSteps, ...step] });
  };
}
