import { gql } from "apollo-angular";

export function CERTIFICATES_QUERY(limit: number, skip: number) {
  return gql`
    query {
      certificateCollection(limit: ${limit}, skip: ${skip}) {
        total
        items {
          sys { id, publishedVersion }
          name
          
          linkedFrom {
            # It is assumed that each certificate can have ONLY ONE issuer
            certificateIssuerCollection(limit: 1) {
              items {
                sys { id }
                nameOfIssuer
                logoOfIssuer { url }
              }
            }
          }
        }
      }
    }
  `;
}
