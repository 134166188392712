export type TeamMembersStateModel = TeamMember[];

export interface TeamMember {
  id: string;
  display_name: string;
  job_title: string;
  account_enabled: boolean;
  office_location: string;
  mail: string;
  pending_changes: TeamMemberPendingChange[];
}

export type TeamMemberPendingChange =
  | AoEPendingChange
  | SpecPendingChange
  | SkillPendingChange
  | PrimaryDomainPendingChange
  | SecondaryDomainPendingChange
  | PrimaryPowerSkillPendingChange
  | SecondaryPowerSkillPendingChange
  | CertificationPendingChange;

export interface AoEPendingChange extends CataloguePendingChange {
  entity_type: "areas_of_expertise";
}

export function isAoEPendingChange(change: TeamMemberPendingChange): change is AoEPendingChange {
  return change.entity_type === "areas_of_expertise";
}

export interface SpecPendingChange extends CataloguePendingChange {
  entity_type: "specialisation";
  parent_id: string;
  parent_change_request: AoEPendingChange;
}

export function isSpecPendingChange(change: TeamMemberPendingChange): change is SpecPendingChange {
  return change.entity_type === "specialisation";
}

export interface SkillPendingChange extends CataloguePendingChange {
  entity_type: "competence";
  parent_id: string;
  parent_change_request: SpecPendingChange;
}

export function isSkillPendingChange(
  change: TeamMemberPendingChange,
): change is SkillPendingChange {
  return change.entity_type === "competence";
}

export interface PrimaryDomainPendingChange extends BasePendingChange {
  entity_type: "primary_domain";
}

export function isPrimaryDomainPendingChange(
  change: TeamMemberPendingChange,
): change is PrimaryDomainPendingChange {
  return change.entity_type === "primary_domain";
}

export interface SecondaryDomainPendingChange extends BasePendingChange {
  entity_type: "secondary_domain";
  parent_id: string;
  parent_change_request: PrimaryDomainPendingChange;
}

export function isSecondaryDomainPendingChange(
  change: TeamMemberPendingChange,
): change is SecondaryDomainPendingChange {
  return change.entity_type === "secondary_domain";
}

export interface PrimaryPowerSkillPendingChange extends BasePendingChange {
  entity_type: "primary_power_skill";
}

export function isPrimaryPowerSkillPendingChange(
  change: TeamMemberPendingChange,
): change is PrimaryPowerSkillPendingChange {
  return change.entity_type === "primary_power_skill";
}

export interface SecondaryPowerSkillPendingChange extends BasePendingChange {
  entity_type: "secondary_power_skill";
  parent_id: string;
  parent_change_request: PrimaryPowerSkillPendingChange;
}

export function isSecondaryPowerSkillPendingChange(
  change: TeamMemberPendingChange,
): change is SecondaryPowerSkillPendingChange {
  return change.entity_type === "secondary_power_skill";
}

export interface CertificationPendingChange extends BasePendingChange {
  entity_type: "certification";
}

export function isCertificationPendingChange(
  change: TeamMemberPendingChange,
): change is CertificationPendingChange {
  return change.entity_type === "certification";
}

export interface CataloguePendingChange extends BasePendingChange {
  action: TeamMemberPendingChangeAction;
  old_value: TeamMemberPendingChangeLevel;
  new_value: TeamMemberPendingChangeLevel;
}

export interface BasePendingChange {
  entity_id: string;
  parent_id?: string;
  entity_type: TeamMemberPendingChangeEntityType;
  status: TeamMemberPendingChangeStatus;
  change_id: string;
  user_id: string;
  timestamp: string;
  comment?: string;
  approved_declined_by: string;
  approval_decline_date: string;
}

export type TeamMemberPendingChangeAction = "new" | "change";
export type TeamMemberPendingChangeStatus = "pending" | "approved" | "declined" | "deleted";
export type TeamMemberPendingChangeLevel = 0 | 1 | 2 | 3 | 4;

export type TeamMemberPendingChangeEntityType =
  | "overall"
  | "areas_of_expertise"
  | "specialisation"
  | "competence"
  | "primary_domain"
  | "secondary_domain"
  | "primary_power_skill"
  | "secondary_power_skill"
  | "certification";

export interface UpdateTeamMembersPendingRequestBody {
  status: string;
  comment?: string;
  entity_version: number;
}

export interface UpdateTeamMembersPendingRequestResponse {
  action: TeamMemberPendingChangeAction;
  old_value: TeamMemberPendingChangeLevel;
  new_value: TeamMemberPendingChangeLevel;
  change_id: string;
  user_id: string;
  parent_id: string;
  timestamp: string;
  status: TeamMemberPendingChangeStatus;
  comment?: string;
  entity_type: TeamMemberPendingChangeEntityType;
  entity_id: string;
  approved_declined_by: string;
  approval_decline_date: string;
}

export type UpdateMultiplePendingRequestBody = {
  change_id: string;
  status: TeamMemberPendingChangeStatus;
  comment?: string;
  entity_version: number;
}[];

export type UpdateMultiplePendingRequestResponse = {
  change_id: string;
  status: TeamMemberPendingChangeStatus;
  http_status_code: 200 | 400 | 403 | 404 | 409 | 424;
  description: string;
}[];
