import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { CatalogueItemLevel } from "src/app/store/catalogue/catalogue.model";

@Injectable({ providedIn: "root" }) // To allow usage in services
@Pipe({ name: "catalogueItemLevel" })
export class CatalogueStoreItemLevelPipe implements PipeTransform {
  private get levelMap(): Record<number, CatalogueItemLevel> {
    return {
      0: "junior",
      1: "regular",
      2: "senior",
      3: "principal",
      4: "seniorPrincipal",
    };
  }

  private formatLevelKey(key: string): string {
    return key.match(/[A-Z]?[a-z]+/g)?.join(" ") || "";
  }

  transform(level?: number | null, newLevel?: number): string {
    if (level === undefined || level === null) return "";

    const levelKey = this.levelMap[level];
    if (newLevel === undefined || newLevel === level) {
      return this.formatLevelKey(levelKey);
    }

    const newLevelKey = this.levelMap[newLevel];
    return `${this.formatLevelKey(levelKey)} > ${this.formatLevelKey(newLevelKey)}`;
  }
}
