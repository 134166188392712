import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import {
  AddAreasOfExpertise,
  AddCertification,
  AddLocation,
  AddPrimaryDomain,
  AddSecondaryDomain,
  AddSkill,
  AddSpecialisation,
  DeleteAreasOfExpertise,
  DeleteCertification,
  DeleteLocation,
  DeletePrimaryDomain,
  DeleteSecondaryDomain,
  DeleteSkill,
  DeleteSpecialisation,
  ResetDisabledSearchCriteria,
  ResetProfileSearchData,
  SubmitProfileSearchData,
  ToggleCriteria,
  UpdateAvailability,
  UpdateDate,
} from "./profile-search-form.actions";
import {
  ProfileSearchFormRequestBody,
  ProfileSearchFormResult,
  ProfileSearchFormStateModel,
} from "./profile-search-form.model";
import { append, patch, removeItem } from "@ngxs/store/operators";
import { submitProfileSearchDataRequestBodyUtils } from "./utils/submit-profile-search-data-request-body.utils";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@State<ProfileSearchFormStateModel>({
  name: "profileSearchForm",
  defaults: {
    availability_percentage: 100,
    start_date: new Date().toISOString(),
    disabled_criteria: [],
  },
})
@Injectable()
export class ProfileSearchFormState {
  constructor(private http: HttpClient) {}

  /* ACTIONS */
  @Action(UpdateAvailability)
  updateAvailability(
    { setState }: StateContext<ProfileSearchFormStateModel>,
    { availability }: UpdateAvailability,
  ): void {
    setState(patch({ availability_percentage: availability }));
  }

  @Action(UpdateDate)
  updateDate({ setState }: StateContext<ProfileSearchFormStateModel>, { date }: UpdateDate): void {
    setState(patch({ start_date: date }));
  }

  @Action(AddLocation)
  addLocation(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { location }: AddLocation,
  ): void {
    const isLocationArray = getState().locations;

    if (!isLocationArray) {
      setState(patch({ locations: [location] }));
    } else {
      setState(patch({ locations: append<string>([location]) }));
    }
  }

  @Action(DeleteLocation)
  deleteLocation(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { location }: DeleteLocation,
  ): void {
    setState(patch({ locations: removeItem(f => f === location) }));

    const isLocationArray = getState().locations;

    if (isLocationArray && isLocationArray.length === 0) {
      setState(patch({ locations: undefined }));
    }
  }

  @Action(AddAreasOfExpertise)
  addAreasOfExpertise(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { aoe }: AddAreasOfExpertise,
  ): void {
    const aoes = getState().areas_of_expertise || [];

    setState(patch({ areas_of_expertise: [...aoes, aoe] }));
  }

  @Action(DeleteAreasOfExpertise)
  deleteAreasOfExpertise(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { aoe }: DeleteAreasOfExpertise,
  ): void {
    setState(patch({ areas_of_expertise: removeItem(f => f.item.id === aoe.id) }));

    const stateArray = getState().areas_of_expertise;

    if (stateArray && stateArray.length === 0) {
      setState(patch({ areas_of_expertise: undefined }));
    }
  }

  @Action(AddSpecialisation)
  addSpecialisation(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { spec }: AddSpecialisation,
  ): void {
    const specs = getState().specialisations || [];

    setState(patch({ specialisations: [...specs, spec] }));
  }

  @Action(DeleteSpecialisation)
  deleteSpecialisation(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { spec }: DeleteSpecialisation,
  ): void {
    setState(patch({ specialisations: removeItem(f => f.item.id === spec.id) }));

    const stateArray = getState().specialisations;

    if (stateArray && stateArray.length === 0) {
      setState(patch({ specialisations: undefined }));
    }
  }

  @Action(AddSkill)
  addSkill(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { skill }: AddSkill,
  ): void {
    const skills = getState().skills || [];

    setState(patch({ skills: [...skills, skill] }));
  }

  @Action(DeleteSkill)
  deleteSkill(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { skill }: DeleteSkill,
  ): void {
    setState(patch({ skills: removeItem(f => f.item.id === skill.id) }));

    const stateArray = getState().skills;

    if (stateArray && stateArray.length === 0) {
      setState(patch({ skills: undefined }));
    }
  }

  @Action(AddPrimaryDomain)
  addPrimaryDomain(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { primaryDomain }: AddPrimaryDomain,
  ): void {
    const primaryDomains = getState().primary_domains || [];

    setState(patch({ primary_domains: [...primaryDomains, primaryDomain] }));
  }

  @Action(DeletePrimaryDomain)
  deletePrimaryDomain(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { primaryDomain }: DeletePrimaryDomain,
  ): void {
    setState(patch({ primary_domains: removeItem(f => f.item.id === primaryDomain.id) }));

    const stateArray = getState().primary_domains;

    if (stateArray && stateArray.length === 0) {
      setState(patch({ primary_domains: undefined }));
    }
  }

  @Action(AddSecondaryDomain)
  addSecondaryDomain(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { secondaryDomain }: AddSecondaryDomain,
  ): void {
    const secondaryDomains = getState().secondary_domains || [];

    setState(patch({ secondary_domains: [...secondaryDomains, secondaryDomain] }));
  }

  @Action(DeleteSecondaryDomain)
  deleteSecondaryDomain(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { secondaryDomain }: DeleteSecondaryDomain,
  ): void {
    setState(patch({ secondary_domains: removeItem(f => f.item.id === secondaryDomain.id) }));

    const stateArray = getState().secondary_domains;

    if (stateArray && stateArray.length === 0) {
      setState(patch({ secondary_domains: undefined }));
    }
  }

  @Action(AddCertification)
  addCertification(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { certification }: AddCertification,
  ): void {
    const certifications = getState().certifications || [];

    setState(patch({ certifications: [...certifications, certification] }));
  }

  @Action(DeleteCertification)
  deleteCertification(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { certification }: DeleteCertification,
  ): void {
    const stateArray = getState().certifications?.filter(item => item.item.id !== certification.id);

    setState(
      patch({ certifications: stateArray && stateArray.length > 0 ? stateArray : undefined }),
    );
  }

  @Action(SubmitProfileSearchData)
  submitProfileSearchData({
    getState,
    patchState,
  }: StateContext<ProfileSearchFormStateModel>): Observable<ProfileSearchFormResult[]> {
    const state = { ...getState() };
    const requestBody: ProfileSearchFormRequestBody =
      submitProfileSearchDataRequestBodyUtils(state);

    return this.http
      .post<ProfileSearchFormResult[]>(
        `${environment.AZ_URL}/search/profiles-by-profile-data`,
        requestBody,
      )
      .pipe(
        tap(data => {
          patchState({ results: data });
        }),
      );
  }

  @Action(ResetProfileSearchData)
  resetProfileSearchData({ setState }: StateContext<ProfileSearchFormStateModel>): void {
    setState({
      availability_percentage: 100,
      start_date: new Date().toISOString(),
      disabled_criteria: [],
    });
  }

  @Action(ToggleCriteria)
  toggleCriteria(
    { getState, setState }: StateContext<ProfileSearchFormStateModel>,
    { id }: ToggleCriteria,
  ): void {
    const ids = getState().disabled_criteria || [];

    const isAlreadyInState = ids.findIndex(item => item === id) >= 0;

    setState(
      isAlreadyInState
        ? patch({ disabled_criteria: removeItem(item => item === id) })
        : patch({ disabled_criteria: [...ids, id] }),
    );
  }

  @Action(ResetDisabledSearchCriteria)
  DeleteDisabledSearchCriteria({ setState }: StateContext<ProfileSearchFormStateModel>): void {
    setState(patch({ disabled_criteria: [] }));
  }

  /* SELECTORS */

  @Selector()
  static selectProfileSearchFormItems(state: ProfileSearchFormStateModel) {
    return state;
  }

  @Selector()
  static selectProfileSearchFormResults(
    state: ProfileSearchFormStateModel,
  ): ProfileSearchFormResult[] | undefined {
    return state.results;
  }

  @Selector()
  static selectDisabledSearchCriteria(state: ProfileSearchFormStateModel): string[] | [] {
    return state.disabled_criteria;
  }
}
