<div>
  <p>This page is for direct admin-panel access only.</p>
  <p>Availability is limited to local/dev stage.</p>

  <br />
  <p><b>Environment switch:</b></p>
  <p>Please open this page in incognito mode to ensure this process works.</p>
  <p><i>Environment: intive</i> will allow you to use intive accounts.</p>
  <p><i>Environment: test</i> will allow you to use test accounts.</p>
  <p>A popup might open with a logout prompt. If the popup prompts you to approve, please do so.</p>
  <p>
    After that, use the SAME tab to navigate to the start page of
    <a href="https://dev.skills.intive.com/">SkillMatrix (DEV)</a>. You'll need to log in into an
    account for the previously picked environment.
  </p>
  <br />
  <p>In case something breaks, just open a new tab and start again</p>
</div>
