import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  constructor(private httpClient: HttpClient) {}

  getContentfulKey(): Observable<any> {
    return this.httpClient.get(`${environment.AZ_URL}/auth/contentful`);
  }

  /**
   * Azure API Key
   * @returns Azure API Key
   */
  getCurrentIdToken(): string {
    return localStorage.getItem("idToken") || "";
  }

  /**
   * Azure API Key
   * @param newValue Azure API Key
   */
  setCurrentIdToken(newValue: string): void {
    localStorage.setItem("idToken", newValue);
  }

  /**
   * Contentful API Key
   * @returns Contentful API Key
   */
  getCurrentKey(): string {
    return localStorage.getItem("apiKey") || "";
  }

  /**
   * Contentful API Key
   * @param newValue Contentful API Key
   */
  setCurrentKey(newValue: string): void {
    localStorage.setItem("apiKey", newValue);
  }
}
