import { StateContext } from "@ngxs/store";
import { updateItem } from "@ngxs/store/operators";
import {
  TeamMembersStateModel,
  UpdateTeamMembersPendingRequestResponse,
} from "../models/team-members.model";
import { updatePendingChanges } from "./update-pending-changes.utils";
import { findTeamMember } from "./find-team-member.utils";

export function updateSinglePendingRequestState({ setState }: StateContext<TeamMembersStateModel>) {
  return function (response: UpdateTeamMembersPendingRequestResponse): void {
    setState(
      updateItem(findTeamMember(response), teamMember => ({
        ...teamMember,
        pending_changes: updatePendingChanges(teamMember.pending_changes, response),
      })),
    );
  };
}
