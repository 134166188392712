import { ProfileEntry, ProfileModel } from "../../profile/profile.model";
import { CertificateStoreItem } from "../models/certificates.model";
import {
  ProfilePrimaryCertificateTree,
  ProfileSecondaryCertificateTree,
} from "../models/profile-certificate-tree.model";
import { CatalogueStoreItemStatus } from "../../catalogue/catalogue.model";
import { reduceCertificateIssuers } from "./reduce-certificate-issuers.utils";

export function buildProfileCertificateTrees(
  profile: ProfileModel,
  certificationsItems: CertificateStoreItem[],
): ProfilePrimaryCertificateTree[] {
  const profileCertificationIds = profile.certifications.map(p => p.entry_id);
  const profileCertifications = certificationsItems.filter(({ id }) =>
    profileCertificationIds.includes(id),
  );
  const profileIssuers = reduceCertificateIssuers(profileCertifications);

  return profileIssuers.map(issuer => ({
    primaryItem: issuer,
    primaryStatus: null,
    secondaryTrees: buildProfileSecondaryCertificateTrees(
      issuer.id,
      profile.certifications,
      profileCertifications,
    ),
  }));
}

function buildProfileSecondaryCertificateTrees(
  issuerId: string,
  profileEntries: ProfileModel["certifications"],
  certifications: CertificateStoreItem[],
): ProfileSecondaryCertificateTree[] {
  return certifications
    .filter(({ issuer }) => issuer.id === issuerId)
    .map(certification => ({
      secondaryItem: certification,
      secondaryStatus: getCertificationStatus(certification, profileEntries),
    }));
}

function getCertificationStatus(
  certification: CertificateStoreItem,
  profileEntries: ProfileModel["certifications"],
): Extract<CatalogueStoreItemStatus, "pending" | "approved"> {
  const profileItem = profileEntries.find(p => p.entry_id === certification.id);

  return getStatus(profileItem);
}

function getStatus(
  entry?: ProfileEntry,
): Extract<CatalogueStoreItemStatus, "pending" | "approved"> {
  return entry?.pending ? "pending" : "approved";
}
