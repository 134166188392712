export function multipleWordSearchTermMatches(name: string, searchTerm: string): string[] {
  const searchTermMatches = searchTerm
    .trim()
    .split(" ")
    .reduce((matches, word) => {
      const regex = new RegExp(word, "gi");
      const wordMatches = name.match(regex) || [];

      return [...matches, ...wordMatches];
    }, [] as string[]);

  // Remove duplicate matches
  return [...new Set(searchTermMatches)];
}
