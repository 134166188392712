import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "../features/button/button.module";
import { AvatarComponent } from "./components/avatar/avatar.component";
import { CatalogueItemDialogHeaderComponent } from "./components/catalogue-item-dialog-header/catalogue-item-dialog-header.component";
import { EnvComponent } from "./components/env/env.component";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import { NotifyComponent } from "./components/notify/notify.component";
import { ResourceCardComponent } from "./components/resource-card/resource-card.component";
import { SmHeaderComponent } from "./components/sm-header/sm-header.component";
import { SmNavigationComponent } from "./components/sm-navigation/sm-navigation.component";
import { StatusBadgeComponent } from "./components/status-badge/status-badge.component";
import { TextBadgeComponent } from "./components/text-badge/text-badge.component";
import { VideoModalComponent } from "./components/video-modal/video-modal.component";
import { CatalogueStoreItemLevelDescriptionPipe } from "./pipes/catalogue-item-level-description.pipe";
import { CatalogueStoreItemLevelPipe } from "./pipes/catalogue-item-level.pipe";
import { SkillStoreItemLevelPipe } from "./pipes/skill-item-level.pipe";
import { SortCMSItemsPipe } from "./pipes/sort-csm-items.pipe";
import { SortProfileTreesPipe } from "./pipes/sort-profile-trees.pipe";
import { HighlightSearchTermMatchesPipe } from "./pipes/highlight-search-term-matches.pipe";
import { ClickOutsideDirective } from "./directives/click-outside.directive";

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    SmHeaderComponent,
    SmNavigationComponent,
    NotifyComponent,
    EnvComponent,
    ResourceCardComponent,
    VideoModalComponent,
    SortCMSItemsPipe,
    AvatarComponent,
    StatusBadgeComponent,
    TextBadgeComponent,
    CatalogueStoreItemLevelPipe,
    CatalogueStoreItemLevelDescriptionPipe,
    SkillStoreItemLevelPipe,
    SortProfileTreesPipe,
    CatalogueItemDialogHeaderComponent,
    HighlightSearchTermMatchesPipe,
    ClickOutsideDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    FormsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    ButtonModule,
  ],
  exports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatProgressBarModule,
    MatDialogModule,
    MatRadioModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSnackBarModule,
    LoadingSpinnerComponent,
    SmHeaderComponent,
    SmNavigationComponent,
    NotifyComponent,
    EnvComponent,
    ResourceCardComponent,
    VideoModalComponent,
    SortCMSItemsPipe,
    AvatarComponent,
    StatusBadgeComponent,
    TextBadgeComponent,
    CatalogueStoreItemLevelPipe,
    CatalogueStoreItemLevelDescriptionPipe,
    SkillStoreItemLevelPipe,
    SortProfileTreesPipe,
    CatalogueItemDialogHeaderComponent,
    ButtonModule,
    HighlightSearchTermMatchesPipe,
    ClickOutsideDirective,
  ],
})
export class SharedModule {}
