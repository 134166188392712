<div class="info-box" [class.has-border]="hasBorder" [class.has-button]="infoBox.closable">
  <div class="info-box__text">
    <img *ngIf="infoBox.iconUrl" class="info-box__text__icon" [src]="infoBox.iconUrl" alt="" />
    <span class="info-box__text__heading">{{ infoBox.heading }}</span
    >&nbsp;|&nbsp;
    <span class="info-box__text__description">{{ infoBox.description }}</span>
    <a class="info-box__text__link" [href]="infoBox.linkUrl" target="_blank">{{
      infoBox.linkText
    }}</a>
  </div>
  <div class="info-box__action" *ngIf="infoBox.closable">
    <sm-button buttonStyle="icon" (onClick)="onClose()">
      <img src="/assets/images/close.svg" alt="" />
    </sm-button>
  </div>
</div>
