<div class="sidebar" *ngIf="sidebarText$ | async as sidebarText">
  <a href="/" class="sidebar-logo">
    <img class="sidebar-logo__image" src="./assets/images/logo_intive_blue.svg" />
    <span class="sidebar-logo__subtitle">Skill Matrix {{ sidebarText.version }}</span>
  </a>

  <div class="sidebar-avatar">
    <sm-avatar class="sidebar-avatar__image" [src]="myAvatar$ | async" [size]="100"></sm-avatar>
    <h2 class="sidebar-avatar__name">{{ msalProfileName$ | async }}</h2>
    <p *ngIf="myLocation$ | async as myLocation" class="sidebar-avatar__location">
      ({{ myLocation }})
    </p>
    <sm-notifications-toggle
      [toggleNotifications]="emailNotificationStatus$ | async"
    ></sm-notifications-toggle>
  </div>

  <div class="sidebar-link">
    <ng-container *ngFor="let item of sidebarItems$ | async">
      <sm-sidebar-item
        *ngIf="item.path !== '/news'"
        [item]="item"
        i18n="Navbar Skill Matrix|Skill Matrix navbar.@@skillMatrix"
      ></sm-sidebar-item>
      <sm-news-sidebar-item
        *ngIf="item.path === '/news'"
        [item]="item"
        i18n="Navbar Skill Matrix|Skill Matrix navbar.@@skillMatrix"
      ></sm-news-sidebar-item>
    </ng-container>
  </div>

  <div class="sidebar-footer">
    <a
      class="sidebar-footer__feedback-btn"
      i18n="Navbar Feedback|Feedback navbar.@@feedback"
      target="_blank"
      href="https://teams.microsoft.com/l/channel/19%3a2b2152a48bb64d90888ce23718c278cb%40thread.tacv2/Skill%2520Matrix%2520Feedback?groupId=bc50290b-fe99-4ca3-8096-85aa1ea2e7a2&tenantId=00a83132-4221-4698-a787-6d679d557a90"
    >
      <sm-button>
        <img [src]="sidebarText.buttonIconUrl" />
        {{ sidebarText.buttonText }}
      </sm-button>
    </a>
  </div>
</div>
