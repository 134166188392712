import { DomainPendingRequestTree } from "../models/pending-request-tree.model";
import {
  PrimaryDomainPendingChange,
  SecondaryDomainPendingChange,
  TeamMember,
  isPrimaryDomainPendingChange,
  isSecondaryDomainPendingChange,
} from "../models/team-members.model";

export function buildDomainPendingRequestTrees({
  pending_changes,
}: TeamMember): DomainPendingRequestTree[] {
  const trees: DomainPendingRequestTree[] = [];

  pending_changes.forEach(pendingReq => {
    if (isPrimaryDomainPendingChange(pendingReq)) {
      const primaryReq = pendingReq;
      const primaryTree = getDomainTree(trees, primaryReq.entity_id);

      if (!primaryTree) newDomainTree(trees, primaryReq);
    } else if (isSecondaryDomainPendingChange(pendingReq)) {
      const primaryReq = pendingReq.parent_change_request;
      const secondaryReq = pendingReq;
      const primaryTree = getDomainTree(trees, primaryReq.entity_id);

      if (!primaryTree) {
        newDomainTree(trees, primaryReq, secondaryReq);
      } else if (
        isPendingChangeNotInTree(primaryTree.secondaryDomainPendingRequests, secondaryReq)
      ) {
        primaryTree.secondaryDomainPendingRequests.push(secondaryReq);
      }
    }
  });

  return trees;
}

function getDomainTree(
  trees: DomainPendingRequestTree[],
  primaryEntityId: string,
): DomainPendingRequestTree | undefined {
  return trees.find(
    ({ primaryDomainPendingRequest }) => primaryDomainPendingRequest.entity_id === primaryEntityId,
  );
}

function newDomainTree(
  trees: DomainPendingRequestTree[],
  primaryReq: PrimaryDomainPendingChange,
  secondaryReq?: SecondaryDomainPendingChange,
): void {
  trees.push({
    primaryDomainPendingRequest: primaryReq,
    secondaryDomainPendingRequests: secondaryReq ? [secondaryReq] : [],
  });
}

function isPendingChangeNotInTree(
  reqs: SecondaryDomainPendingChange[],
  req: SecondaryDomainPendingChange,
): boolean {
  return reqs.findIndex(({ change_id }) => change_id === req.change_id) < 0;
}
