import { gql } from "apollo-angular";
import { NOTIFICATIONS_QUERY } from "./queries/notifications.query";
import { POPUPS_QUERY } from "./queries/popups.query";
import { SIDEBAR_QUERY } from "./queries/sidebar.query";
import { PAGES_QUERY } from "./queries/pages.query";
import { TWO_LEVEL_PANEL_QUERY } from "./queries/two-level-panel.query";
import { INFO_BOXES_QUERY } from "./queries/info-boxes.query";
import { HEADLINES_QUERY } from "./queries/headlines.query";

export const CMS_TEXT_DATA_QUERY = () => gql`
  query{
    ${SIDEBAR_QUERY(10)}
    ${NOTIFICATIONS_QUERY(5)}
    ${POPUPS_QUERY(10)}
    ${PAGES_QUERY(10)}
    ${TWO_LEVEL_PANEL_QUERY(5)}
    ${INFO_BOXES_QUERY(20)}
    ${HEADLINES_QUERY(10)}
  }
`;
