import { Component, Input } from "@angular/core";

@Component({
  selector: "sm-catalogue-item-dialog-header",
  templateUrl: "catalogue-item-dialog-header.component.html",
  styleUrls: ["./catalogue-item-dialog-header.component.scss"],
})
export class CatalogueItemDialogHeaderComponent {
  @Input() title!: string;
  @Input() iconUrl?: string;
  @Input() upperTitle?: string;
  @Input() subTitle?: string;
}
