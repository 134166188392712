import { gql } from "apollo-angular";

export const NEWS_QUERY = () => gql`
  query {
    newsCardCollection(order: publishDate_DESC) {
      items {
        headline
        buttonText
        buttonLink
        publishDate
        image {
          url
        }
        description {
          json
        }
      }
    }
  }
`;
