import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { TwoLevelPanelCollection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { TwoLevelPanelText } from "../cms-text-data.model";

export function parseTwoLevelPanelItems(
  twoLevelPanelCollection?: TwoLevelPanelCollection | null,
): TwoLevelPanelText[] {
  if (!twoLevelPanelCollection) return [];

  return twoLevelPanelCollection.items.filter(filterNull).map(item => ({
    name: item.name || "",
    title: item.title || "",
    descriptionTitle: item.descriptionTitle || "",
    descriptionText: item.descriptionText || "",
    infoButtonText: item.infoButtonText || "",
    infoButtonLink: item.infoButtonLink || "",
    actionButtonText: item.actionButtonText || "",
    actionButtonLink: item.actionButtonLink || "",
    noDataText: documentToHtmlString(item.noDataText?.json),
    iconUrl: item.illustration?.url || "",
    subtitle: item.subtitle || "",
    firstLevelTitle: item.firstLevelTitle || "",
    secondLevelTitle: item.secondLevelTitle || "",
  }));
}
