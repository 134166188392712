import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CmsTextDataState } from "../cms-text-data.state";
import {
  ApprovalsPageText,
  CmsDataStateModel,
  HeadlineItem,
  InfoBoxText,
  LearningPathPageText,
  NotificationTextItem,
  PageNotFoundPageText,
  PopupTextItem,
  ProfilePageText,
  ProfileSearchPageText,
  SearchResultTableText,
  SidebarText,
  SidebarTextItem,
  TwoLevelPanelText,
} from "../cms-text-data.model";
import { CmsTextPopupName } from "../models/cms-text-popup-name.model";
import { CmsTextTwoLevelPanelName } from "../models/cms-text-two-level-panel-name.model";
import { CmsCatalogueHeadline } from "../models/cms-catalogue-headline.model";
import { GetCMSTextData } from "../cms-text-data.actions";

@Injectable({ providedIn: "root" })
export class CmsTextDataService {
  @Select(CmsTextDataState)
  private cmsTextData$!: Observable<CmsDataStateModel>;

  @Select(CmsTextDataState.selectPopupTextItem)
  private selectPopupTextItem$!: Observable<
    ReturnType<typeof CmsTextDataState.selectPopupTextItem>
  >;

  @Select(CmsTextDataState.selectHeadlineItem)
  private selectHeadlineItem$!: Observable<ReturnType<typeof CmsTextDataState.selectHeadlineItem>>;

  @Select(CmsTextDataState.selectTwoLevelPanelTextItem)
  private selectTwoLevelPanelTextItem$!: Observable<
    ReturnType<typeof CmsTextDataState.selectTwoLevelPanelTextItem>
  >;

  constructor(private store: Store) {}

  fetchCmsTextData() {
    return this.store.dispatch(new GetCMSTextData());
  }

  selectProfilePageText(): Observable<ProfilePageText> {
    return this.cmsTextData$.pipe(map(data => data.profilePage));
  }

  selectProfileSearchPageText(): Observable<ProfileSearchPageText> {
    return this.cmsTextData$.pipe(map(data => data.profileSearchPage));
  }

  selectSearchResultTablePageText(): Observable<SearchResultTableText> {
    return this.cmsTextData$.pipe(map(data => data.searchResultTable));
  }

  selectSidebarTextItems(): Observable<SidebarTextItem[]> {
    return this.cmsTextData$.pipe(map(data => data.sidebarItems));
  }

  selectSidebarText(): Observable<SidebarText> {
    return this.cmsTextData$.pipe(map(data => data.sidebar));
  }

  selectApprovalsPageText(): Observable<ApprovalsPageText> {
    return this.cmsTextData$.pipe(map(data => data.approvalsPage));
  }

  selectPageNotFoundText(): Observable<PageNotFoundPageText> {
    return this.cmsTextData$.pipe(map(data => data.pageNotFoundPage));
  }

  selectLearningPathPageText(): Observable<LearningPathPageText> {
    return this.cmsTextData$.pipe(map(data => data.learningPath));
  }

  selectPopupsTextItem(popupName: CmsTextPopupName): Observable<PopupTextItem | undefined> {
    return this.selectPopupTextItem$.pipe(
      map(selectPopupTextItem => selectPopupTextItem(popupName)),
    );
  }

  selectTwoLevelPanelTextItem(
    twoLevelPanelName: CmsTextTwoLevelPanelName,
  ): Observable<TwoLevelPanelText | undefined> {
    return this.selectTwoLevelPanelTextItem$.pipe(
      map(selectTwoLevelPanelTextItem => selectTwoLevelPanelTextItem(twoLevelPanelName)),
    );
  }

  selectNotificationTextItems(): Observable<NotificationTextItem[]> {
    return this.cmsTextData$.pipe(map(data => data.notifications));
  }

  selectInfoBoxTextItems(): Observable<InfoBoxText[]> {
    return this.cmsTextData$.pipe(map(data => data.infoBoxes));
  }

  selectHeadlineItem(catalogueName: CmsCatalogueHeadline): Observable<HeadlineItem | undefined> {
    return this.selectHeadlineItem$.pipe(
      map(selectHeadlineItem => selectHeadlineItem(catalogueName)),
    );
  }
}
