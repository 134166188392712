import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sortCMSItems" })
export class SortCMSItemsPipe implements PipeTransform {
  transform<T extends { name?: string | null }>(items: T[] | null): T[] {
    if (!items) return [];

    /**
     * The pipe might receive immutable data,
     * a copy is created to avoid errors
     */
    const sortedItems = [...items];

    // Sort alphabetically by name
    return sortedItems.sort((itemA, itemB) => (itemA.name ?? "").localeCompare(itemB.name ?? ""));
  }
}
