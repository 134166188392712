import { StateContext } from "@ngxs/store";
import { areStoreItemArraysEqual } from "../../utils/is-store-items-array-equal.utils";
import { PowerSkillsStateModel, PrimaryPowerSkillItem } from "../models/power-skills.model";

export function setPowerSkillsState({ getState, setState }: StateContext<PowerSkillsStateModel>) {
  return function (primaryPowerSkills: PrimaryPowerSkillItem[]): void {
    const prevPrimaryPowerSkills = getState();
    const shouldNotUpdateState = areStoreItemArraysEqual(
      primaryPowerSkills,
      prevPrimaryPowerSkills,
    );
    if (shouldNotUpdateState) return;

    setState([...prevPrimaryPowerSkills, ...primaryPowerSkills]);
  };
}
