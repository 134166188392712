import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, mergeMap, takeWhile } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AppService {
  loading = new BehaviorSubject<boolean>(true);

  /**
   * Ensures the passed function is executed after the app loaded.
   */
  afterLoad(callback: () => Observable<any>) {
    return this.loading.pipe(
      takeWhile((isLoading: boolean) => isLoading, true),
      filter(isLoading => !isLoading),
      mergeMap(() => {
        return callback();
      }),
    );
  }
}
