import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "./components/button/button.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule],
  exports: [ButtonComponent],
})
export class ButtonModule {}
