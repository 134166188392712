import { StateContext } from "@ngxs/store";
import { MY_PROFILE_KEY, ProfileStateModel, ProfileEntry, ProfileModel } from "../profile.model";
import { UpdateProfileEntryResponse } from "../models/update-profile-entry.model";
import { TeamMemberPendingChangeEntityType } from "../../team-members/models/team-members.model";

export function updateProfileEntry(context: StateContext<ProfileStateModel>) {
  return function (response: UpdateProfileEntryResponse): ProfileStateModel {
    const state = context.getState();
    const profileEntriesKey = getProfileEntryKey(response.entity_type);
    if (!profileEntriesKey) return state;

    const myProfile = state[MY_PROFILE_KEY];
    const entriesToUpdate = myProfile?.[profileEntriesKey] || [];

    return context.patchState({
      [MY_PROFILE_KEY]: {
        ...myProfile,
        [profileEntriesKey]: updateEntries(entriesToUpdate, response),
      },
    });
  };
}

function updateEntries(
  entries: ProfileEntry[],
  response: UpdateProfileEntryResponse,
): ProfileEntry[] {
  const { entity_id, new_value, old_value, status, parent_id } = response;
  const isNewEntry =
    entries.findIndex(e => e.entry_id === entity_id && e.parent_id === parent_id) < 0;

  const updatedEntry: ProfileEntry = {
    entry_id: entity_id,
    parent_id,
    previous_level: old_value,
    level: new_value,
    pending: status === "pending",
  };

  if (isNewEntry) return [...entries, updatedEntry];

  return entries.map(entry => {
    if (entry.entry_id === entity_id && entry.parent_id === parent_id) {
      return { ...entry, ...updatedEntry };
    }
    return entry;
  });
}

function getProfileEntryKey(
  entityType: TeamMemberPendingChangeEntityType,
): ProfileEntryKey | undefined {
  switch (entityType) {
    case "areas_of_expertise":
      return "areas_of_expertise";

    case "specialisation":
      return "specialisations";

    case "competence":
      return "skills";

    case "primary_domain":
      return "primary_domains";

    case "secondary_domain":
      return "secondary_domains";

    case "certification":
      return "certifications";

    default:
      return;
  }
}

type ProfileEntryKey = keyof Pick<
  ProfileModel,
  | "areas_of_expertise"
  | "specialisations"
  | "skills"
  | "primary_domains"
  | "secondary_domains"
  | "certifications"
>;
