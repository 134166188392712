import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { filterNullItemsPipe } from "@shared/utils/filter-null.utils";
import { LargeCollectionRequestArgs, CMSRequestService } from "../services/cms-request.service";
import { ProfileState } from "../profile/profile.state";
import { DOMAIN_KNOWLEDGE_QUERY } from "./domain-knowledge.query";
import { GetDomainKnowledgeItems } from "./domain-knowledge.actions";
import {
  DomainKnowledgeStateModel,
  PrimaryDomainItem,
  SecondaryDomainItem,
} from "./domain-knowledge.models";
import { ProfilePrimaryDomainTree } from "./models/profile-domain-knowledge-tree.model";
import { reduceSecondaryDomainItems } from "./utils/reduce-secondary-domain-items";
import { buildProfileDomainTrees } from "./utils/build-profile-domain-trees.utils";
import { formatCMSPrimaryDomainItem } from "./utils/format-cms-primary-domain-item.utils";
import { setDomainKnowledgeState } from "./utils/set-domain-knowledge-state.utils";

@State<DomainKnowledgeStateModel>({
  name: "cmsDomainKnowledge",
  defaults: [],
})
@Injectable()
export class DomainKnowledgeState {
  constructor(private cmsService: CMSRequestService) {}

  /** ACTIONS */
  @Action(GetDomainKnowledgeItems)
  getDomainItems(
    context: StateContext<DomainKnowledgeStateModel>,
  ): Observable<PrimaryDomainItem[]> {
    const fetchArgs: LargeCollectionRequestArgs<"primaryDomainCollection"> = {
      collection: "primaryDomainCollection",
      limit: 10,
      itemQuery: DOMAIN_KNOWLEDGE_QUERY,
    };

    return this.cmsService.fetchLargeCollectionItems(fetchArgs).pipe(
      map(({ data }) => data[fetchArgs.collection]?.items || []),
      filterNullItemsPipe,
      map(cmsPrimaryDomainItems => cmsPrimaryDomainItems.map(formatCMSPrimaryDomainItem)),
      tap(setDomainKnowledgeState(context)),
    );
  }

  /** SELECTORS */
  @Selector()
  static selectPrimaryDomainItems(
    primaryDomainItems: DomainKnowledgeStateModel,
  ): PrimaryDomainItem[] {
    return primaryDomainItems;
  }

  @Selector()
  static selectSecondaryDomainItems(
    primaryDomainItems: DomainKnowledgeStateModel,
  ): SecondaryDomainItem[] {
    return reduceSecondaryDomainItems(primaryDomainItems);
  }

  @Selector()
  static selectPrimaryDomainItem(primaryDomainItems: DomainKnowledgeStateModel) {
    return function (primaryDomainId: string): PrimaryDomainItem | undefined {
      return primaryDomainItems.find(({ id }) => primaryDomainId === id);
    };
  }

  @Selector([DomainKnowledgeState.selectSecondaryDomainItems])
  static selectSecondaryDomainItem(
    _: DomainKnowledgeStateModel,
    secondaryDomainItems: ReturnType<typeof DomainKnowledgeState.selectSecondaryDomainItems>,
  ) {
    return function (secondaryDomainId: string): SecondaryDomainItem | undefined {
      return secondaryDomainItems.find(({ id }) => secondaryDomainId === id);
    };
  }

  @Selector([ProfileState.selectProfile])
  static selectProfileDomainKnowledge(
    primaryDomainItems: DomainKnowledgeStateModel,
    selectProfile: ReturnType<typeof ProfileState.selectProfile>,
  ) {
    return function (profileId?: string): ProfilePrimaryDomainTree[] | null {
      const profile = selectProfile(profileId);
      if (!profile) return null;

      return buildProfileDomainTrees(profile, primaryDomainItems);
    };
  }
}
