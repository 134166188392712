import { Injectable } from "@angular/core";
import { InfoBoxText } from "src/app/store/cms-text-data/cms-text-data.model";

export const closedBoxes = "CLOSED_INFO_BOXES";
export type windowsDefault = "windowsDefault";

@Injectable({
  providedIn: "root",
})
export class InfoBoxService {
  get closedInfoBoxes() {
    if (localStorage.getItem(closedBoxes)) {
      return JSON.parse(localStorage.getItem(closedBoxes) || "");
    } else {
      return [];
    }
  }

  showWindowsUserDefaultMessage(): boolean {
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    const isWindows = navigator.appVersion.indexOf("Win") !== -1;
    const innerWidth = window.innerWidth;
    return isWindows && browserZoomLevel === 150 && innerWidth * (browserZoomLevel / 100) > 1440;
  }

  isShowInfoBox(infoBox: InfoBoxText): boolean {
    if (infoBox.name === "windowsDefault" && this.closedInfoBoxes.indexOf(infoBox.id) === -1) {
      return this.showWindowsUserDefaultMessage();
    }
    if (infoBox.startDate || infoBox.endDate) {
      const now = new Date().getTime();
      const startDate = new Date(infoBox.startDate).getTime();
      const endDate = new Date(infoBox.endDate).getTime();
      return now > startDate && endDate > now && this.closedInfoBoxes.indexOf(infoBox.id) === -1;
    }
    return this.closedInfoBoxes.indexOf(infoBox.id) === -1;
  }

  close(infoBox: InfoBoxText): void {
    let closedInfoBoxesObj = localStorage.getItem(closedBoxes);
    if (!closedInfoBoxesObj) {
      closedInfoBoxesObj = JSON.stringify([infoBox.id]);
    } else {
      closedInfoBoxesObj = JSON.stringify([...JSON.parse(closedInfoBoxesObj), infoBox.id]);
    }
    localStorage.setItem(closedBoxes, closedInfoBoxesObj);
  }
}
