export const TWO_LEVEL_PANEL_QUERY = (limit: number): string => `
    twoLevelPanelCollection(limit: ${limit}) {
        total
        items {
            sys { id }
            name
            title
            descriptionTitle
            descriptionText
            infoButtonText
            infoButtonLink
            actionButtonText
            actionButtonLink
            noDataText { json }
            illustration { url }
            subtitle
            firstLevelTitle
            secondLevelTitle
        }
    }
`;
