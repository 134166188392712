import { NotificationCollection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { NotificationTextItem } from "../cms-text-data.model";

export function parseNotificationTextItems(
  notificationCollection?: NotificationCollection | null,
): NotificationTextItem[] {
  if (!notificationCollection) return [];
  const items = notificationCollection.items.filter(filterNull) || [];

  return items.map(item => ({
    name: item.name || "",
    message: item.message || "",
  }));
}
