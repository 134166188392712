import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InfoBoxText } from "src/app/store/cms-text-data/cms-text-data.model";

@Component({
  selector: "sm-info-box-item",
  templateUrl: "./info-box-item.component.html",
  styleUrls: ["./info-box-item.component.scss"],
})
export class InfoBoxItemComponent {
  @Input() infoBox!: InfoBoxText;
  @Input() hasBorder = false;
  @Output() close = new EventEmitter<void>();

  onClose(): void {
    this.close.emit();
  }
}
