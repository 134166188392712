import { gql } from "apollo-angular";
import { PAGE_COLLECTION_QUERY } from "../utils/page-collection.query";
import { cmsPagesIds } from "../utils/cms-pages-ids.utils";

export const HELP_CONTENT_QUERY = (pages: (keyof typeof cmsPagesIds)[]) => gql`
  query {
    ${PAGE_COLLECTION_QUERY(pages)}
    
    videoResourceCardCollection {
      items {
        sys {
          id
          publishedVersion
        }
        title
        audience
        duration
        thumbnail {
          url
          description
          contentfulMetadata {
            tags {
              id
            }
          }
          sys {
            id
            publishedVersion
          }
        }
        description {
          json
        }
        url {
          json
        }
      }
    }

    externalResourceCardCollection {
      items {
        title
        thumbnail {
          url
          description
          contentfulMetadata {
            tags {
              id
            }
          }
          sys {
            id
            publishedVersion
          }
        }
        description {
          json
        }
        url {
          json
        }
        sys {
          id
          publishedVersion
        }
      }
    }
  }
`;
