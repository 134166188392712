import { CatalogueStoreItemStatus } from "../../catalogue/catalogue.model";
import { ProfileTree } from "../../catalogue/models/profile-aoe-tree.model";
import { CertificateIssuerStoreItem, CertificateStoreItem } from "./certificates.model";

export interface ProfilePrimaryCertificateTree {
  primaryItem: CertificateIssuerStoreItem;
  primaryStatus: null;
  secondaryTrees: ProfileSecondaryCertificateTree[];
}

export interface ProfileSecondaryCertificateTree {
  secondaryItem: CertificateStoreItem;
  secondaryStatus: CatalogueStoreItemStatus;
}

export function isProfilePrimaryCertificateTree(
  tree: ProfileTree,
): tree is ProfilePrimaryCertificateTree {
  return "primaryItem" in tree && tree.primaryItem.type === "CertificateIssuer";
}

export function isProfileSecondaryCertificateTree(
  tree: ProfileTree,
): tree is ProfileSecondaryCertificateTree {
  return "secondaryItem" in tree && tree.secondaryItem.type === "Certificate";
}
