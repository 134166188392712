import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { SpinnerBadgeService } from "../services/spinner-badge.service";
import { finalize } from "rxjs/operators";

@Injectable()
export class SpinnerBadgeInterceptor implements HttpInterceptor {
  constructor(public spinnerBadgeService: SpinnerBadgeService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.spinnerBadgeService.show();
    return next.handle(request).pipe(finalize(() => this.spinnerBadgeService.hide()));
  }
}
