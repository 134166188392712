import {
  LearningPathSectionReference,
  LearningPathSectionStoreItem,
} from "../../learning-paths/models/learning-paths.model";

export function getSkillRelatedLearningPathIds(
  skillId: string,
  sections: LearningPathSectionStoreItem[],
): string[] {
  return getRelatedLearningPathIds(skillId, "Technology", sections);
}

export function getCertificationRelatedLearningPathIds(
  certificationId: string,
  sections: LearningPathSectionStoreItem[],
): string[] {
  return getRelatedLearningPathIds(certificationId, "Certificate", sections);
}

function getRelatedLearningPathIds(
  id: string,
  type: LearningPathSectionReference["type"],
  sections: LearningPathSectionStoreItem[],
): string[] {
  return sections
    .filter(filterReferenceSection(id, type))
    .map(section => section.parentLearningPathId)
    .reduce(
      (uniquePathIds, pathId) =>
        uniquePathIds.includes(pathId) ? uniquePathIds : [...uniquePathIds, pathId],
      [] as string[],
    );
}

function filterReferenceSection(skillId: string, type: LearningPathSectionReference["type"]) {
  return function (section: LearningPathSectionStoreItem): boolean {
    if (!type) return false;

    return section.skillReferences
      .filter(reference => reference.type === type)
      .map(reference => reference.id)
      .includes(skillId);
  };
}
