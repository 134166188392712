export const PAGES_QUERY = (limit: number): string => `
    pageCollection(limit: ${limit}) {
        total
        items {
            sys { id }
            title
            subtitle
            headingSection1
            headingSection2
            headingSection3
            fallbackText { json }
            fallback2 { json }
            fallbackIllustration { url }
            descriptionSection1 { json }
            descriptionSection2 { json }
            descriptionSection3 { json }
            button1Text
            button1Link
            textButton1Link
            textButton1Text
            emptySearchResultIllustration { url }
            emptySearchResultText { json }
        }
    }
`;
