import { PageCollection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { cmsPagesIds } from "../../utils/cms-pages-ids.utils";
import { PageNotFoundPageText } from "../cms-text-data.model";

export function parsePageNotFoundPageTextUtils(
  pageCollection?: PageCollection | null,
): PageNotFoundPageText {
  const pageNotFoundPage = pageCollection?.items
    .filter(filterNull)
    .find(page => page.sys.id === cmsPagesIds.pageNotFound);

  return {
    title: pageNotFoundPage?.title || "",
    description: pageNotFoundPage?.subtitle || "",
    actionDescription: pageNotFoundPage?.headingSection1 || "",
    actionText: pageNotFoundPage?.textButton1Text || "",
    actionLink: pageNotFoundPage?.textButton1Link || "",
    image: pageNotFoundPage?.fallbackIllustration?.url || "",
  };
}
