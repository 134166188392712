import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { insertItem, removeItem } from "@ngxs/store/operators";
import { ToggleCatalogueFilterItem } from "./catalogue-filters.actions";
import {
  CatalogueFilterItem,
  CatalogueFilterType,
  CatalogueFiltersStateModel,
} from "./catalogue-filters.model";

@State<CatalogueFiltersStateModel>({
  name: "catalogueFilters",
  defaults: [],
})
@Injectable()
export class CatalogueFiltersState {
  /* ACTIONS */
  @Action(ToggleCatalogueFilterItem)
  toggleCatalogueFilterItem(
    { getState, setState }: StateContext<CatalogueFiltersStateModel>,
    { item }: ToggleCatalogueFilterItem,
  ): void {
    const alreadyInState = getState().findIndex(({ id }) => id === item.id) >= 0;

    setState(alreadyInState ? removeItem(({ id }) => id === item.id) : insertItem(item));
  }

  /* SELECTORS */
  @Selector()
  static selectSelectedFilterItems(state: CatalogueFiltersStateModel) {
    return function (type?: CatalogueFilterType): CatalogueFilterItem[] {
      if (!type) return state;

      return state.filter(item => item.type === type);
    };
  }
}
