import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InfoBoxComponent } from "./components/info-box/info-box.component";
import { InfoBoxItemComponent } from "./components/info-box-item/info-box-item.component";
import { ButtonModule } from "../button/button.module";

@NgModule({
  declarations: [InfoBoxComponent, InfoBoxItemComponent],
  imports: [CommonModule, ButtonModule],
  exports: [InfoBoxComponent],
})
export class InfoBoxModule {}
