import { gql } from "apollo-angular";

export function DOMAIN_KNOWLEDGE_QUERY(limit: number, skip: number) {
  return gql`
    query {
      primaryDomainCollection(limit: ${limit}, skip: ${skip}) {
        total
        items {
          sys { id, publishedVersion }
          icon { url }
          name
          secondaryDomainsCollection {
            items {
              sys { id, publishedVersion }
              name
            }
          }
        }
      }
    }
  `;
}
