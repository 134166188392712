import { Component, OnInit } from "@angular/core";
import { CmsTextDataService } from "src/app/store/cms-text-data/services/cms-text-data.service";
import { Observable } from "rxjs";
import { InfoBoxText } from "src/app/store/cms-text-data/cms-text-data.model";
import { InfoBoxService } from "../../services/info-box.service";

@Component({
  selector: "sm-info-box",
  templateUrl: "./info-box.component.html",
  styleUrls: ["./info-box.component.scss"],
})
export class InfoBoxComponent implements OnInit {
  infoBoxItems$!: Observable<InfoBoxText[]>;

  constructor(
    private cmsTextDataService: CmsTextDataService,
    private infoBoxService: InfoBoxService,
  ) {}

  ngOnInit(): void {
    this.infoBoxItems$ = this.cmsTextDataService.selectInfoBoxTextItems();
  }

  isShowInfoBox(infoBox: InfoBoxText): boolean {
    return this.infoBoxService.isShowInfoBox(infoBox);
  }

  close(infoBox: InfoBoxText): void {
    this.infoBoxService.close(infoBox);
  }
}
