import { PrimaryPowerSkillItem, SecondaryPowerSkillItem } from "../models/power-skills.model";

export function reduceSecondaryPowerSkillItems(
  primarySkills: PrimaryPowerSkillItem[],
): SecondaryPowerSkillItem[] {
  return primarySkills.reduce(
    (secondaryItems, primaryItem) => [...secondaryItems, ...primaryItem.secondaryPowerSkillItems],
    [] as SecondaryPowerSkillItem[],
  );
}
