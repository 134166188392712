import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { AreaOfExpertise } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { AoEStoreItem } from "../catalogue.model";

export function formatCMSAoEItem(aoe: AreaOfExpertise): AoEStoreItem {
  const specChildrenIds =
    aoe.specialisationsCollection?.items.filter(filterNull).map(spec => spec.sys.id) || [];

  return {
    id: aoe.sys.id,
    type: aoe.__typename,
    name: aoe.name || "",
    iconUrl: aoe.icon?.url || "",
    illustrationUrl: aoe.illustration?.url || "",
    entityVersion: aoe.sys.publishedVersion || 1,
    description: aoe.description || "",
    levelDescriptionTitle: aoe.levelDescriptionTitle || "",
    specChildrenIds,
    levelDescription: {
      junior: documentToHtmlString(aoe.junior?.json),
      regular: documentToHtmlString(aoe.regular?.json),
      senior: documentToHtmlString(aoe.senior?.json),
      principal: documentToHtmlString(aoe.principal?.json),
      seniorPrincipal: documentToHtmlString(aoe.seniorPrincipal?.json),
    },
  };
}
