import { InfoBoxText } from "../cms-text-data.model";
import { InfoBoxCollection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";

export function parseInfoBoxTextItems(infoBoxCollection?: InfoBoxCollection | null): InfoBoxText[] {
  if (!infoBoxCollection) return [];

  return infoBoxCollection.items.filter(filterNull).map(item => ({
    id: item.sys.id || "",
    name: item.name || "",
    heading: item.heading || "",
    description: item.description || "",
    iconUrl: item.icon?.url || "",
    linkText: item.linkText || "",
    linkUrl: item.linkUrl || "",
    startDate: item.startDate || "",
    endDate: item.endDate || "",
    closable: item.closable || false,
  }));
}
