import { PrimaryDomainItem, SecondaryDomainItem } from "../domain-knowledge.models";

export function reduceSecondaryDomainItems(
  primaryDomains: PrimaryDomainItem[],
): SecondaryDomainItem[] {
  return primaryDomains.reduce(
    (secondaryItems, primaryItem) => [...secondaryItems, ...primaryItem.secondaryDomainItems],
    [] as SecondaryDomainItem[],
  );
}
