import { PendingRequestTree } from "../models/pending-request-tree.model";
import { TeamMembersStateModel } from "../models/team-members.model";
import { buildAllTeamMembersPendingRequestTree } from "./build-all-team-members-pending-request-tree.utils";
import { buildTeamMemberPendingRequestTrees } from "./build-team-member-request-tree.utils";

export function getPendingRequestTrees(
  teamMembers: TeamMembersStateModel,
  teamMemberId?: string,
): PendingRequestTree[] {
  if (!teamMemberId) return buildAllTeamMembersPendingRequestTree(teamMembers);

  const teamMember = teamMembers.find(({ id }) => teamMemberId === id);
  if (!teamMember) return [];

  return buildTeamMemberPendingRequestTrees(teamMember);
}
