import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { IconModule } from "@shared/icon/icon.module";

import { environment } from "src/environments/environment";
import { AdminService } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GraphQLModule } from "./graphql.module";
import { TeamGuard } from "@shared/guards/team.guard";
import { ProfileSearchGuard } from "@shared/guards/profile-search.guard";
import { IskmaInterceptor } from "./shared/interceptors/iskma.interceptor";
import { SpinnerBadgeInterceptor } from "./shared/interceptors/spinner-badge.interceptor";
import { AppService } from "./shared/services/app.service";
import { SpinnerBadgeService } from "./shared/services/spinner-badge.service";
import { SharedModule } from "./shared/shared.module";
import { AppStoreModule } from "./store/store.module";
import { InfoBoxModule } from "./features/info-box/info-box.module";
import { SidebarModule } from "./features/sidebar/sidebar.module";

const getConfig = (defaultValue: string, field: keyof typeof environment) => {
  const configValue = localStorage.getItem(field.toString()) || defaultValue;
  localStorage.setItem(field, configValue);
  return configValue;
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    SidebarModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    IconModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: getConfig(environment.MA_CLIENT_ID, "MA_CLIENT_ID"),
          authority: getConfig(environment.MA_AUTHORITY, "MA_AUTHORITY"),
          redirectUri: getConfig(environment.MA_REDIRECT_URI, "MA_REDIRECT_URI"),
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ["user.read"],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([["https://graph.microsoft.com", ["user.read"]]]),
      },
    ),
    GraphQLModule,
    AppStoreModule,
    InfoBoxModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IskmaInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerBadgeInterceptor, multi: true },
    MsalGuard,
    TeamGuard,
    ProfileSearchGuard,
    AppService,
    SpinnerBadgeService,
    AdminService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
