import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { EMPTY, Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthorizationService } from "../services/authorization.service";
import { NotificationService } from "../services/notification.service";
import { MsalProfileService } from "@shared/services/msal-profile.service";

const azureHost = new URL(environment.AZ_URL).hostname.toString();

const HOSTS = {
  azure: azureHost,
  contentful: "graphql.contentful.com",
  microsoftOnline: "microsoftonline.com",
  microsoft: "graph.microsoft.com/v1.0",
};

const excluded = [".svg", "photo/$value"];

@Injectable()
export class IskmaInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthorizationService,
    private msalService: MsalService,
    private msalProfile: MsalProfileService,
    private notificationService: NotificationService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (excluded.find(x => request.url.endsWith(x))) {
      return next.handle(request);
    }

    const domain = new URL(request.url);
    let newReq = request;

    if (domain.hostname.includes(HOSTS.azure)) {
      newReq = this.setupAzureReq(request);
    }

    if (domain.hostname.includes(HOSTS.contentful)) {
      newReq = this.setupContentfulReq(request);
    }

    return next.handle(newReq).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          switch (true) {
            // AZURE Auth failed
            case err.url?.includes(HOSTS.azure) && err.status === 401:
              console.log("Refresh token approach");
              return this.updateMsalIdToken().pipe(
                catchError(() => {
                  console.log("Forcing logout");
                  this.logoutUser();
                  return EMPTY;
                }),
                switchMap(() => this.intercept(newReq, next)),
              );
            // Contentful Auth failed
            case err.url?.includes(HOSTS.contentful) && err.status === 401:
              console.log("Getting contentful key");
              return this.authService.getContentfulKey().pipe(
                tap(res => {
                  this.msalProfile.setProfile(this.msalService.instance.getAllAccounts()[0]);
                  this.authService.setCurrentKey(res.api_key);
                }),
                switchMap(() => this.intercept(newReq, next)),
              );
            // MICROSOFT Refresh token failed
            case err.url?.includes(HOSTS.microsoftOnline) &&
              err.url?.endsWith("/token") &&
              err.status === 400:
              console.log("Logging out user");
              this.logoutUser();
              return EMPTY;
            // API error handler (/change)
            case err.url?.includes(HOSTS.azure) &&
              err.url?.includes("/change") &&
              err.status === 424:
              this.notificationService.showError({ error: err });
              throw err;
            case err.url?.includes(HOSTS.azure) &&
              err.url?.includes("/change") &&
              err.status === 409:
              this.notificationService.showError({ error: err });
              throw err;
            default:
              this.notificationService.showError({ error: err });
          }
        } else {
          this.notificationService.showError({ error: err });
        }

        return EMPTY;
      }),
    );
  }

  logoutUser(): void {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (!account) return;

    this.msalService.logoutRedirect({ account });
  }

  setupAzureReq(request: HttpRequest<any>): HttpRequest<any> {
    const authKey = this.authService.getCurrentIdToken();
    // "ocp-apim-subscription-key": environment.SUBSCRIPTION_KEY
    const authReq = request.clone({
      headers: request.headers
        .set("Authorization", "Bearer ".concat(authKey))
        .set("ocp-apim-subscription-key", environment.SUB_KEY),
    });

    return authReq;
  }

  setupContentfulReq(request: HttpRequest<any>): HttpRequest<any> {
    const authReq = request.clone({
      headers: request.headers.set(
        "Authorization",
        "Bearer ".concat(this.authService.getCurrentKey()),
      ),
    });

    return authReq;
  }

  updateMsalIdToken() {
    // If token expires, we use MSAL to get a new one and update it locally
    const accounts = this.msalService.instance.getAllAccounts()[0];
    if (!accounts) return EMPTY;

    const authRequest = {
      account: accounts,
      scopes: ["user.read"],
    };

    return this.msalService.acquireTokenSilent(authRequest).pipe(
      tap(res => {
        if (res.account) this.msalProfile.setProfile(res.account);
        if (res.idToken) this.authService.setCurrentIdToken(res.idToken);
      }),
    );
  }
}
