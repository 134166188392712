import { StateContext } from "@ngxs/store";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { NewsCard } from "@shared/models/contentful.types";
import { NewsItem, NewsStateModel } from "../news.model";

export function setNewsState({ setState }: StateContext<NewsStateModel>) {
  return function (cmsNews: NewsCard[]): NewsItem[] {
    const formattedNews: NewsItem[] = cmsNews.map(cmsNew => ({
      headline: cmsNew.headline || "",
      buttonLink: cmsNew.buttonLink || "",
      buttonText: cmsNew.buttonText || "",
      imageUrl: cmsNew.image?.url || "",
      description: documentToHtmlString(cmsNew.description?.json),
      publishDate: new Date(cmsNew.publishDate),
    }));

    return setState(formattedNews);
  };
}
