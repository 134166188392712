import { Injectable } from "@angular/core";
import { State, Action, StateContext } from "@ngxs/store";
import { map, shareReplay, tap } from "rxjs/operators";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { filterNullItemsPipe } from "@shared/utils/filter-null.utils";
import { CMSRequestService } from "../services/cms-request.service";
import { GetOverallLevelDescriptions } from "./overall-level-descriptions.actions";
import { OverallLevelDescriptionsStateModel } from "./overall-level-descriptions.model";
import { OVERALL_LEVEL_DESCRIPTION_QUERY } from "./overall-level-description.query";

@State<OverallLevelDescriptionsStateModel>({
  name: "overallLevelDescriptions",
  defaults: {
    junior: "",
    regular: "",
    senior: "",
    principal: "",
    seniorPrincipal: "",
  },
})
@Injectable()
export class OverallLevelDescriptionsState {
  constructor(private cmsService: CMSRequestService) {}

  @Action(GetOverallLevelDescriptions)
  getOverallDescriptions({ setState }: StateContext<OverallLevelDescriptionsStateModel>) {
    return this.cmsService.cmsQuery(OVERALL_LEVEL_DESCRIPTION_QUERY()).pipe(
      map(result => result.data.overallLevelDescriptionCollection?.items || []),
      filterNullItemsPipe,
      map(items => items[0]),
      map(levelDescriptions => ({
        junior: documentToHtmlString(levelDescriptions.junior?.json),
        regular: documentToHtmlString(levelDescriptions.regular?.json),
        senior: documentToHtmlString(levelDescriptions.senior?.json),
        principal: documentToHtmlString(levelDescriptions.principal?.json),
        seniorPrincipal: documentToHtmlString(levelDescriptions.seniorPrincipal?.json),
      })),
      shareReplay(1),
      tap(setState),
    );
  }
}
