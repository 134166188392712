import {
  ApprovalsPageText,
  CmsDataStateModel,
  LearningPathPageText,
  PageNotFoundPageText,
  ProfilePageText,
  ProfileSearchPageText,
  SearchResultTableText,
  SidebarText,
} from "./cms-text-data.model";

export const cmsTextDataDefaults: CmsDataStateModel = {
  notifications: [],
  popups: [],
  sidebarItems: [],
  sidebar: {} as SidebarText,
  profilePage: {} as ProfilePageText,
  profileSearchPage: {} as ProfileSearchPageText,
  searchResultTable: {} as SearchResultTableText,
  approvalsPage: {} as ApprovalsPageText,
  twoLevelPanels: [],
  infoBoxes: [],
  headlines: [],
  pageNotFoundPage: {} as PageNotFoundPageText,
  learningPath: {} as LearningPathPageText,
};
