<button
  class="button {{ buttonStyle }}-button {{ buttonSize }}-button"
  [class.has-icon]="hasIcon"
  [class.loading]="loading"
  (click)="clickEvent($event)"
  [disabled]="disabled"
>
  <mat-icon *ngIf="loading" class="spinner-loading">
    <mat-spinner class="background" mode="determinate" diameter="18" value="100"></mat-spinner>
    <mat-spinner diameter="18"></mat-spinner>
  </mat-icon>

  <ng-content></ng-content>
</button>
