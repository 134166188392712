import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SpinnerBadgeService {
  isSpinnerLoading = new BehaviorSubject<boolean>(false);

  show(): void {
    this.isSpinnerLoading.next(true);
  }
  hide(): void {
    this.isSpinnerLoading.next(false);
  }
}
