export const NOTIFICATIONS_QUERY = (limit: number): string => `
    notificationCollection(limit: ${limit}) {
        total
        items {
            sys { id }
            name
            message
        }
    }
`;
