import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MsalService } from "@azure/msal-angular";
import { EnvComponent } from "@shared/components/env/env.component";
import { environment } from "src/environments/environment";

/**
 * This service is limited to local/dev environment.
 * Because of a custom injection, every function MUST be
 * an arrow function to preserve the scope.
 */
@Injectable({
  providedIn: "root",
})
export class AdminService {
  visible = false;
  buttons: { name: string; function: any }[] = [];

  constructor(private http: HttpClient, private router: Router, private msalService: MsalService) {
    /**
     * Declare new admin functions here,
     * create the function on the bottom.
     */
    this.buttons.push(
      { name: "Reset own profile", function: this.resetMe },
      { name: "Log out", function: this.logout },
      { name: "Environment: Intive", function: this.setEnvIntive },
      { name: "Environment: Test", function: this.setEnvTest },
    );
  }

  static getAdminRouteComponent = () => {
    return EnvComponent;
  };

  setupAdmin = () => {
    document.body.addEventListener("keyup", ($event: KeyboardEvent) => {
      if ($event.key === "F2") {
        if (this.visible) {
          this.removeAdmin();
        } else {
          this.createAdmin();
        }
      }
    });
  };

  removeAdmin = () => {
    document.getElementById("admin-panel")?.remove();
    this.visible = false;
  };

  createAdmin = () => {
    const div = document.createElement("div");
    div.style.zIndex = "9999999999999999999";
    div.id = "admin-panel";
    div.innerText = "Dev Panel\nOpen/close with F2";

    div.style.marginTop = "10px";
    div.style.marginRight = "20px";
    div.style.fontSize = "12px";
    div.style.lineHeight = "20px";

    div.style.position = "absolute";
    div.style.top = "0";
    div.style.right = "0";
    div.style.width = "200px";
    div.style.padding = "10px 10px 10px 10px";

    div.style.backgroundColor = "white";
    div.style.border = "1px solid blue";
    div.style.borderRadius = "10px";

    const buttons = this.createButtons();
    buttons.forEach(btn => div.appendChild(btn));

    document.body.appendChild(div);
    this.visible = true;
  };

  createButtons = () => {
    const buttons: any[] = [];

    this.buttons.forEach((button, index) => {
      const btn = document.createElement("button");
      btn.addEventListener("click", () => button.function(btn));
      btn.addEventListener("mousedown", mx => {
        (mx.target as HTMLElement).style.backgroundColor = "lightgrey";
      });
      btn.addEventListener("mouseup", mx => {
        (mx.target as HTMLElement).style.backgroundColor = "rgb(240, 240, 240)";
      });

      btn.innerText = button.name;
      btn.style.width = "100%";
      btn.style.marginTop = "10px";
      btn.style.fontSize = "16px";
      btn.style.padding = "3px";
      btn.style.borderRadius = "5px";
      btn.style.border = "1px solid gray";

      if (index === 0) {
        btn.style.marginTop = "25px";
      }

      buttons.push(btn);
    });

    return buttons;
  };

  resetMe = (btn: HTMLButtonElement) => {
    btn.disabled = true;
    const text = btn.innerText;

    btn.innerText = "Loading";

    return this.http.post(`${environment.AZ_URL}/reset/me`, {}).subscribe(
      res => {
        alert("Successfully reset own profile!");
        console.log("Reset me: ", res);
      },
      err => {
        alert("Error occurred while trying to reset own profile!");
        console.log("Error reset me: ", err);
      },
      () => {
        btn.disabled = false;
        btn.innerText = text;
        this.router.navigate(["/"]);
      },
    );
  };

  setEnvIntive = () => {
    this.setEnv(environment.MA_CLIENT_ID, environment.MA_AUTHORITY, "env-intive");
  };

  setEnvTest = () => {
    const clientIdTest = "1cb08281-34ae-4d9c-a2b0-1a718f86404c";
    const authorityTest = "https://login.microsoftonline.com/eaf9759d-1295-40a2-bb47-2f00cb0be55a";
    this.setEnv(clientIdTest, authorityTest, "env-test");
    // this.setEnv(environment.MA_CLIENT_ID_TEST, environment.MA_AUTHORITY_TEST, "env-test");
  };

  /**
   *
   * @param clientId Which clientId to be used for login
   * @param authority Which authority to be used for login
   * @param description Which description to leave in the localStorage
   */
  setEnv = (clientId: string, authority: string, description: string) => {
    localStorage.clear();

    localStorage.setItem("MA_CLIENT_ID", clientId);
    localStorage.setItem("MA_AUTHORITY", authority);
    localStorage.setItem("environment", description);
    this.logoutPopup();
  };

  logoutPopup() {
    const acc = this.msalService.instance.getAllAccounts();
    this.msalService.logoutPopup({ account: acc[0] });
  }

  logout = () => {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (!account) return;

    this.msalService.logoutRedirect({ account });
  };
}
