import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, zip } from "rxjs";
import { concatMap, map, switchMap, tap } from "rxjs/operators";
import { ProfileStoreService } from "src/app/store/profile/services/profile-store.service";
import { TeamMembersStoreService } from "src/app/store/team-members/services/team-members-store.service";

@Injectable({ providedIn: "root" })
export class ProfileSearchGuard implements CanActivate {
  constructor(
    private router: Router,
    private profileStore: ProfileStoreService,
    private teamMembersStore: TeamMembersStoreService,
  ) {}

  canActivate(): Observable<boolean> {
    const fetchProfile$ = this.profileStore.fetchProfile();
    const fetchTeamMembers$ = this.teamMembersStore.fetchTeamMembers();
    const isCmOrPm$ = this.profileStore.selectProfileIsCmOrPm();
    const isTeamLead$ = this.teamMembersStore.selectIsTeamLead();

    return fetchProfile$.pipe(
      concatMap(() => fetchTeamMembers$),
      switchMap(() =>
        zip(isCmOrPm$, isTeamLead$).pipe(
          map(([isCmOrPm, isTeamLead]) => isCmOrPm || isTeamLead),
          tap(isAllowed => {
            if (!isAllowed) this.router.navigate(["/"]);
          }),
        ),
      ),
    );
  }
}
