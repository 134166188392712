import { PrimaryDomainItem, SecondaryDomainItem } from "../domain-knowledge.models";
import { ProfileTree } from "../../catalogue/models/profile-aoe-tree.model";
import { CatalogueStoreItemStatus } from "../../catalogue/catalogue.model";

export interface ProfilePrimaryDomainTree {
  primaryItem: PrimaryDomainItem;
  primaryStatus: CatalogueStoreItemStatus;
  secondaryTrees: ProfileSecondaryDomainTree[];
}

export function isProfilePrimaryDomainTree(tree: ProfileTree): tree is ProfilePrimaryDomainTree {
  return "primaryItem" in tree && tree.primaryItem.type === "PrimaryDomain";
}

export interface ProfileSecondaryDomainTree {
  secondaryItem: SecondaryDomainItem;
  secondaryStatus: CatalogueStoreItemStatus;
}

export function isProfileSecondaryDomainTree(
  tree: ProfileTree,
): tree is ProfileSecondaryDomainTree {
  return "secondaryItem" in tree && tree.secondaryItem.type === "SecondaryDomain";
}
