import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { filterNullItemsPipe } from "@shared/utils/filter-null.utils";
import {
  GetLearningPathSectionSteps,
  GetLearningPathSections,
  GetLearningPaths,
} from "./learning-paths.actions";
import {
  LearningPathSectionStepStoreItem,
  LearningPathSectionStoreItem,
  LearningPathStoreItem,
  LearningPathsStateModel,
} from "./models/learning-paths.model";
import {
  LEARNING_PATHS_QUERY,
  LEARNING_PATH_SECTIONS_QUERY,
  LEARNING_PATH_SECTION_STEPS_QUERY,
} from "./learning-paths.query";
import { CMSRequestService, LargeCollectionRequestArgs } from "../services/cms-request.service";
import { parseLearningPaths } from "./utils/parse-learning-paths.utils";
import { parseLearningPathSections } from "./utils/parse-learning-path-sections";
import { setLearningPathsState } from "./utils/set-learning-paths-state.utils";
import { setLearningPathSectionsState } from "./utils/set-learning-path-sections.utils";
import { parseLearningPathSectionSteps } from "./utils/parse-learning-path-section-steps";
import { setLearningPathSectionStepsState } from "./utils/set-learning-path-section-items.utils";

@State<LearningPathsStateModel>({
  name: "learningPaths",
  defaults: {
    paths: [],
    sections: [],
    steps: [],
  },
})
@Injectable()
export class LearningPathsState {
  constructor(private cmsService: CMSRequestService) {}

  /* ACTIONS */
  @Action(GetLearningPaths)
  getLearningPaths(
    context: StateContext<LearningPathsStateModel>,
  ): Observable<LearningPathStoreItem[]> {
    const fetchArgs: LargeCollectionRequestArgs<"learningPathCollection"> = {
      collection: "learningPathCollection",
      limit: 40,
      itemQuery: LEARNING_PATHS_QUERY,
    };

    return this.cmsService.fetchLargeCollectionItems(fetchArgs).pipe(
      map(({ data }) => data[fetchArgs.collection]?.items || []),
      filterNullItemsPipe,
      map(parseLearningPaths),
      tap(setLearningPathsState(context)),
    );
  }

  @Action(GetLearningPathSections)
  getLearningPathSections(
    context: StateContext<LearningPathsStateModel>,
  ): Observable<LearningPathSectionStoreItem[]> {
    const fetchArgs: LargeCollectionRequestArgs<"learningPathSectionCollection"> = {
      collection: "learningPathSectionCollection",
      limit: 25,
      itemQuery: LEARNING_PATH_SECTIONS_QUERY,
    };

    return this.cmsService.fetchLargeCollectionItems(fetchArgs).pipe(
      map(({ data }) => data[fetchArgs.collection]?.items || []),
      filterNullItemsPipe,
      map(parseLearningPathSections),
      tap(setLearningPathSectionsState(context)),
    );
  }

  @Action(GetLearningPathSectionSteps)
  getLearningPathSectionSteps(
    context: StateContext<LearningPathsStateModel>,
    { sectionStepIds }: GetLearningPathSectionSteps,
  ): Observable<LearningPathSectionStepStoreItem[]> {
    const fetchArgs: LargeCollectionRequestArgs<"learningPathItemCollection"> = {
      collection: "learningPathItemCollection",
      limit: 40,
      itemQuery: LEARNING_PATH_SECTION_STEPS_QUERY(sectionStepIds),
    };

    return this.cmsService.fetchLargeCollectionItems(fetchArgs).pipe(
      map(({ data }) => data[fetchArgs.collection]?.items || []),
      filterNullItemsPipe,
      map(parseLearningPathSectionSteps),
      tap(setLearningPathSectionStepsState(context)),
    );
  }

  /* SELECTORS */
  @Selector()
  static selectLearningPaths(state: LearningPathsStateModel): LearningPathStoreItem[] {
    return state.paths;
  }

  @Selector()
  static selectAllLearningPathSections(
    state: LearningPathsStateModel,
  ): LearningPathSectionStoreItem[] {
    return state.sections;
  }

  @Selector()
  static selectLearningPathCategories(state: LearningPathsStateModel): string[] {
    const allCategories = state.paths.map(path => path.categories).flat();
    const uniqueCategories = [...new Set(allCategories)];
    return uniqueCategories.sort();
  }

  @Selector()
  static selectLearningPathItem(state: LearningPathsStateModel) {
    return function (learningPathId: string): LearningPathStoreItem | undefined {
      return state.paths.find(path => path.id === learningPathId);
    };
  }

  @Selector()
  static selectLearningPathSections(state: LearningPathsStateModel) {
    return function (learningPathId: string): LearningPathSectionStoreItem[] {
      return state.sections.filter(section => section.parentLearningPathId === learningPathId);
    };
  }

  @Selector()
  static selectLearningPathSectionSteps(state: LearningPathsStateModel) {
    return function (pathSectionIds: string[]): LearningPathSectionStepStoreItem[] {
      return state.steps.filter(step => pathSectionIds.includes(step.parentSectionId));
    };
  }
}
