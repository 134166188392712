export const INFO_BOXES_QUERY = (limit: number): string => `
    infoBoxCollection(limit: ${limit}) {
        total
            items {
                sys { id }
                name
                heading
                description
                icon { url }
                startDate
                endDate
                linkText
                linkUrl
                closable
            }
    }
`;
