export const HEADLINES_QUERY = (limit: number): string => `
    catalogueHeadlinesCollection(limit: ${limit}) {
        total
        items {
            sys { id }
            name
            mainHeadline
            subheadline
            unavailableHeadline
        }
    }
`;
