import { Editor, LearningPath, LearningPathSection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { LearningPathStoreItem } from "../models/learning-paths.model";
import { formatPathDescription } from "./format-path-description.utils";

export function parseLearningPaths(cmsPaths: LearningPath[]): LearningPathStoreItem[] {
  return cmsPaths.map(p => ({
    id: p.sys.id,
    entityVersion: p.sys.publishedVersion || 0,
    type: "LearningPath",
    name: p.title || "",
    url: p.url || "",
    description: formatPathDescription(p.description),
    platformId: String(p.platformId || ""),
    editors: parseEditors(p.editorsCollection?.items),
    sectionIds: getSectionIds(p.sectionsCollection?.items),
    categories: getCategories(p.categories),
    ...(isString(p.creationDate) && { creationDate: new Date(p.creationDate) }),
    ...(isString(p.lastModified) && { lastModified: new Date(p.lastModified) }),
  }));
}

function parseEditors(editors?: (Editor | null)[]): { name: string; email: string }[] {
  return (
    editors?.filter(filterNull).map(e => ({
      name: e.displayName || "",
      email: e.email || "",
    })) || []
  );
}

function getSectionIds(sections?: (LearningPathSection | null)[]): string[] {
  return sections?.filter(filterNull).map(item => item.sys.id) || [];
}

function getCategories(categories: LearningPath["categories"]): string[] {
  return categories?.filter(filterNull) || [];
}

function isString(value: unknown): boolean {
  return typeof value === "string";
}
