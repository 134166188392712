import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { GetHistory } from "./history.actions";
import { HistoryModel, HistoryStateModel, MY_HISTORY_KEY } from "./history.models";

@State<HistoryStateModel>({
  name: "history",
  defaults: {},
})
@Injectable()
export class HistoryState {
  private firedRequests: Record<string, true | undefined> = {};

  constructor(private http: HttpClient) {}

  /** ACTIONS */
  @Action(GetHistory)
  fetchHistory(
    { getState, patchState }: StateContext<HistoryStateModel>,
    { entityType, profileId }: GetHistory,
  ): Observable<HistoryModel[]> {
    const historyKey = profileId || MY_HISTORY_KEY;
    if (this.firedRequests[historyKey]) return of(getState()[historyKey]);

    const urlSuffix = profileId ? `/${profileId}` : "";
    this.firedRequests[historyKey] = true;

    return this.http
      .get<HistoryModel[]>(`${environment.AZ_URL}/history/${entityType}${urlSuffix}`)
      .pipe(tap(history => patchState({ [historyKey]: history })));
  }

  /* SELECTORS */

  @Selector()
  static selectHistory(history: HistoryStateModel) {
    return function (profileId?: string): HistoryModel[] {
      return history?.[profileId || MY_HISTORY_KEY];
    };
  }
}
