import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProfileState } from "../profile.state";
import { ProfileModel } from "../profile.model";
import { GetProfile } from "../profile.actions";
import { isCmOrPm } from "../utils/is-cm-or-pm.utils";

@Injectable({ providedIn: "root" })
export class ProfileStoreService {
  @Select(ProfileState.selectProfile)
  private selectProfile$!: Observable<ReturnType<typeof ProfileState.selectProfile>>;

  @Select(ProfileState.selectEmailNotificationStatus)
  private emailNotificationStatus$!: Observable<boolean>;

  @Select(ProfileState.selectPendingRequestNumber)
  private selectPendingRequestNumber$!: Observable<
    ReturnType<typeof ProfileState.selectPendingRequestNumber>
  >;

  constructor(private store: Store) {}

  /**
   * @param profileId if not provided, the id of the current user will be set
   */
  fetchProfile(profileId?: string) {
    return this.store.dispatch(new GetProfile(profileId));
  }

  /**
   * @param profileId if not provided, the id of the current user will be set
   * @returns  profile model, if already in store
   */
  selectProfile(profileId?: string): Observable<ProfileModel | undefined> {
    return this.selectProfile$.pipe(map(selectProfile => selectProfile(profileId)));
  }

  /**
   * @returns current user email notification status
   */
  selectEmailNotificationStatus(): Observable<boolean> {
    return this.emailNotificationStatus$;
  }

  /**
   * @param profileId if not provided, the id of the current user will be set
   * @returns number of pending requests (approvals)
   */
  selectPendingRequestNumber(profileId?: string): Observable<number> {
    return this.selectPendingRequestNumber$.pipe(
      map(selectPendingRequestNumber => selectPendingRequestNumber(profileId)),
    );
  }

  /**
   * @param profileId if not provided, the id of the current user will be set
   * @returns the profile is capacity or project manager, or not
   */
  selectProfileIsCmOrPm(profileId?: string): Observable<boolean> {
    return this.selectProfile(profileId).pipe(
      map(profile => profile?.employee.jobTitle),
      map(isCmOrPm),
    );
  }
}
