import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { PageCollection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { ApprovalsPageText } from "../cms-text-data.model";
import { cmsPagesIds } from "../../utils/cms-pages-ids.utils";

export function parseApprovalsPageText(pageCollection?: PageCollection | null): ApprovalsPageText {
  const approvalsPage = pageCollection?.items
    .filter(filterNull)
    .find(page => page.sys.id === cmsPagesIds.approvals);

  return {
    title: approvalsPage?.title || "",
    declineRequestSection1: documentToHtmlString(approvalsPage?.descriptionSection1?.json) || "",
    declineRequestSection2: documentToHtmlString(approvalsPage?.descriptionSection2?.json) || "",
    declineRequestSection3: documentToHtmlString(approvalsPage?.descriptionSection3?.json) || "",
    emptySearchResultIcon: approvalsPage?.emptySearchResultIllustration?.url || "",
    emptySearchResultText: documentToHtmlString(approvalsPage?.emptySearchResultText?.json) || "",
    noDataIcon: approvalsPage?.fallbackIllustration?.url || "",
    noDataText: documentToHtmlString(approvalsPage?.fallback2?.json) || "",
  };
}
