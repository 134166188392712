export class GetLearningPaths {
  static readonly type = "[LearningPaths] Get Learning Paths";
}

export class GetLearningPathSections {
  static readonly type = "[LearningPaths] Get Learning Path Sections";
}

export class GetLearningPathSectionSteps {
  static readonly type = "[LearningPaths] Get Learning Path Section Steps";
  constructor(public sectionStepIds: string[]) {}
}
