import { StateContext } from "@ngxs/store";
import { DomainKnowledgeStateModel, PrimaryDomainItem } from "../domain-knowledge.models";
import { areStoreItemArraysEqual } from "../../utils/is-store-items-array-equal.utils";

export function setDomainKnowledgeState({
  getState,
  setState,
}: StateContext<DomainKnowledgeStateModel>) {
  return function (primaryDomains: PrimaryDomainItem[]): void {
    const prevPrimaryDomains = getState();
    const shouldNotUpdateState = areStoreItemArraysEqual(primaryDomains, prevPrimaryDomains);
    if (shouldNotUpdateState) return;

    setState([...prevPrimaryDomains, ...primaryDomains]);
  };
}
