import { PageCollection } from "@shared/models/contentful.types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { filterNull } from "@shared/utils/filter-null.utils";
import { cmsPagesIds } from "../../utils/cms-pages-ids.utils";

export function parseProfileSearchPageText(pageCollection?: PageCollection | null) {
  const profileSearchPage = pageCollection?.items
    .filter(filterNull)
    .find(page => page.sys.id === cmsPagesIds.profileSearch);

  return {
    title: profileSearchPage?.title || "",
    subTitleTop: profileSearchPage?.subtitle || "",
    subTitleBottom1: profileSearchPage?.headingSection1 || "",
    subTitleBottom2: profileSearchPage?.headingSection2 || "",
    iconUrl: profileSearchPage?.fallbackIllustration?.url || "",
    peopleSearchTitle: documentToHtmlString(profileSearchPage?.descriptionSection1?.json) || "",
    skillSearchTitle: documentToHtmlString(profileSearchPage?.descriptionSection2?.json) || "",
    skillSearchDescription:
      documentToHtmlString(profileSearchPage?.descriptionSection3?.json) || "",
  };
}
