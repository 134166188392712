<div class="info-box-container">
  <ng-container *ngFor="let infoBox of infoBoxItems$ | async; let i = index">
    <sm-info-box-item
      *ngIf="isShowInfoBox(infoBox)"
      [infoBox]="infoBox"
      [hasBorder]="i > 0"
      (close)="close(infoBox)"
    ></sm-info-box-item>
  </ng-container>
</div>
