import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Specialisation } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { AoEStoreItem, SpecStoreItem } from "../catalogue.model";

export function formatCMSSpecItem(aoes: AoEStoreItem[]) {
  return function (spec: Specialisation): SpecStoreItem {
    const id = spec.sys.id;
    const parentAoE = aoes.find(aoe => aoe.specChildrenIds.find(specId => specId === id));
    const skillChildrenIds =
      spec.technologiesCollection?.items.filter(filterNull).map(skill => skill.sys.id) || [];

    return {
      id,
      type: spec.__typename,
      name: spec?.name || "",
      parentId: parentAoE?.id || "",
      parentName: parentAoE?.name || "",
      iconUrl: parentAoE?.iconUrl || "",
      entityVersion: spec.sys.publishedVersion || 1,
      description: spec?.description || "",
      skillChildrenIds,
      levelDescription: {
        junior: documentToHtmlString(spec.junior?.json),
        regular: documentToHtmlString(spec.regular?.json),
        senior: documentToHtmlString(spec.senior?.json),
        principal: documentToHtmlString(spec.principal?.json),
        seniorPrincipal: documentToHtmlString(spec.seniorPrincipal?.json),
      },
    };
  };
}
