import { StateContext } from "@ngxs/store";
import { Technology } from "@shared/models/contentful.types";
import { CatalogueStateModel } from "../catalogue.model";
import { areCMSItemArraysEqual } from "../../utils/is-store-items-array-equal.utils";

export function setCatalogueSkillState({
  getState,
  patchState,
}: StateContext<CatalogueStateModel>) {
  return function (cmsSkills: Technology[]): void {
    const prevSkills = getState().cmsSkills;
    const shouldNotUpdateState = areCMSItemArraysEqual(cmsSkills, prevSkills);
    if (shouldNotUpdateState) return;

    patchState({ cmsSkills: [...prevSkills, ...cmsSkills] });
  };
}
