export function formatPathDescription(description?: string | null): string {
  if (!description) return "";

  return (
    description
      // Remove consecutive white spaces
      .replace(/\s+/g, " ")
      // Remove hyphens followed by a word
      .replace(/-\w/g, groups => groups[1])
  );
}
