import {
  AoEStoreItem,
  CatalogueStoreItemStatus,
  SkillStoreItem,
  SpecStoreItem,
} from "../catalogue.model";
import {
  ProfilePrimaryDomainTree,
  ProfileSecondaryDomainTree,
} from "../../domain-knowledge/models/profile-domain-knowledge-tree.model";
import {
  ProfilePrimaryPowerSkillTree,
  ProfileSecondaryPowerSkillTree,
} from "../../power-skills/models/power-skill-tree.model";
import {
  ProfilePrimaryCertificateTree,
  ProfileSecondaryCertificateTree,
} from "../../certificates/models/profile-certificate-tree.model";

export type ProfileTree =
  | ProfileAoETree
  | ProfileSpecTree
  | ProfileSkillTree
  | ProfilePrimaryDomainTree
  | ProfileSecondaryDomainTree
  | ProfilePrimaryPowerSkillTree
  | ProfileSecondaryPowerSkillTree
  | ProfilePrimaryCertificateTree
  | ProfileSecondaryCertificateTree;

export interface ProfileAoETree {
  aoeItem: AoEStoreItem;
  aoeStatus: CatalogueStoreItemStatus;
  aoeLevel: number;
  specTrees: ProfileSpecTree[];
}

export function isProfileAoETree(tree: ProfileTree): tree is ProfileAoETree {
  return "aoeItem" in tree;
}

export interface ProfileSpecTree {
  specItem: SpecStoreItem;
  specStatus: CatalogueStoreItemStatus;
  specLevel: number;
  skillTrees: ProfileSkillTree[];
}

export function isProfileSpecTree(tree: ProfileTree): tree is ProfileSpecTree {
  return "specItem" in tree;
}

export interface ProfileSkillTree {
  skillItem: SkillStoreItem;
  skillStatus: CatalogueStoreItemStatus;
  skillLevel: number;
}

export function isProfileSkillTree(tree: ProfileTree): tree is ProfileSkillTree {
  return "skillItem" in tree;
}
