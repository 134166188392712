import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "sm-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() buttonStyle: "filled" | "outline" | "text" | "icon" | "disabled" = "filled";
  @Input() buttonSize: "small" | "normal" = "normal";
  @Input() iconPosition: "left" | "right" = "left";
  @Input() hasIcon = false;
  @Input() disabled = false;
  @Input() loading = false;
  @Output() onClick = new EventEmitter<MouseEvent>();

  clickEvent($event: MouseEvent): void {
    if (this.disabled || this.loading) return;

    $event.stopPropagation();
    this.onClick.emit($event);
  }
}
