import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { environment } from "src/environments/environment";
import { ProfileState } from "./profile/profile.state";
import { DomainKnowledgeState } from "./domain-knowledge/domain-knowledge.state";
import { TeamMembersState } from "./team-members/team-members.state";
import { CatalogueState } from "./catalogue/catalogue.state";
import { AvatarsState } from "./avatars/avatars.state";
import { CmsTextDataState } from "./cms-text-data/cms-text-data.state";
import { CatalogueFiltersState } from "./catalogue-filters/catalogue-filters.state";
import { HistoryState } from "./history/history.state";
import { PeersState } from "./peers/peers.state";
import { PowerSkillsState } from "./power-skills/power-skills.state";
import { NewsState } from "./news/news.state";
import { HelpContentState } from "./help-content/help-content.state";
import { ProfileSearchState } from "./profile-search/profile-search.state";
import { LocationState } from "./location/location.state";
import { ProfileSearchFormState } from "./profile-search-form/profile-search-form.state";
import { OverallLevelDescriptionsState } from "./overall-level-descriptions/overall-level-descriptions.state";
import { CertificatesState } from "./certificates/certificates.state";
import { LearningPathsState } from "./learning-paths/learning-paths.state";

const AppState = [
  ProfileState,
  AvatarsState,
  DomainKnowledgeState,
  TeamMembersState,
  CatalogueState,
  CmsTextDataState,
  CatalogueFiltersState,
  PeersState,
  PowerSkillsState,
  HistoryState,
  NewsState,
  HelpContentState,
  ProfileSearchState,
  LocationState,
  ProfileSearchFormState,
  OverallLevelDescriptionsState,
  CertificatesState,
  LearningPathsState,
];

@NgModule({
  imports: [
    NgxsModule.forRoot(AppState, { developmentMode: !environment.IS_PROD }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.IS_PROD }),
  ],
})
export class AppStoreModule {}
