import { StateContext } from "@ngxs/store";
import { Certificate } from "@shared/models/contentful.types";
import { areCMSItemArraysEqual } from "../../utils/is-store-items-array-equal.utils";
import { CertificatesStateModel } from "../models/certificates.model";

export function setCertificationsState({
  getState,
  setState,
}: StateContext<CertificatesStateModel>) {
  return function (cmsCertifications: Certificate[]): void {
    const prevCertifications = getState();
    const shouldNotUpdateState = areCMSItemArraysEqual(cmsCertifications, prevCertifications);
    if (shouldNotUpdateState) return;

    setState([...prevCertifications, ...cmsCertifications]);
  };
}
