import { Component, Input } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";

const defaultURL = "/assets/images/fallback-logo.svg";

@Component({
  selector: "sm-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent {
  @Input() src?: SafeUrl | string | null;
  @Input() size!: number;
  @Input() alt!: string;

  get avatarSrc(): SafeUrl | string {
    return this.src || defaultURL;
  }

  get style(): Partial<CSSStyleDeclaration> {
    if (!this.size) return {};
    const size = this.size || 64;

    return {
      width: `${size}px`,
      height: `${size}px`,
    };
  }
}
