import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { NotificationsToggleComponent } from "./components/notifications-toggle/notifications-toggle.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SidebarItemComponent } from "./components/sidebar-item/sidebar-item.component";
import { NewsSidebarItemComponent } from "./components/news-sidebar-item/news-sidebar-item.component";

@NgModule({
  declarations: [
    SidebarComponent,
    NotificationsToggleComponent,
    SidebarItemComponent,
    NewsSidebarItemComponent,
  ],
  imports: [SharedModule, CommonModule, RouterModule, MatSnackBarModule],
  exports: [SidebarComponent],
})
export class SidebarModule {}
