import { StateContext } from "@ngxs/store";
import {
  ChangeSecondaryDomainResponse,
  MY_PROFILE_KEY,
  ProfileModel,
  ProfileStateModel,
} from "../profile.model";

export function updateProfileStateSecondaryDomains({
  getState,
  patchState,
}: StateContext<ProfileStateModel>) {
  return function (response: ChangeSecondaryDomainResponse): void {
    const profile = getState()[MY_PROFILE_KEY];
    const successfulSecondaryDomains: ProfileModel["secondary_domains"] = response
      .filter(res => `${res.http_status_code}`.startsWith("2"))
      .map(res => ({
        entry_id: res.entity_id,
        parent_id: res.parent_id,
        pending: res.status === "pending",
      }));

    patchState({
      [MY_PROFILE_KEY]: {
        ...profile,
        secondary_domains: [...profile.secondary_domains, ...successfulSecondaryDomains],
      },
    });
  };
}
