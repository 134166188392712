import {
  PrimaryDomainItem,
  SecondaryDomainItem,
} from "./../domain-knowledge/domain-knowledge.models";
import {
  TeamMemberPendingChangeAction,
  TeamMemberPendingChangeEntityType,
} from "../team-members/models/team-members.model";
import {
  PrimaryPowerSkillItem,
  SecondaryPowerSkillItem,
} from "../power-skills/models/power-skills.model";

export class GetProfile {
  static readonly type = "[Profile] Get profile";
  constructor(public profileId?: string, public refreshRequest?: boolean) {}
}

export class UpdateProfileEntry {
  static readonly type = "[Profile] Update profile entry";
  constructor(
    public entityId: string,
    public entityVersion: number,
    public entityType: TeamMemberPendingChangeEntityType,
    public level: number,
    public parentId?: string,
    public action?: TeamMemberPendingChangeAction,
  ) {}
}

export class ChangeProfilePrimaryDomain {
  static readonly type = "[Profile] Update primary domain";
  constructor(public primaryDomain: PrimaryDomainItem) {}
}

export class ChangeProfileSecondaryDomains {
  static readonly type = "[Profile] Update secondary domains";
  constructor(public parentId: string, public secondaryDomains: SecondaryDomainItem[]) {}
}

export class ChangeProfilePrimaryPowerSkill {
  static readonly type = "[Profile] Update primary power skill";
  constructor(public primaryPowerSkill: PrimaryPowerSkillItem) {}
}

export class ChangeProfileSecondaryPowerSkills {
  static readonly type = "[Profile] Update secondary power skills";
  constructor(public parentId: string, public secondaryPowerSkills: SecondaryPowerSkillItem[]) {}
}

export class DeleteProfileEntry {
  static readonly type = "[Profile] Delete profile entry";
  constructor(
    public entityId: string,
    public entityType: TeamMemberPendingChangeEntityType,
    public parentId?: string,
  ) {}
}
