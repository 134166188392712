import { StateContext } from "@ngxs/store";
import { ProfileModel, ProfileStateModel } from "../profile.model";

export function setProfileState(
  profileKey: string,
  { patchState }: StateContext<ProfileStateModel>,
) {
  return function (profile: ProfileModel): ProfileStateModel {
    return patchState({
      [profileKey]: {
        ...profile,
      },
    });
  };
}
