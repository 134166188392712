import { PopUpCollection } from "@shared/models/contentful.types";
import { PopupTextItem } from "../cms-text-data.model";
import { filterNull } from "@shared/utils/filter-null.utils";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export function parsePopupTextItems(popupCollection?: PopUpCollection | null): PopupTextItem[] {
  if (!popupCollection) return [];

  return popupCollection?.items.filter(filterNull).map(item => ({
    title: item.title || "",
    name: item.name || "",
    headline: item.headline || "",
    headline2: item.headline2 || "",
    description: documentToHtmlString(item.description?.json),
    descriptionTitle: item.descriptionTitle || "",
  }));
}
