import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { filterNullItemsPipe } from "@shared/utils/filter-null.utils";
import { LargeCollectionRequestArgs, CMSRequestService } from "../services/cms-request.service";
import { GetPowerSkills } from "./power-skills.actions";
import {
  PowerSkillsStateModel,
  PrimaryPowerSkillItem,
  SecondaryPowerSkillItem,
} from "./models/power-skills.model";
import { POWER_SKILLS_QUERY } from "./queries/power-skills.query";
import { formatCMSPrimaryPowerSkill } from "./utils/format-cms-power-skills.utils";
import { ProfileState } from "../profile/profile.state";
import { ProfilePrimaryPowerSkillTree } from "./models/power-skill-tree.model";
import { reduceSecondaryPowerSkillItems } from "./utils/reduce-secondary-power-skill-items.utils";
import { buildProfilePowerSkillTrees } from "./utils/build-profile-power-skills-trees.utils";
import { setPowerSkillsState } from "./utils/set-power-skills-state.utils";

@State<PowerSkillsStateModel>({
  name: "cmsPowerSkills",
  defaults: [],
})
@Injectable()
export class PowerSkillsState {
  constructor(private cmsService: CMSRequestService) {}

  /** ACTIONS */
  @Action(GetPowerSkills)
  fetchPowerSkills(
    context: StateContext<PowerSkillsStateModel>,
  ): Observable<PrimaryPowerSkillItem[]> {
    const fetchArgs: LargeCollectionRequestArgs<"primaryPowerSkillCollection"> = {
      collection: "primaryPowerSkillCollection",
      limit: 10,
      itemQuery: POWER_SKILLS_QUERY,
    };

    return this.cmsService.fetchLargeCollectionItems(fetchArgs).pipe(
      map(({ data }) => data[fetchArgs.collection]?.items || []),
      filterNullItemsPipe,
      map(cmsPowerSkillItems => cmsPowerSkillItems.map(formatCMSPrimaryPowerSkill)),
      tap(setPowerSkillsState(context)),
    );
  }

  /** SELECTORS */
  @Selector()
  static selectPrimaryPowerSkillItems(
    primaryPowerSkillItems: PowerSkillsStateModel,
  ): PrimaryPowerSkillItem[] {
    return primaryPowerSkillItems;
  }

  @Selector()
  static selectSecondaryPowerSkillItems(
    primaryPowerSkillItems: PowerSkillsStateModel,
  ): SecondaryPowerSkillItem[] {
    return reduceSecondaryPowerSkillItems(primaryPowerSkillItems);
  }

  @Selector()
  static selectPrimaryPowerSkillItem(primaryPowerSkillItems: PowerSkillsStateModel) {
    return function (primaryPowerSkillId: string): PrimaryPowerSkillItem | undefined {
      return primaryPowerSkillItems.find(({ id }) => primaryPowerSkillId === id);
    };
  }

  @Selector([PowerSkillsState.selectSecondaryPowerSkillItems])
  static selectSecondaryPowerSkillItem(
    _: PowerSkillsStateModel,
    secondaryPowerSkillItems: ReturnType<typeof PowerSkillsState.selectSecondaryPowerSkillItems>,
  ) {
    return function (secondaryPowerSkillId: string): SecondaryPowerSkillItem | undefined {
      return secondaryPowerSkillItems.find(({ id }) => secondaryPowerSkillId === id);
    };
  }

  @Selector([ProfileState.selectProfile])
  static selectProfilePowerSkills(
    primaryPowerSkillItems: PowerSkillsStateModel,
    selectProfile: ReturnType<typeof ProfileState.selectProfile>,
  ) {
    return function (profileId?: string): ProfilePrimaryPowerSkillTree[] | null {
      const profile = selectProfile(profileId);
      if (!profile) return null;

      return buildProfilePowerSkillTrees(profile, primaryPowerSkillItems);
    };
  }
}
