import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { PendingRequestTree } from "./models/pending-request-tree.model";
import {
  TeamMember,
  TeamMembersStateModel,
  UpdateMultiplePendingRequestBody,
  UpdateMultiplePendingRequestResponse,
  UpdateTeamMembersPendingRequestBody,
  UpdateTeamMembersPendingRequestResponse,
} from "./models/team-members.model";
import {
  GetTeamMembers,
  RemovePendingRequests,
  UpdateMultiplePendingRequest,
  UpdateSinglePendingRequest,
} from "./team-members.actions";
import { getPendingRequestTrees } from "./utils/get-pending-request-trees.utils";
import { removeStatePendingRequests } from "./utils/remove-state-pending-requests.utils";
import { updateMultiplePendingRequestsState } from "./utils/update-multiple-pending-request.utils";
import { updateSinglePendingRequestState } from "./utils/update-single-pending-request.utils";

@State<TeamMembersStateModel>({
  name: "teamMembers",
  defaults: [],
})
@Injectable()
export class TeamMembersState {
  constructor(private http: HttpClient) {}

  /** ACTIONS */
  @Action(GetTeamMembers)
  getTeamMembers(
    { getState, setState }: StateContext<TeamMembersStateModel>,
    { refreshRequest }: GetTeamMembers,
  ): Observable<TeamMember[]> {
    const teamMembersState = getState();
    const isStateSet = teamMembersState.length > 0;

    if (!isStateSet || refreshRequest) {
      return this.http
        .get<TeamMember[]>(`${environment.AZ_URL}/team`)
        .pipe(tap(teamMembers => setState(teamMembers)));
    }

    return of(teamMembersState);
  }

  @Action(UpdateSinglePendingRequest)
  updateSinglePendingRequest(
    stateContext: StateContext<TeamMembersStateModel>,
    { changeId, comment, status, entityVersion }: UpdateSinglePendingRequest,
  ): Observable<UpdateTeamMembersPendingRequestResponse> {
    const body: UpdateTeamMembersPendingRequestBody = {
      status,
      comment,
      entity_version: entityVersion,
    };

    return this.http
      .put<UpdateTeamMembersPendingRequestResponse>(
        `${environment.AZ_URL}/change/${changeId}`,
        body,
      )
      .pipe(tap(updateSinglePendingRequestState(stateContext)));
  }

  @Action(UpdateMultiplePendingRequest)
  updateMultiplePendingRequests(
    stateContext: StateContext<TeamMembersStateModel>,
    { changeIds, comment, status, entityVersions }: UpdateMultiplePendingRequest,
  ): Observable<UpdateMultiplePendingRequestResponse> {
    const body: UpdateMultiplePendingRequestBody = entityVersions.map((entityVersion, i) => ({
      change_id: changeIds[i],
      status,
      comment,
      entity_version: entityVersion,
    }));

    return this.http
      .post<UpdateMultiplePendingRequestResponse>(`${environment.AZ_URL}/change/bulk`, body)
      .pipe(tap(updateMultiplePendingRequestsState(stateContext)));
  }

  @Action(RemovePendingRequests)
  removePendingRequests(
    stateContext: StateContext<TeamMembersStateModel>,
    { pendingRequests }: RemovePendingRequests,
  ): void {
    return removeStatePendingRequests(stateContext, pendingRequests);
  }

  /** SELECTORS */
  @Selector()
  static selectTeamMembers(teamMembers: TeamMembersStateModel): TeamMembersStateModel {
    return teamMembers;
  }

  @Selector()
  static selectTeamMember(teamMembers: TeamMembersStateModel) {
    return function (teamMemberId?: string): TeamMember | undefined {
      return teamMembers.find(({ id }) => teamMemberId === id);
    };
  }

  @Selector()
  static selectPendingRequestTrees(teamMembers: TeamMembersStateModel) {
    return function (teamMemberId?: string): PendingRequestTree[] {
      return getPendingRequestTrees(teamMembers, teamMemberId);
    };
  }
}
