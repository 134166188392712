import { LearningPathItem } from "@shared/models/contentful.types";
import { LearningPathSectionStepStoreItem } from "../models/learning-paths.model";

export function parseLearningPathSectionSteps(
  cmsSectionItems: LearningPathItem[],
): LearningPathSectionStepStoreItem[] {
  return cmsSectionItems.map(s => ({
    id: s.sys.id,
    entityVersion: s.sys.publishedVersion || 0,
    type: "LearningPathItem",
    name: s.title || "",
    platformId: String(s.platformId || ""),
    order: s.order || 0,
    url: s.url || "",
    numberOfItems: s.numberOfItems || 0,
    itemType: getItemType(s.type),
    duration: s.duration || 0,
    thumbnail: getThumbnail(s.thumbnail),
    parentSectionId: getParentId(s),
  }));
}

function getParentId(s: LearningPathItem): string {
  return s.linkedFrom?.learningPathSectionCollection?.items[0]?.sys.id || "";
}

function getItemType(t?: LearningPathItem["type"] | null) {
  switch (t) {
    case "course":
      return "course";
    case "coursePortion":
      return "coursePortion";
    case "lab":
      return "lab";
    case "assessment":
      return "assessment";
    case "link":
      return "link";
    default:
      return null;
  }
}

function getThumbnail(t: LearningPathItem["thumbnail"]): string {
  if (!t) return "";

  // In order of preferred resolution
  if (t.size480X270) {
    return t.size480X270;
  } else if (t.size304X171) {
    return t.size304X171;
  } else if (t.size750X422) {
    return t.size750X422;
  } else if (t.size240X135) {
    return t.size240X135;
  } else if (t.url) {
    return t.url;
  } else if (t.size200H) {
    return t.size200H;
  } else if (t.size125H) {
    return t.size125H;
  } else if (t.size100X100) {
    return t.size100X100;
  } else if (t.size96X54) {
    return t.size96X54;
  } else if (t.size75X75) {
    return t.size75X75;
  } else if (t.size50X50) {
    return t.size50X50;
  } else if (t.size48X27) {
    return t.size48X27;
  } else {
    return "";
  }
}
