<sm-loading-spinner
  *ngIf="appService.loading.getValue()"
  [showTitle]="true"
  [title]="'Skill Matrix'"
  [subTitle]="'Loading your data...'"
>
</sm-loading-spinner>

<div *ngIf="!appService.loading.getValue()" class="main-container" #hostRef>
  <sm-sidebar *ngIf="!noSidebar"></sm-sidebar>
  <div class="content">
    <sm-info-box></sm-info-box>
    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="adminEnabled" class="admin"></div>

<app-redirect></app-redirect>
