import { PrimaryDomainItem, SecondaryDomainItem } from "../domain-knowledge.models";
import {
  ProfilePrimaryDomainTree,
  ProfileSecondaryDomainTree,
} from "../models/profile-domain-knowledge-tree.model";
import { ProfileEntry, ProfileModel } from "../../profile/profile.model";
import { CatalogueStoreItemStatus } from "../../catalogue/catalogue.model";

export function buildProfileDomainTrees(
  profile: ProfileModel,
  primaryDomainItems: PrimaryDomainItem[],
): ProfilePrimaryDomainTree[] {
  const profilePrimaryIds = profile.primary_domains.map(p => p.entry_id);

  return primaryDomainItems
    .filter(({ id }) => profilePrimaryIds.includes(id))
    .map(primaryItem => ({
      primaryItem,
      primaryStatus: getPrimaryItemStatus(primaryItem, profile.primary_domains),
      secondaryTrees: buildProfileSecondaryDomainTrees(
        profile,
        primaryItem.id,
        primaryItem.secondaryDomainItems,
      ),
    }));
}

function buildProfileSecondaryDomainTrees(
  { secondary_domains }: ProfileModel,
  primaryDomainId: string,
  secondaryDomainItems: SecondaryDomainItem[],
): ProfileSecondaryDomainTree[] {
  const profileSecondaryIds = secondary_domains
    .filter(s => s.parent_id === primaryDomainId)
    .map(s => s.entry_id);

  return secondaryDomainItems
    .filter(({ id }) => profileSecondaryIds.includes(id))
    .map(secondaryItem => ({
      secondaryItem,
      secondaryStatus: getSecondaryItemStatus(secondaryItem, secondary_domains),
    }));
}

function getPrimaryItemStatus(
  primaryItem: PrimaryDomainItem,
  primaryDomains: ProfileModel["primary_domains"],
) {
  const profileItem = primaryDomains.find(p => p.entry_id === primaryItem.id);

  return getStatus(profileItem);
}

function getSecondaryItemStatus(
  secondaryItem: SecondaryDomainItem,
  secondaryDomains: ProfileModel["secondary_domains"],
) {
  const profileItem = secondaryDomains.find(
    s => s.entry_id === secondaryItem.id && s.parent_id === secondaryItem.parentId,
  );

  return getStatus(profileItem);
}

function getStatus(
  entry?: ProfileEntry,
): Extract<CatalogueStoreItemStatus, "pending" | "approved"> {
  return entry?.pending ? "pending" : "approved";
}
