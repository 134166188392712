import { PageCollection } from "@shared/models/contentful.types";
import { filterNull } from "@shared/utils/filter-null.utils";
import { LearningPathPageText } from "../cms-text-data.model";
import { cmsPagesIds } from "../../utils/cms-pages-ids.utils";

export function parseLearningPathPageText(
  pageCollection?: PageCollection | null,
): LearningPathPageText {
  const learningPathPage = pageCollection?.items
    .filter(filterNull)
    .find(page => page.sys.id === cmsPagesIds.learningPath);

  return {
    stepThumbnailFallback: learningPathPage?.fallbackIllustration?.url || "",
    noStepsFallback: learningPathPage?.emptySearchResultIllustration?.url || "",
    infoBoxDescription: learningPathPage?.headingSection3 || "",
    infoBoxArticleText: learningPathPage?.button1Text || "",
    infoBoxArticleLink: learningPathPage?.button1Link || "",
    infoBoxHideLinkText: learningPathPage?.textButton1Text || "",
  };
}
