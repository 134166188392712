export function areStoreItemArraysEqual<T extends { id: string }>(
  itemsA: T[],
  itemsB: T[],
): boolean {
  return itemsA.every(itemA => itemsB.findIndex(itemB => itemA.id === itemB.id) >= 0);
}

export function areCMSItemArraysEqual<T extends { sys: { id: string } }>(
  itemsA: T[],
  itemsB: T[],
): boolean {
  return itemsA.every(itemA => itemsB.findIndex(itemB => itemA.sys.id === itemB.sys.id) >= 0);
}
