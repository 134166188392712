import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "skillItemLevel" })
export class SkillStoreItemLevelPipe implements PipeTransform {
  transform(level: number): SkillLevel {
    switch (level) {
      default:
        return "Basic Knowledge";
      case 1:
        return "Used it in a project";
      case 2:
        return "Know it very well";
      case 3:
        return "Expert, can teach others";
    }
  }
}

type SkillLevel =
  | "Basic Knowledge"
  | "Used it in a project"
  | "Know it very well"
  | "Expert, can teach others";
