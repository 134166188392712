import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export function filterNull<T>(item: T): item is Exclude<T, null> {
  return Boolean(item);
}

export function filterNullItemsPipe<T>(items$: Observable<T[]>) {
  return items$.pipe(map(items => items.filter(filterNull)));
}
