import { Component, Input, OnInit } from "@angular/core";
import { VideoModalComponent } from "@shared/components/video-modal/video-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { ExternalResourceCard, VideoResourceCard } from "@shared/models/contentful.types";
import { documentToHtmlString, Next } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { VideoModalDialogConfig } from "@shared/components/video-modal/video-modal.models";

@Component({
  selector: "sm-resource-card",
  templateUrl: "./resource-card.component.html",
  styleUrls: ["./resource-card.component.scss"],
})
export class ResourceCardComponent implements OnInit {
  @Input() data: VideoResourceCard | ExternalResourceCard = {
    contentfulMetadata: { tags: [] },
    sys: { environmentId: "", id: "", spaceId: "" },
  };
  @Input() isVideo = false;

  descriptionHtml = "";
  urlHtml = "";
  url = "";
  previewUrl = "";
  previewAlt = "";

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.descriptionHtml = documentToHtmlString(this.data.description?.json);
    this.previewUrl = this.data.thumbnail?.url ?? "";
    this.previewAlt = this.data.thumbnail?.description ?? "";
    this.getUrlData();
  }

  get duration(): string {
    return (this.data as VideoResourceCard).duration ?? "";
  }

  getUrlData(): void {
    const urlOptions = {
      renderNode: {
        /*
          The Node contentful types don't allow node to have
          content[0].value for some reason
        */
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        [BLOCKS.PARAGRAPH]: (node: any, next: Next) => {
          this.url = node.content[0].value;
          return `${next(node.content) ? next(node.content) : ""}`;
        },
      },
    };
    this.urlHtml = documentToHtmlString(this.data.url?.json, urlOptions);
  }

  onOpenCard(): void {
    if (this.isVideo) {
      this.showVideo();
    } else {
      window.open(this.url, "_blank");
    }
  }

  showVideo(): void {
    const dialogConfig: VideoModalDialogConfig = {
      data: { url: this.url },
      panelClass: "help-video-dialog",
    };
    this.dialog.open(VideoModalComponent, dialogConfig);
  }
}
